import React, { useCallback, useEffect, useState } from "react";
import CompanionGraphic from "./companionGraphic";

export enum Companions {
  Snail = "snail",
  Leaf = "leaf",
  Ladybug = "ladybug",
  Elephant = "elephant",
  Grasshopper = "grasshopper",
  Chameleon = "chameleon",
  Bug = "bug",
  Firefly = "firefly",
  Bird = "bird",
}

export enum CompanionType {
  progress = "progress",
  previous_best = "previousbest",
  timer = "timer",
}

type CompanionProps = {
  position: number;
  showMask: boolean;
  source: string;
  companionType: CompanionType;
};

export const setShowMask = (showMask: boolean) => {
  return showMask ? "block" : "none";
};

export const getCompanionId = (companionType: CompanionType) => {
  if (companionType === CompanionType.progress) {
    return "companion";
  } else if (companionType === CompanionType.previous_best) {
    return "previous-companion";
  } else {
    return "timer-companion";
  }
};

export const showHideMask = (
  showMask: boolean,
  companion: HTMLElement,
  position: number,
  companionType: CompanionType
) => {
  const children = companion?.children;
  const isShowMask = setShowMask(showMask);
  const companionId = getCompanionId(companionType);

  for (const element of children) {
    element.setAttribute("display", isShowMask);
  }

  const character = document.getElementById(companionId + position);
  character?.setAttribute("display", "block");
};

export const setCompanionPosition = (
  showMask: boolean,
  position: number,
  companionType: CompanionType
) => {
  const svgContainerId = `${companionType}_companion`;
  const svgContainer = document.getElementById(svgContainerId);
  const children = svgContainer?.children;

  if (children) {
    let companion;

    // Reset visibility of children to none
    for (const element of children) {
      element.setAttribute("display", "none");
    }
    if (position > 0 && position <= children.length) {
      companion = document.getElementById(svgContainerId + position);
      companion?.setAttribute("display", "block");
    } else {
      companion = document.getElementById(`${svgContainerId}1`);
      companion?.setAttribute("display", "none");
    }

    // Show/Hide companion's mask
    if (companion && companionType !== CompanionType.timer) {
      showHideMask(showMask, companion, position, companionType);
    }

    // Shows companion based on position
    companion?.setAttribute("display", "block");
  }
};

function Companion(props: CompanionProps) {
  const [isLoaded, setIsLoaded] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadComplete = useCallback(() => setIsLoaded(true), []);
  useEffect(() => {
    if (isLoaded) {
      setCompanionPosition(props.showMask, props.position, props.companionType);
    }
  }, [isLoaded, props.showMask, props.position, props.companionType]);

  return (
    <CompanionGraphic
      id={props.companionType}
      src={props.source}
      onLoad={loadComplete}
    />
  );
}

export default Companion;
