import React from "react";
type LoaderProps = {
  style?: any;
};
export default function Loader(props: LoaderProps) {
  return (
    <div style={props.style} className="loader-background">
      <div className="sp sp-circle"></div>
    </div>
  );
}
