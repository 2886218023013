import blackAnt from "../../assets/power-backgrounds/black-ant.png";
import redAnt from "../../assets/power-backgrounds/red-ant.png";

type AntBackgroundProps = {
  life: number;
};

export const changeBackground = (life: number) => {
  switch (life) {
    case 2:
      return blackAnt;
    case 1:
      return redAnt;
    case 0:
      return blackAnt;
    default:
      return undefined;
  }
};

function AntBackground(props: AntBackgroundProps) {
  return (
    <>
      {changeBackground(props.life) && (
        <img
          draggable="false"
          src={changeBackground(props.life)}
          alt="ant-background"
          className="power-game-background"
        />
      )}
    </>
  );
}

export default AntBackground;
