import React, { useCallback, useEffect, useState } from "react";
import FishAnimation, {
  SpeedTwoCharacter,
} from "../fish-animation/fish-animation";
import { Companions } from "../progress-bar/companion/companion";
import gameLooseAudio from "../../assets/sound/gameloose.mp3";
import gameWinAudio from "../../assets/sound/gamewin.mp3";
import Loader from "../loader/loader";
import ArrowButton from "../arrow-button/arrow-button";
import { GameContainerProps, GameType, Question } from "./game-container";
import { getMediaInfo, validateAnswer } from "../../api";
import { shuffleArray } from "../../utils/ArrayItems";
import TimerProgressBar from "../timer-progress-bar/timer-progress-bar";
import ProgressBar, { ProgressColors } from "../progress-bar/progress-bar";
import { LifeCharacter } from "../progress-bar/life/life";
import { playAudioById } from "../../utils/AudioPlayerController";

import {
  disableOkButton,
  disableSingleChoiceQuestion,
  getExternalReference,
  getQuestionType,
  setBackground,
} from "./game-utils";
import { onGameOver, saveAnswer } from "./game-api-calls";
import SpiderAnimation from "../spider-animation/spider-animation";

export type QuestionParserProps = {
  data: any;
  setHelperCardURL: (url: string) => void;
};

export let currentGameType: GameType;
export let exerciseId: string;
export let gameDuration: number;
export const maxQuestions = 20;

export const useExplanationVideo = () => {
  const [sourceLink, setSourceLink] = useState("");
  const switchVideo = (title: string) => {
    if (title === "Power") {
      setSourceLink("https://zwijsen.bbvms.com/p/semsom/c/4263811.html");
    } else if (title === "Speed1") {
      setSourceLink("https://zwijsen.bbvms.com/p/semsom/c/4263812.html");
    } else {
      setSourceLink("https://zwijsen.bbvms.com/p/semsom/c/4263810.html");
    }
  };

  return { sourceLink, setSourceLink, switchVideo };
};

export const useGameController = (props: GameContainerProps) => {
  const [isGameBegin, setIsGameBegin] = useState(false);
  const [answerAttempt, setAnswerAttempt] = useState(0);
  const [showSoundPopup, setShowSoundPopup] = useState(false);
  const [voiceAudio, setVoiceAudio] = useState(false);
  const [clickedVoice, setClickedVoice] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(false);
  const [life, setLife] = useState(0);
  const [showMask, setShowMask] = useState(false);
  const [powerExerciseAttemptCount, setPowerExerciseAttemptCount] = useState(2);
  const [speedTwoCharacterColor, setSpeedTwoCharacterColor] =
    useState<SpeedTwoCharacter>(SpeedTwoCharacter.RED);
  const [showHelperCard, setShowHelperCard] = useState(false);
  const [personalBest, setPersonalBest] = useState(0);
  const [showReward, setShowReward] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [selectedOptionId, setSelectedOptionId] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [companionProgress, setCompanionProgress] = useState(0);
  const [inputText, setInputText] = useState<number | null>(null);
  const [startSpeedTwoAnimation, setStartSpeedTwoAnimation] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] =
    useState<boolean | undefined>(undefined);
  const [isAnswerSubmitted, setIsAnswerSubmitted] = useState<boolean>(false);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [startTimer, setStartTimer] = useState<boolean | undefined>(undefined);
  const [companion, setCompanion] = useState<Companions>(Companions.Ladybug);
  const [givenAnswer, setGivenAnswer] = useState("");
  const [playAudio, setPlayAudio] = useState(false);
  const [helperCardURL, setHelperCardURL] = useState<string | null>(null);
  const [showArrowIcon, setShowArrowIcon] = useState(false);
  const [isGameOver, setGameOver] = useState(false);
  const [isGameWin, setGameWin] = useState(false);
  const [showExplanationVideo, setShowExplanationVideo] = useState(false);
  const [isVideoViewed, setVideoViewed] = useState(false);
  const [boxContainerStyle, setBoxContainerStyle] = useState<string>("");
  const [publicationId, setPublicationId] = useState<string>("");
  const [isShuffleArray, setIsShuffleArray] = useState(true);
  const [exerciseList, setExerciseList] = useState<any>("");
  const [isFrogJump, setIsFrogJump] = useState(false);
  const [autoAnswer, setAutoAnswer] = useState<string>("");
  const [isFrogJumpFromScreen, setIsFrogJumpFromScreen] = useState(false);
  const [pauseSpeedTwoAnimation, setPauseSpeedTwoAnimation] = useState(false);
  const [
    isHangingSpiderAnimationComplete,
    setIsHangingSpiderAnimationComplete,
  ] = useState(false);

  const initGameContainer = (props: any, checkAudio: boolean) => {
    if (props.exerciseList) {
      // Sets exercise list
      setExerciseList({
        personalBest: props.exerciseList.data.personalBest,
        exerciseAttempt: props.exerciseList.data.exerciseAttempt,
        questions: props.exerciseList.data.questions,
      });
      setPublicationId(props.exerciseList.data.questions.publicationId);
      exerciseId = props.exerciseList.data.questions.id;
      parseQuestion({
        data: props.exerciseList.data.questions.assessments[questionIndex],
      });
      props.exerciseList.data.personalBest <= maxQuestions
        ? setPersonalBest(props.exerciseList.data.personalBest)
        : setPersonalBest(0);
      initAudioQuestion();
      setQuestionIndex(0);
      // Shows sound check popup
      if (checkAudio) {
        if (
          props.exerciseList.data.exerciseAttempt <= 1 &&
          props.exerciseList.data.questions.assessments[0].assets.length > 0 &&
          props.exerciseList.data.questions.assessments[0].assets[0].type ===
            "AUDIO" &&
          localStorage.getItem("showSoundPopup") !== "true"
        ) {
          localStorage.setItem("showSoundPopup", "true");
          setShowSoundPopup(true);
        } else if (props?.isExplanationVideo && !isVideoViewed) {
          setShowExplanationVideo(true);
        } else {
          setShowExplanationVideo(false);
        }
      }
    }

    currentGameType = props.gameType;
    gameDuration = props.gameDuration;
    setLifeAccordingToGameType(props.gameType);

    setBackground(props.gameType, (url) => {
      setBackgroundImage(url);
    });
    setPlayAudio(false);
    setQuestionBoxStyle();
    setIsFrogJump(false);
    setIsFrogJumpFromScreen(false);
    setCompanionProgress(0);
    setQuestionIndex(0);
    setIsHangingSpiderAnimationComplete(false);
  };

  const setLifeAccordingToGameType = (currentGameType: GameType) => {
    if (
      currentGameType === GameType.Power ||
      currentGameType === GameType.PowerWater
    ) {
      setLife(2);
    } else if (
      currentGameType === GameType.SpeedTwo ||
      currentGameType === GameType.SpeedTwoLand
    ) {
      setLife(1);
      setShowMask(true);
    }
  };

  const checkAnswer = async () => {
    const input = document.getElementById(
      "answer-input-box"
    ) as HTMLInputElement;
    disableOkButton(true);
    if (currentQuestion?.answerType === "TEXT_ENTRY") {
      setInputText(0);
      input.disabled = true;
      input?.setAttribute("readOnly", "readOnly");
    } else {
      disableSingleChoiceQuestion(true);
    }
    setPauseSpeedTwoAnimation(true);
    setIsAnswerSubmitted(false);
    // Correct Answer
    let isCorrectAnswer;
    currentQuestion?.answerType === "TEXT_ENTRY"
      ? setGivenAnswer(input.value.toString())
      : setGivenAnswer(selectedOptionId);
    await validateAnswer({
      publicationId,
      exerciseId,
      assessmentId: currentQuestion?.questionId || "",
      interactionId: currentQuestion?.interactionId || "",
      answer:
        currentQuestion?.answerType === "TEXT_ENTRY"
          ? input.value
          : selectedOptionId,
      isReturnFeedback: true,
    })
      .then((response) => {
        isCorrectAnswer = response.data.isCorrect;
        setAutoAnswer(response.data.feedbackAnswer);
      })
      .catch((error) => {
        console.log(error);
      });

    if (isCorrectAnswer) {
      // Correct Answer
      handleCorrectAnswer(input);
    } else {
      // Wrong Answer
      handleIncorrectAnswer(input);
      handlePowerAnswer(input);
    }
    setStartSpeedTwoAnimation(false);
    setPauseSpeedTwoAnimation(false);
    setIsAnswerSubmitted(true);
    if (currentQuestion?.answerType === "TEXT_ENTRY") input.blur();
  };

  const handleCorrectAnswer = (input: HTMLInputElement) => {
    if (companionProgress >= 0 && companionProgress <= maxQuestions) {
      setCompanionProgress(companionProgress + 1);
      const isLastQuestion = companionProgress === maxQuestions - 1;
      const isSpeedOne = props.gameType === GameType.SpeedOne;
      const isSpeedOneAir = props.gameType === GameType.SpeedOneAir;
      if (isLastQuestion && (isSpeedOne || isSpeedOneAir)) {
        setStartTimer(false);
      }
    } else {
      setCompanionProgress(maxQuestions);
    }

    setIsCorrectAnswer(true);
    setAnswerAttempt(2);

    setIsFrogJump(true);
    //save right answer
    saveAnswer({
      questionIndex,
      answerAttempt,
      publicationId,
      props,
      answer:
        currentQuestion?.answerType === "TEXT_ENTRY"
          ? input.value.toString()
          : selectedOptionId,
      assessmentId: currentQuestion?.questionId || "",
      assessmentAttemptNumber: 2,
      callback: () => {
        setTimeout(() => increaseIndex(true), 1000);
      },
    });
  };

  const handleIncorrectAnswer = (input: HTMLInputElement) => {
    setIsCorrectAnswer(false);
    setAnswerAttempt(4);
    if (currentQuestion) {
      saveAnswer({
        questionIndex,
        answerAttempt,
        publicationId,
        props,
        answer:
          currentQuestion?.answerType === "TEXT_ENTRY"
            ? input.value.toString()
            : selectedOptionId,
        assessmentId: currentQuestion?.questionId,
        assessmentAttemptNumber: 3,
        callback: () => {
          if (
            currentGameType === GameType.SpeedOne ||
            currentGameType === GameType.SpeedOneAir
          ) {
            shuffleQuestion();
          } else {
            const input = document.getElementById(
              "answer-input-box"
            ) as HTMLInputElement;

            if (currentQuestion?.answerType === "TEXT_ENTRY") {
              setInputText(0);
              input.disabled = false;
              input?.removeAttribute("readOnly");
            } else {
              disableSingleChoiceQuestion(false);
            }
          }
        },
      });
    }
  };

  const shuffleQuestion = () => {
    if (
      (isShuffleArray &&
        currentQuestion &&
        currentGameType === GameType.SpeedTwo) ||
      (isShuffleArray &&
        currentQuestion &&
        currentGameType === GameType.SpeedOne) ||
      (isShuffleArray &&
        currentQuestion &&
        currentGameType === GameType.SpeedOneAir) ||
      (isShuffleArray &&
        currentQuestion &&
        currentGameType === GameType.SpeedTwoLand)
    ) {
      setIsAnswerSubmitted(false);
      exerciseList.questions.assessments = shuffleArray(
        exerciseList.questions.assessments
      );
      parseQuestion({
        data: exerciseList.questions.assessments[questionIndex],
      });
      increaseIndex(false);
      focusInput();
    }
  };

  const focusInput = () => {
    const input = document.getElementById(
      "answer-input-box"
    ) as HTMLInputElement;
    if (input) {
      input.removeAttribute("readOnly");
      setInputText(0);
      input.focus();
      input.disabled = false;
    }
  };

  const initAudioQuestion = async () => {
    if (currentQuestion?.questionType === "voice") {
      await getMediaInfo(
        getExternalReference(
          exerciseList.questions.assessments[0].assets,
          exerciseList.questions.assessments[0].interactions[0].question
            .assetIds[0]
        )
      )
        .then((response) => {
          const audioURL = response.data.url;
          const audioPlayer = document.getElementById(
            "question-audio-player"
          ) as HTMLAudioElement;
          audioPlayer.src = "";
          audioPlayer.src = audioURL;
        })
        .catch((err) => {});
    }
  };

  const powerGameHandleLife = (input: HTMLInputElement) => {
    let gotoFailScreenDelay;
    if (powerExerciseAttemptCount > 1) {
      setLife(life - 1);
      setPowerExerciseAttemptCount(powerExerciseAttemptCount - 1);

      if (life === 0) {
        setPlayAudio(false);
        setVideoViewed(true);
        setShowHelperCard(false);
        if (currentGameType === GameType.PowerWater) {
          setIsFrogJumpFromScreen(true);
          gotoFailScreenDelay = 500;
        } else {
          gotoFailScreenDelay = 0;
        }

        setTimeout(() => {
          onGameOver({
            exerciseStatus: 2,
            props,
            callback: () => {
              gotoFailScreen(gameLooseAudio, (value) => {
                props.updateGameBegin(value);
              });
              setGameOver(true);
            },
          });
        }, gotoFailScreenDelay);
      } else {
        setShowHelperCard(true);
      }

      setTimeout(() => {
        if (currentQuestion?.answerType === "TEXT_ENTRY") {
          input?.parentElement?.classList.remove("wrong-answer-box");
          input.removeAttribute("readOnly");
          input.value = "";
          input.focus();
        }
        disableOkButton(false);
      }, 1000);
    }
  };

  const handlePowerAnswer = (input: HTMLInputElement) => {
    if (
      currentGameType === GameType.Power ||
      currentGameType === GameType.PowerWater
    ) {
      setIsAnswerSubmitted(false);
      setIsCorrectAnswer(undefined);
      if (powerExerciseAttemptCount > 1) {
        powerGameHandleLife(input);
      } else if (powerExerciseAttemptCount === 1) {
        // animate input on wrong answer
        if (currentQuestion?.answerType === "TEXT_ENTRY") {
          input?.parentElement?.classList.remove("wrong-answer-box");
        }
        setTimeout(() => {
          if (currentQuestion?.answerType === "TEXT_ENTRY")
            input?.parentElement?.classList.add("wrong-answer-box");
          setAnswerAttempt(3);
          // show correct answer
          setTimeout(() => {
            showCorrectAnswer(input);
          }, 1000);
        }, 0);
      }
    }
  };

  const gotoFailScreen = (
    audio: string,
    onFailScreen: (gameBegin: boolean) => void
  ) => {
    playGameOverAudio(audio, false);
    setGameWin(false);
    setQuestionIndex(0);
    setCompanionProgress(0);
    setShowReward(false);
    setSpeedTwoCharacterColor(SpeedTwoCharacter.RED);
    setIsGameBegin(false);
    setIsAnswerSubmitted(false);
    setStartSpeedTwoAnimation(false);
    const box = document.getElementById("question-box-container");
    if (box) box.style.opacity = "1";
    if (
      currentGameType === GameType.Power ||
      currentGameType === GameType.PowerWater
    )
      setPowerExerciseAttemptCount(2);

    setTimeout(() => {
      setGameOver(false);
      onFailScreen(false);
    }, 6000);
  };

  const gotoWinScreen = (audio: string, callback: () => void) => {
    setGameWin(true);
    playGameOverAudio(audio, false);
    setIsGameBegin(false);
    setStartSpeedTwoAnimation(false);
    setTimeout(() => {
      setGameOver(false);
      callback();
    }, 6000);
  };

  const showCorrectAnswer = (input: HTMLInputElement) => {
    if (currentQuestion?.answerType === "TEXT_ENTRY") {
      input.value = autoAnswer ? autoAnswer : "";
      input?.setAttribute("readOnly", "readOnly");
      input?.parentElement?.classList.remove("wrong-answer-box");
      input?.parentElement?.classList.add("auto-answer-box");
      input?.focus();
      setShowArrowIcon(true);
      disableOkButton(false);
    } else {
      const correctOption = document.getElementById(
        String(autoAnswer)
      ) as HTMLElement;
      disableSingleChoiceQuestion(true);
      setSelectedOptionId(String(autoAnswer));
      correctOption?.classList.remove("wrong-answer-box");
      correctOption?.classList.add("option-auto-answer-box");
      setShowArrowIcon(true);
      disableOkButton(false);
    }
  };

  const increaseIndex = (increaseIndex: boolean) => {
    setIsAnswerSubmitted(false);
    setShowArrowIcon(false);
    setPowerExerciseAttemptCount(2);
    setShowHelperCard(false);
    setAnswerAttempt(0);

    if (increaseIndex && questionIndex < maxQuestions)
      setQuestionIndex(questionIndex + 1);

    if (currentQuestion?.answerType === "TEXT_ENTRY") {
      focusInput();
    } else {
      disableSingleChoiceQuestion(false);
    }
    disableOkButton(true);
  };

  const handleSpeedTwoTimerEnd = useCallback(() => {
    setLife(life - 1);
    setStartSpeedTwoAnimation(false);
    setIsCorrectAnswer(false);
    setIsAnswerSubmitted(true);
    disableOkButton(true);
    const input = document.getElementById(
      "answer-input-box"
    ) as HTMLInputElement;
    if (input) {
      input.setAttribute("readOnly", "readonly");
      input.disabled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLife, life]);

  const handleSpeedTwoAnimation = useCallback(() => {
    if (life === 0) {
      setSpeedTwoCharacterColor(SpeedTwoCharacter.GREEN);
      if (currentGameType === GameType.SpeedTwo) {
        setStartSpeedTwoAnimation(false);
        shuffleQuestion();
        setIsShuffleArray(false);
      } else if (currentGameType === GameType.SpeedTwoLand) {
        setStartSpeedTwoAnimation(true);
        shuffleQuestion();
      }
    } else if (life === -1) {
      // Goto Fail Page
      setPlayAudio(false);
      setVideoViewed(true);
      onGameOver({
        exerciseStatus: 2,
        props,
        callback: () => {
          gotoFailScreen(gameLooseAudio, (value) => {
            props.updateGameBegin(value);
          });
          setGameOver(true);
        },
      });
    } else {
      currentGameType === GameType.SpeedTwoLand
        ? setStartSpeedTwoAnimation(true)
        : setStartSpeedTwoAnimation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [life, isShuffleArray]);

  // Shuffles SpeedTwoLand game after spider climbs up
  useEffect(() => {
    if (
      currentGameType === GameType.SpeedTwoLand &&
      isHangingSpiderAnimationComplete &&
      life === 0
    ) {
      setStartSpeedTwoAnimation(true);
      shuffleQuestion();
      setIsShuffleArray(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHangingSpiderAnimationComplete]);

  const onSpeedTwoTimerEnd = useCallback(() => {
    handleSpeedTwoTimerEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSpeedTwoTimerEnd]);

  const speedTwoAnimationEnd = useCallback(
    () => {
      handleSpeedTwoAnimation();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSpeedTwoAnimation]
  );

  const gotoNextQuestion = (onGotoWinScreen: () => void) => {
    if (isCorrectAnswer) {
      if (questionIndex < exerciseList.questions.assessments.length) {
        parseQuestion({
          data: exerciseList.questions.assessments[questionIndex],
        });
        disableOkButton(true);
      } else {
        setPlayAudio(false);
        setVideoViewed(true);
        onGameOver({
          exerciseStatus: 3,
          props,
          callback: () => {
            gotoWinScreen(gameWinAudio, () => {
              onGotoWinScreen();
            });
            setGameOver(true);
          },
        });
      }
    }
  };

  const initInputField = (props: any) => {
    if (!props.isLoading && props.isGameBegin) {
      setStartTimer(true);
    }
  };

  const setQuestionBoxStyle = () => {
    switch (currentGameType) {
      case GameType.SpeedTwo:
        setBoxContainerStyle("speed-question-box-container");
        break;
      case GameType.SpeedOne:
        setBoxContainerStyle("speed-question-box-container");
        break;
      case GameType.Power:
        setBoxContainerStyle("power-question-box-container");
        break;
      case GameType.PowerWater:
        setBoxContainerStyle("power-year-four-question-box-container");
        break;
      case GameType.SpeedOneAir:
        setBoxContainerStyle("speed-one-air-question-box-container");
        break;
    }
  };

  const fishAnimationContainer = (props: any) => {
    return (
      <FishAnimation
        timerDurationInMilliSeconds={gameDuration}
        startAnimation={startSpeedTwoAnimation}
        pauseAnimation={pauseSpeedTwoAnimation}
        isCorrectAnswer={isCorrectAnswer ? isCorrectAnswer : false}
        isAnswerSubmitted={isAnswerSubmitted}
        fishColor={speedTwoCharacterColor}
        onAnimationEnd={speedTwoAnimationEnd}
        onTimerEnd={onSpeedTwoTimerEnd}
      ></FishAnimation>
    );
  };

  const spiderAnimationContainer = (props: any) => {
    return (
      <SpiderAnimation
        timerDurationInMilliSeconds={gameDuration}
        startAnimation={startSpeedTwoAnimation}
        pauseAnimation={pauseSpeedTwoAnimation}
        isCorrectAnswer={isCorrectAnswer ? isCorrectAnswer : false}
        isAnswerSubmitted={isAnswerSubmitted}
        isLoading={props.isLoading}
        spiderColor={speedTwoCharacterColor}
        onAnimationEnd={speedTwoAnimationEnd}
        onTimerEnd={onSpeedTwoTimerEnd}
      ></SpiderAnimation>
    );
  };

  const getLife = (gameType: GameType) => {
    switch (gameType) {
      case GameType.SpeedTwo:
        return { character: LifeCharacter.fish, remaining: life };
      case GameType.SpeedTwoLand:
        return { character: LifeCharacter.spider, remaining: life };
      case GameType.Power:
        return { character: LifeCharacter.ant, remaining: life };
      case GameType.PowerWater:
        return { character: LifeCharacter.frog, remaining: life };
    }
  };

  const headerProgressBar = (
    props: any,
    showTimerProgressBar: boolean,
    onTimerEnd: Function
  ) => {
    return showTimerProgressBar ? (
      <TimerProgressBar
        isTimerStarted={startTimer}
        gameDuration={gameDuration}
        onTimerEnd={onTimerEnd}
        gameType={props.gameType}
        isGameStarted={isGameBegin}
        maxQuestions={maxQuestions}
      ></TimerProgressBar>
    ) : (
      <ProgressBar
        gameType={props.gameType}
        companion={companion}
        color={ProgressColors.Purple}
        progress={companionProgress}
        position={
          questionIndex < maxQuestions ? questionIndex + 1 : maxQuestions
        }
        previousBestPosition={personalBest}
        showMask={showMask}
        life={getLife(props.gameType)}
        onHangingSpiderAnimationComplete={(isCompleted) => {
          setIsHangingSpiderAnimationComplete(isCompleted);
        }}
        isGameStarted={isGameBegin}
      ></ProgressBar>
    );
  };

  const showLoader = (props: any, onArrowClick: Function) => {
    return (
      <>
        {props.isLoading ? (
          <Loader data-testid="loading" />
        ) : (
          !isGameBegin && (
            <ArrowButton data-testid="arrow-button" onClick={onArrowClick} />
          )
        )}
      </>
    );
  };

  const optionClick = useCallback(
    (item: any) => {
      setSelectedAnswer(true);
      setIsAnswerSubmitted(false);
      setIsCorrectAnswer(undefined);
      setSelectedOptionId(item.id);
      disableOkButton(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const speedOneTimerEnd = useCallback(
    () => {
      setPlayAudio(false);
      setVideoViewed(true);
      disableOkButton(true);
      const input = document.getElementById(
        "answer-input-box"
      ) as HTMLInputElement;
      if (input) {
        input.setAttribute("readOnly", "readonly");
        input.disabled = true;
      }

      onGameOver({
        exerciseStatus: 2,
        props,
        callback: () => {
          gotoFailScreen(gameLooseAudio, (value) => {
            props.updateGameBegin(value);
            if (input) {
              input.removeAttribute("readOnly");
              input.disabled = false;
            }
            setStartTimer(undefined);
          });
          setGameOver(true);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onGameOver]
  );

  const hideSoundPopup = useCallback(() => {
    if (props?.isExplanationVideo) {
      setShowExplanationVideo(true);
    }
    setShowSoundPopup(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.isExplanationVideo]);

  const handleAddItemOnChange = (inputItem: number) => {
    setInputText(inputItem);
  };

  const handleShowExplanationVideo = useCallback(() => {
    setShowExplanationVideo(false);
  }, []);

  const playGameOverAudio = (source: string, isMuted: boolean) => {
    const gameOverAudioPlayer = document.getElementById(
      "game-over-audio-player"
    ) as HTMLAudioElement;
    gameOverAudioPlayer.muted = isMuted;
    gameOverAudioPlayer.src = "";
    gameOverAudioPlayer.src = source;
    playAudioById("game-over-audio-player");
  };

  const parseQuestion = async (props: any) => {
    let question: string;
    // Sets Text Question

    if (
      props.data.interactions[0].question === null ||
      props.data.interactions[0].question?.assetIds?.length < 1 ||
      props.data.interactions[0].question?.assetIds === null
    ) {
      question = props.data.interactions[0].text.replace(/(<([^>]+)>)/gi, "");

      setQuestion(props, question);
    } else {
      const assetType = props.data.assets[0].type;
      // Sets Image and Audio Question
      await getMediaInfo(
        getExternalReference(
          props.data.assets,
          props.data.interactions[0].question.assetIds[0]
        )
      )
        .then((response) => {
          const baseURL = response.data.url.split("?")[0];
          const query = response.data.url.split("?")[1];
          const imageURL = `${baseURL}/previews/maxWidth_400_maxHeight_180.jpg?${query}`;
          const question = assetType === "IMAGE" ? imageURL : response.data.url;
          setQuestion(props, question);
        })
        .catch((err) => {});
    }

    // Sets HelperCard URL
    setHelperCardImage(props);
  };

  const setQuestion = (props: any, question: string) => {
    const currentQuestion = {
      questionId: props.data.id.toString(),
      questionType: getQuestionType(props.data),
      interactionId: props.data.interactions[0].id,
      question,
      answers: [
        {
          optionId: "option-id",
          value: "3",
        },
      ],
      answerType: props.data.interactions[0].type,
      options:
        props.data.interactions[0].type === "TEXT_ENTRY"
          ? null
          : props.data.interactions[0].options,
    };
    setCurrentQuestion(currentQuestion);
  };

  const setHelperCardImage = async (props: QuestionParserProps) => {
    if (props.data.interactions[0].incorrectFeedback) {
      await getMediaInfo(
        getExternalReference(
          props.data.assets,
          props.data.interactions[0].incorrectFeedback.imageId
        )
      )
        .then((response) => {
          const baseURL = response.data.url.split("?")[0];
          const query = response.data.url.split("?")[1];
          const imageURL = `${baseURL}/previews/maxWidth_700_maxHeight_180.jpg?${query}`;
          setHelperCardURL(imageURL);
        })
        .catch((err) => {});
    }
  };

  return {
    inputText,
    isCorrectAnswer,
    setInputText,
    checkAnswer,
    selectedAnswer,
    setSelectedAnswer,
    isAnswerSubmitted,
    backgroundImage,
    startTimer,
    setStartTimer,
    companion,
    setCompanion,
    questionIndex,
    setQuestionIndex,
    setCurrentQuestion,
    currentQuestion,
    setIsAnswerSubmitted,
    companionProgress,
    setPersonalBest,
    personalBest,
    life,
    setLife,
    showMask,
    setShowMask,
    startSpeedTwoAnimation,
    setStartSpeedTwoAnimation,
    handleSpeedTwoAnimation,
    speedTwoCharacterColor,
    setSpeedTwoCharacterColor,
    showHelperCard,
    showReward,
    setShowReward,
    givenAnswer,
    setGivenAnswer,
    showSoundPopup,
    setShowSoundPopup,
    voiceAudio,
    setVoiceAudio,
    clickedVoice,
    setClickedVoice,
    answerAttempt,
    setAnswerAttempt,
    playAudio,
    setPlayAudio,
    isGameBegin,
    setIsGameBegin,
    setIsCorrectAnswer,
    setCompanionProgress,
    powerExerciseAttemptCount,
    setPowerExerciseAttemptCount,
    handlePowerAnswer,
    showCorrectAnswer,
    increaseIndex,
    onSpeedTwoTimerEnd,
    initGameContainer,
    gotoNextQuestion,
    initInputField,
    fishAnimationContainer,
    spiderAnimationContainer,
    headerProgressBar,
    selectedOptionId,
    setSelectedOptionId,
    optionClick,
    speedOneTimerEnd,
    hideSoundPopup,
    handleAddItemOnChange,
    helperCardURL,
    setHelperCardURL,
    showArrowIcon,
    setShowArrowIcon,
    isGameOver,
    setGameOver,
    isGameWin,
    setGameWin,
    initAudioQuestion,
    handleCorrectAnswer,
    gotoFailScreen,
    gotoWinScreen,
    handleShowExplanationVideo,
    showExplanationVideo,
    showLoader,
    playGameOverAudio,
    shuffleQuestion,
    powerGameHandleLife,
    handleIncorrectAnswer,
    boxContainerStyle,
    setBoxContainerStyle,
    publicationId,
    setPublicationId,
    isShuffleArray,
    setIsShuffleArray,
    exerciseList,
    setExerciseList,
    speedTwoAnimationEnd,
    setVideoViewed,
    isVideoViewed,
    setShowExplanationVideo,
    parseQuestion,
    setHelperCardImage,
    isFrogJump,
    setIsFrogJump,
    handleSpeedTwoTimerEnd,
    isFrogJumpFromScreen,
    setIsFrogJumpFromScreen,
    getLife,
  };
};

