import React, { useEffect, useState } from "react";
import Background from "./block3-background.svg";
import Complete from "./block3-complete.svg";

import FocusGrayedOut from "./block3-focus-grayed-out.svg";
import Focus from "./block3-focus.svg";
import Foreground from "./block3-foreground.svg";
import HelpBlock from "./block3-help-block.svg";

import SvgGraphic from "../../utils/SvgGraphic";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import {
  setExplainationVideoPlayedStatus,
  setLoadingDataComplete,
  setSubMenuBlocks,
  addClickEventOnStone,
  setSubMenuManifestDataByMenuId,
  toggleBlockHelp,
} from "../../utils/SubmenuController";
import { BlockSubMenuProps } from "../Block1BlockMenu";
import { levelURLNames } from "../../component/constant";
import { elementReady } from "../../utils/CheckElement";
type urlParams = {
  state: {
    path?: string;
    levelTitle?: string;
    manifestSubMenuId?: string;
    manifestMenuId?: string;
  };
};
let navigate: NavigateFunction | ((arg0: string, params?: urlParams) => void);

const blockThreeRepeatIcons = [
  [
    { id: "repeat_btn_b3_1_1" },
    { id: "repeat_btn_b3_1_2" },
    { id: "repeat_btn_b3_1_3" },
  ],
  [
    { id: "repeat_btn_b3_2_1" },
    { id: "repeat_btn_b3_2_2" },
    { id: "repeat_btn_b3_2_3" },
  ],
];

const blockThreegrayedBackgrounds = [
  [
    { id: "grayedout_b3_1_1" },
    { id: "grayedout_b3_1_2" },
    { id: "grayedout_b3_1_3" },
  ],
  [
    { id: "grayedout_b3_2_1" },
    { id: "grayedout_b3_2_2" },
    { id: "grayedout_b3_2_3" },
  ],
];
const blockThreePlayButtons = [
  [
    { id: "play_btn_3_1_1" },
    { id: "play_btn_3_1_2" },
    { id: "play_btn_3_1_3" },
  ],
  [
    { id: "play_btn_3_2_1" },
    { id: "play_btn_3_2_2" },
    { id: "play_btn_3_2_3" },
  ],
];

export const useBlock3BlockMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [subMenuManifestData, setSubMenuManifestData] = useState([]);
  const [blockThreeLoadCounter, setBlockThreeLoadCounter] = useState(0);
  const [blockThreeIsLoading, setBlockThreeIsLoading] = useState(true);

  return {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    blockThreeLoadCounter,
    setBlockThreeLoadCounter,
    blockThreeIsLoading,
    setBlockThreeIsLoading,
  };
};

export const setBlockThreeLevelRepeatIcons = (
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  blockThreeRepeatIcons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockThreeLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
  blockThreePlayButtons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockThreeLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
};

export const addClickEventOnBlockThreeLevels = (
  clickableElement: HTMLElement | null,
  index: number,
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  if (clickableElement) {
    clickableElement.addEventListener("click", () => {
      let manifestSubMenuId =
        clickableElement.getAttribute("manifestSubMenuId");
      const blockThreeSubmenuId = clickableElement.getAttribute("subMenuId");
      const blockThreeLevelId = clickableElement.getAttribute("levelId");

      const blockThreeButtonIndex = clickableElement.getAttribute("data-index");

      const isExplainationVideoPlayedList = setExplainationVideoPlayedStatus(
        blockThreeSubmenuId,
        blockThreeButtonIndex,
        explainationVideoPlayedStatus
      );

      navigate(
        `/b3/menu/${menuId}/submenu/${blockThreeSubmenuId}/${levelURLNames[index]}/${blockThreeLevelId}`,
        {
          state: {
            manifestSubMenuId: `${manifestSubMenuId}`,
            manifestMenuId: manifestBlockId,
            isExplainationVideo:
              isExplainationVideoPlayedList.isExplainationVideoPlayed
                ? false
                : true,
            levelTitle: isExplainationVideoPlayedList.levelTitle,
          },
        }
      );
    });

    clickableElement.style.cursor = "pointer";
  }
};

export const useSvgImageBlockThreeLoaded = (
  props: BlockSubMenuProps,
  menuId: any,
  loadCounter: number
) => {
  const {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    setBlockThreeLoadCounter,
  } = useBlock3BlockMenu();

  const onSvgGraphicBlockThreeLoaded = () => {
    loadCounter = loadCounter + 1;
    setBlockThreeLoadCounter(loadCounter);
    if (loadCounter === 6) {
      setIsVisible(true);
      setBlockThreeLevelRepeatIcons(
        menuId || "",
        props.subMenuData,
        props.manifestBlockId
      );
      addClickEventOnStone("doel_knop_drempel_kopie_1_", () => {
        toggleBlockHelp("block_help_3");
      });
      setSubMenuBlocks(
        blockThreeRepeatIcons,
        blockThreegrayedBackgrounds,
        blockThreePlayButtons,
        props.subMenuData,
        subMenuManifestData
      );

      if (props.onLoad) props.onLoad();
    }
  };
  return {
    isVisible,
    setIsVisible,
    onSvgGraphicBlockThreeLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  };
};

const BlockThreeSubMenu = (props: BlockSubMenuProps) => {
  const {
    blockThreeLoadCounter,
    setBlockThreeLoadCounter,
    blockThreeIsLoading,
    setBlockThreeIsLoading,
  } = useBlock3BlockMenu();

  const { menuId } = useParams();

  const {
    isVisible,
    onSvgGraphicBlockThreeLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  } = useSvgImageBlockThreeLoaded(props, menuId, blockThreeLoadCounter);
  navigate = useNavigate();

  const manifestData = useAppSelector(
    (state: any) => state.manifest.manifestData
  );

  useEffect(() => {
    let blockThreeManifestChildren = manifestData?.children;
    setBlockThreeLoadCounter(0);
    setBlockThreeIsLoading(true);
    setSubMenuManifestDataByMenuId(
      blockThreeManifestChildren,
      (data: any) => {
        setSubMenuManifestData(data);
      },
      menuId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingDataComplete(
      props,
      () => {
        setBlockThreeIsLoading(false);
      },
      menuId,
      subMenuManifestData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId, subMenuManifestData, props.subMenuData]);

  return (
    <>
      {!blockThreeIsLoading && (
        <div style={{ visibility: isVisible ? "visible" : "hidden" }}>
          <SvgGraphic src={Background} onLoad={onSvgGraphicBlockThreeLoaded} />
          <SvgGraphic
            src={FocusGrayedOut}
            onLoad={onSvgGraphicBlockThreeLoaded}
          />
          <SvgGraphic src={Focus} onLoad={onSvgGraphicBlockThreeLoaded} />
          <SvgGraphic src={Complete} onLoad={onSvgGraphicBlockThreeLoaded} />

          <SvgGraphic
            src={HelpBlock}
            style={{ display: "none" }}
            id={"block_help_3"}
            onLoad={onSvgGraphicBlockThreeLoaded}
          />
          <SvgGraphic src={Foreground} onLoad={onSvgGraphicBlockThreeLoaded} />
        </div>
      )}
    </>
  );
};
export default BlockThreeSubMenu;
