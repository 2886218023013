import React, { useState, useEffect, useCallback } from "react";

import Nav from "react-bootstrap/Nav";
import { useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import PopUpModal from "../modal/modal";
import soundTestAudio from "../../assets/sound/voicetesting.mp3";
import logoutAudio from "../../assets/sound/logoutmodal.mp3";
import collapseFullScreen from "../../assets/icons/collapse-fullscreen.svg";
import expandFullScreen from "../../assets/icons/expand-fullscreen.svg";
import volume from "../../assets/icons/volume.svg";
import volumeWhite from "../../assets/icons/volume-white.svg";
import setting from "../../assets/icons/setting.svg";
import audioIcon from "../../assets/icons/audiovoice.svg";
import backarrow from "../../assets/icons/backarrow.svg";
import logo from "../../assets/icons/semsomlogo.svg";
import settingIcon from "../../assets/icons/modalsetting.svg";
import { navbarSlice } from "../../redux/navbar/navbar.slice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";

import { manifestInfo } from "../../api";
import AudioVoice from "../audio-voice/audio-voice";
import { isMobileDevice } from "../../utils/mobileDevice";
import { stopAudioById } from "../../utils/AudioPlayerController";
import { getEnvironmentUrl } from "../../services/base";
import { manifestSlice } from "../../redux/manifest/manifest.slice";
import { authInfoSlice } from "../../redux/auth/auth.slice";

export interface NavProps {
  leftContent?: string;
  navigation?: any;
  handleFullScreen: Function;
  navigateRoute?: Function;
  hideSemsomTitle?: boolean;
}

export const useTopNavbar = (dispatch: Function) => {
  const [openPopOut, setOpenPopup] = useState(false);
  const [soundSetting, setSoundSetting] = useState(false);
  const [voiceAudio, setVoiceAudio] = useState(false);
  const [clickedVoice, setClickedVoice] = useState(false);
  const [fullScreenIcon, setFullScreenIcon] = useState(expandFullScreen);

  const logoutHandle = async () => {
    const baseUrl = await getEnvironmentUrl();
    window.location.href = `${baseUrl}/auth/logout`;
    localStorage.removeItem("mainMenuOpenAutoFullScreen");
    sessionStorage.removeItem("showSoundPopup");
    dispatch(navbarSlice.actions.resetPopOut(false));
    dispatch(authInfoSlice.actions.resetAuth());
  };

  const stopAllAudio = () => {
    document
      .querySelectorAll("audio")
      .forEach((audioElement: HTMLAudioElement) => {
        audioElement.muted = true;
        audioElement.pause();
      });
  };

  const closeVoiceModal = useCallback(() => {
    setSoundSetting(false);
    stopAudioById("sound-test-audio-player");
  }, []);

  const isFullScreen = () => {
    return !!document.fullscreenElement;
  };

  const onFullscreenChange = () => {
    if (isFullScreen()) {
      setFullScreenIcon(collapseFullScreen);
      window.localStorage.setItem("fullscreenviewer", "false");
    } else {
      setFullScreenIcon(expandFullScreen);
      window.localStorage.setItem("fullscreenviewer", "true");
    }
  };
  const manifestInformationResponses = (response1: any, response2: any) => {
    const yearFourChildren: Array<any> = [];
    response2.children.forEach((response: any, index: number) => {
      response.number = index === 0 ? 9 : 10;
      yearFourChildren.push(response);
    });
    const listOfChildren = [...response1.children, ...yearFourChildren];
    let manifestDataDetail;
    manifestDataDetail = {
      ...response1,
      children: listOfChildren,
      publicationId: response1.publicationId,
      publicationIdYearFour: response2.publicationId,
      year: response2.year,
    };
    return manifestDataDetail;
  };

  return {
    openPopOut,
    setOpenPopup,
    soundSetting,
    setSoundSetting,
    voiceAudio,
    setVoiceAudio,
    clickedVoice,
    setClickedVoice,
    logoutHandle,
    fullScreenIcon,
    isFullScreen,
    onFullscreenChange,
    closeVoiceModal,
    stopAllAudio,
    manifestInformationResponses,
  };
};

function TopNavbar(props: NavProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authDetail = useAppSelector((state: any) => state.authInfo);
  const popOut = useAppSelector((state: any) => state.navbar.openDropDown);

  const {
    openPopOut,
    setOpenPopup,
    soundSetting,
    setSoundSetting,
    voiceAudio,
    setVoiceAudio,
    clickedVoice,
    setClickedVoice,
    logoutHandle,
    fullScreenIcon,
    onFullscreenChange,
    closeVoiceModal,
    stopAllAudio,
    manifestInformationResponses,
  } = useTopNavbar(dispatch);

  useEffect(() => {
    async function manifestApi() {
      Promise.all([
        manifestInfo({
          course: "semsom-zwitch",
          product: "automatiseren",
          year: "3",
        }),
        manifestInfo({
          course: "semsom-zwitch",
          product: "automatiseren",
          year: "4",
        }),
      ])
        .then(([response1, response2]) => {
          const manifestInformationResponseResult =
            manifestInformationResponses(response1.data, response2.data);

          dispatch(
            manifestSlice.actions.updateManifest(
              manifestInformationResponseResult
            )
          );
        })
        .catch((error) => {});
    }

    manifestApi();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (props.navigateRoute) {
        props.navigateRoute();
      }
    }, 5000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const audio = document.getElementById(
      "logout-sound-player"
    ) as HTMLAudioElement;
    openPopOut ? audio.play() : stopAudioById("logout-sound-player");
  }, [openPopOut]);

  useEffect(() => {
    onFullscreenChange();
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () =>
      window.removeEventListener("fullscreenchange", onFullscreenChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const splitUserName = (username: string) => {
    let words = username.split(" ");
    let firstLetters = words.map((word) => word[0]);
    return firstLetters.toString().split(",").join("");
  };
  return (
    <>
      <PopUpModal
        size="sm"
        dialogClassName="logout-modal"
        show={openPopOut}
        close={() => setOpenPopup(false)}
        centered={true}
        title={""}
      >
        <h4 className="text-dark medium-text">Stoppen?</h4>
        <div className="button-styles">
          <button
            data-testid="btn-logout"
            style={{
              backgroundColor: "#1D9D9",
            }}
            className="btn-dark btn-logout"
            onClick={() => {
              setOpenPopup(false);
            }}
            title=""
            aria-label=""
          >
            <img draggable="false" src={backarrow} alt="back" />
          </button>
          <button
            data-testid="btn-test-logout"
            aria-label="logout"
            className="btn-dark btn-logout"
            title=""
            onClick={logoutHandle}
          >
            OK
          </button>
        </div>
      </PopUpModal>

      <PopUpModal
        size="lg"
        dialogClassName=" voice-setting"
        show={soundSetting}
        close={closeVoiceModal}
        muteAudio={() => setVoiceAudio(false)}
        centered={true}
        title={""}
      >
        <h1 className="text-dark mb-4 medium-text">Mijn instellingen</h1>
        <p className="mb-4 medium-text">Test hier je geluid:</p>
        <div className="sound-setting-wrapper">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
            }}
            className="setting-button"
          >
            <button
              data-testid="sound-button"
              aria-label="sound button"
              className="btn-dark btn-logout"
              onClick={() => {
                (
                  document.getElementById(
                    "sound-test-audio-player"
                  ) as HTMLAudioElement
                ).play();
                setClickedVoice(true);
                setVoiceAudio(!voiceAudio);
              }}
              title=""
            >
              <img draggable="false" src={audioIcon} alt="audiovoice" />
            </button>
          </div>
        </div>
        <div className="ok-setting-wrapper">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="setting-button"
          >
            <button
              data-testid="ok-button"
              aria-label="ok button"
              className={`${
                !clickedVoice
                  ? "disable-button btn-logout"
                  : "btn-dark btn-logout"
              } `}
              disabled={clickedVoice ? false : true}
              onClick={() => {
                setSoundSetting(false);
                setClickedVoice(false);
                stopAudioById("sound-test-audio-player");
              }}
              title=""
            >
              OK
            </button>
          </div>
        </div>
      </PopUpModal>

      <AudioVoice
        data-testid="audio-voice"
        id={"sound-test-audio-player"}
        url={soundTestAudio}
      />
      <AudioVoice
        data-testid="log-out"
        id={"logout-sound-player"}
        url={logoutAudio}
      />

      <Navbar className="px-4 py-0  navbar-container" bg="primary" expand="lg">
        <Navbar.Brand className="text-secondary">
          {props.leftContent === "back" ? (
            <div
              data-testid="clickable-navigate"
              onClick={() => {
                props.navigateRoute ? props.navigateRoute() : navigate(-1);
                stopAllAudio();
              }}
              className="left-nav-content user-events-select"
              title=""
            >
              <img draggable="false" src={backarrow} alt="back" />
            </div>
          ) : null}
        </Navbar.Brand>
        {!props.hideSemsomTitle && (
          <Navbar.Text className="mx-auto navbar-content-position">
            <img
              draggable="false"
              className="user-events-select"
              src={logo}
              alt="semsomlogo"
            />
          </Navbar.Text>
        )}
        <div className="right-nav">
          <Navbar.Text className="student-username user-events-select user-name">
            {authDetail.userDetail?.fullName}
          </Navbar.Text>
          <Navbar.Text className="student-username-short user-events-select user-name ">
            {splitUserName(authDetail.userDetail?.fullName)}
          </Navbar.Text>
          {isMobileDevice ? (
            <Nav>
              <button
                data-testid="setting-button"
                aria-label="setting button"
                onClick={() => setSoundSetting(true)}
                className="menuIcon  setting-color setting-icon-menu"
              >
                <img draggable="false" src={volumeWhite} alt="volume" />
              </button>
            </Nav>
          ) : (
            <Nav>
              <button
                data-testid="setting-button"
                aria-label="setting button"
                onClick={() => {
                  dispatch(navbarSlice.actions.openPopOut());
                }}
                className="menuIcon  setting-color setting-icon-menu"
              >
                <img
                  draggable="false"
                  className="user-events-select"
                  src={setting}
                  alt="setting"
                />
              </button>
              <div
                id="myDropdown"
                className={`dropdown-content ${
                  popOut && "show-growth-dropdown"
                }`}
              >
                <div
                  data-testid="handle-full-screen"
                  onClick={() => {
                    props.handleFullScreen();
                  }}
                  className="border-right cursor-pointer line-border"
                >
                  <img
                    draggable="false"
                    src={fullScreenIcon}
                    alt="fullscreen-icon"
                  />
                </div>
                <div
                  data-testid="volume-setting"
                  className="d-flex align-items-center mx-2 cursor-pointer"
                  onClick={() => {
                    setSoundSetting(true);
                    dispatch(navbarSlice.actions.resetPopOut(false));
                  }}
                >
                  <img
                    className="user-events-select"
                    draggable="false"
                    src={volume}
                    alt="volume"
                  />
                </div>
              </div>
            </Nav>
          )}
          <Nav>
            <Nav.Link
              data-testid="nav-link"
              onClick={() => {
                popOut && dispatch(navbarSlice.actions.resetPopOut(false));
                setOpenPopup(true);
                setVoiceAudio(true);
              }}
              className="menuIcon"
              aria-label=""
              aria-labelledby=""
            >
              <img
                draggable="false"
                src={settingIcon}
                className="user-events-select"
                alt="modal-setting-icon"
              />
            </Nav.Link>
          </Nav>
        </div>
      </Navbar>
    </>
  );
}

export default TopNavbar;
