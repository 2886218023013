import React, { useState, useEffect } from "react";
export interface CompanionGraphicProps {
  id?: string;
  src: string;
  onLoad?: Function;
}

export const useCompanionGraphic = () => {
  const [svgContent, setSvgContent] = useState("");
  function setGraphic(props: CompanionGraphicProps) {
    fetch(props.src)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setSvgContent(text);
        if (props.onLoad) props.onLoad();
      });
  }
  return { svgContent, setSvgContent, setGraphic };
};

const CompanionGraphic = (props: CompanionGraphicProps) => {
  const { svgContent, setGraphic } = useCompanionGraphic();

  useEffect(() => {
    setGraphic(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return (
    <div
      data-testid={`test-${props.id}`}
      className={"svg-graphic"}
      id={props.id}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

export default CompanionGraphic;
