export const playAudioById = (playerId: string) => {
  const audioPlayer = document.getElementById(playerId) as HTMLAudioElement;
  if (audioPlayer) {
    const playAudio = () => {
      audioPlayer.play();
      audioPlayer.removeEventListener("canplay", playAudio);
      audioPlayer.setAttribute("listener", "false");
    };

    audioPlayer.addEventListener("canplay", playAudio);
    audioPlayer.setAttribute("listener", "true");
  }
};

export const stopAudioById = (playerId: string) => {
  const audioPlayer = document.getElementById(playerId) as HTMLAudioElement;
  if (audioPlayer) {
    audioPlayer.pause();
    audioPlayer.currentTime = 0;
  }
};
