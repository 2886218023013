import React, { useCallback, useEffect, useState } from "react";
import TopNavbar from "../../component/navbar/navbar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import GameContainer, {
  GameType,
  PlayerInfo,
} from "../../component/game-container/game-container";
import { exercisesPlayerStart } from "../../api";
import { useAppSelector } from "../../redux/hooks";
import SettingOverlay from "../../component/setting-overlay/setting-overlay";
import { getGameTitle } from "../../component/game-container/game-utils";

type LevelProps = {
  handleFullScreen: Function;
  gameType: GameType;
  showFrog: boolean;
  exerciseId: string | undefined;
  playerInfo: PlayerInfo;
  parameterInformation: manifestInfo;
};
type manifestInfo = {
  manifestSubMenuId?: string;
  manifestMenuId?: string;
  isExplanationVideo?: boolean;
};

export const useLevel = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [exerciseAttemptNumber, setExerciseAttemptNumber] = useState(0);
  const [isGameBegin, setIsGameBegin] = useState(false);
  const [gameDuration, setGameDuration] = useState(0);
  const [exercise, setExercise] = useState<any[] | null>(null);

  return {
    loading,
    setLoading,
    isGameBegin,
    setIsGameBegin,
    setExerciseAttemptNumber,
    exerciseAttemptNumber,
    exercise,
    setExercise,
    gameDuration,
    setGameDuration,
  };
};

export default function Level(props: LevelProps) {
  const {
    loading,
    isGameBegin,
    setLoading,
    setIsGameBegin,
    setExerciseAttemptNumber,
    exerciseAttemptNumber,
    exercise,
    setExercise,
    gameDuration,
    setGameDuration,
  } = useLevel();

  let handle = useFullScreenHandle();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const beginGame = useCallback((value: boolean) => setIsGameBegin(value), []);

  const popOut = useAppSelector((state: any) => state.navbar.openDropDown);
  const manifestData = useAppSelector((state: any) => state.manifest);
  const parameterInformationList = {
    learningObjectiveId: props.parameterInformation.manifestSubMenuId,
    blockId: props.parameterInformation?.manifestMenuId,
    courseName: manifestData.manifestData?.course,
    publicationId:
      props.playerInfo.menuId === 9 || props.playerInfo.menuId === 10
        ? manifestData.manifestData?.publicationIdYearFour
        : manifestData.manifestData?.publicationId,
  };

  useEffect(() => {
    async function getContentInformation() {
      setLoading(true);
      await exercisesPlayerStart({
        menuId: props.playerInfo.menuId,
        subMenuId: props.playerInfo.subMenuId,
        levelTitle: getGameTitle(props.gameType),
        publicationId:
          props.playerInfo.menuId === 9 || props.playerInfo.menuId === 10
            ? manifestData.manifestData?.publicationIdYearFour
            : manifestData.manifestData?.publicationId,
        exerciseId: props.exerciseId ? props.exerciseId : "",
        exerciseAttemptNumber: 0,
        status: 0,
        learningObjectiveId: props.parameterInformation.manifestSubMenuId,
        blockId: props.parameterInformation.manifestMenuId,
        courseName: manifestData.manifestData?.course,
      })
        .then((response: any) => {
          setExerciseAttemptNumber(response.data.exerciseAttempt);
          setExercise(response);
          setGameDuration(response.data.timerDuration);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    if (
      props.parameterInformation.manifestSubMenuId &&
      props.parameterInformation.manifestMenuId &&
      !isGameBegin
    ) {
      getContentInformation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, props.parameterInformation, isGameBegin]);

  return (
    <FullScreen handle={handle}>
      <TopNavbar
        leftContent="back"
        hideSemsomTitle
        handleFullScreen={props.handleFullScreen}
      />
      {popOut && <SettingOverlay />}
      <GameContainer
        data-testid="game-contain"
        gameType={props.gameType}
        showFrog={props.showFrog}
        isLoading={loading}
        exerciseList={exercise}
        exerciseAttemptNumber={exerciseAttemptNumber}
        isGameBegin={isGameBegin}
        answerType={"text"}
        updateGameBegin={beginGame}
        gameDuration={gameDuration}
        playerInfo={props.playerInfo}
        parameterInformation={parameterInformationList}
        isExplanationVideo={props.parameterInformation.isExplanationVideo}
      />
    </FullScreen>
  );
}
