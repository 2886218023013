import { Companions } from "../progress-bar/companion/companion";
import { GameType } from "./game-container";
import powerBackground from "../../assets/background_img/power.jpg";
import speed1Background from "../../assets/background_img/speed1.jpg";
import speed2Background from "../../assets/background_img/speed2.jpg";
import powerWaterBackground from "../../assets/background_img/Achtergrond_level1.svg";
import speedTwoLandBackground from "../../assets/background_img/achtergrond_level3.svg";
import speedOneAirBackground from "../../assets/background_img/achtergrond_level2_background.svg";
export const handleBackSpace = () => {
  const input = document.getElementById("answer-input-box") as HTMLInputElement;
  const value = input.value.slice(0, -1);
  input.value = value;
  input.parentElement?.classList.remove("right-answer-box");
  input.parentElement?.classList.remove("wrong-answer-box");
};

export const handleAddItem = (inputItem: number, callback: () => void) => {
  const input = document.getElementById("answer-input-box") as HTMLInputElement;
  input.value = input.value + inputItem.toString();
  input.parentElement?.classList.remove("right-answer-box");
  input.parentElement?.classList.remove("wrong-answer-box");
  callback();
};

export const setCompanionCharacter = (
  thresholdId: string | undefined,
  updateState: (companion: Companions) => void
) => {
  if (thresholdId === "b1" || thresholdId === "b2") {
    updateState(Companions.Ladybug);
  } else if (thresholdId === "b3" || thresholdId === "b4") {
    updateState(Companions.Leaf);
  } else if (thresholdId === "b5") {
    updateState(Companions.Grasshopper);
  } else if (thresholdId === "b6" || thresholdId === "b7") {
    updateState(Companions.Snail);
  } else if (thresholdId === "b8") {
    updateState(Companions.Elephant);
  } else if (thresholdId === "b9") {
    updateState(Companions.Bug);
  } else if (thresholdId === "b10") {
    updateState(Companions.Firefly);
  }
};

export const setBackground = (
  gameType: GameType,
  updateState: (url: string) => void
) => {
  switch (gameType) {
    case GameType.Power:
      updateState(powerBackground);
      break;
    case GameType.SpeedOne:
      updateState(speed1Background);
      break;
    case GameType.SpeedTwo:
      updateState(speed2Background);
      break;
    case GameType.PowerWater:
      updateState(powerWaterBackground);
      break;
    case GameType.SpeedOneAir:
      updateState(speedOneAirBackground);
      break;
    case GameType.SpeedTwoLand:
      updateState(speedTwoLandBackground);
      break;
  }
};

export const disableSingleChoiceQuestion = (isDisable: boolean) => {
  const options = document.getElementsByClassName("answer-box-container");
  for (const option of options) {
    option.classList.remove("selected-option");
    option.classList.remove("option-wrong-answer");
    option.classList.remove("option-right-answer");
    const optionButton = option as HTMLElement;
    if (isDisable) {
      optionButton.style.pointerEvents = "none";
    } else {
      optionButton.classList.add("answer-box-container");
      optionButton.style.pointerEvents = "auto";
    }
  }
};

export const isOkButtonDisabled = () => {
  return document
    .getElementById("ok-button")
    ?.classList.contains("disable-button");
};

export const disableOkButton = (isDisable: boolean) => {
  const okButton = document.getElementById("ok-button");
  isDisable
    ? okButton?.classList.add("disable-button")
    : okButton?.classList.remove("disable-button");
};

export const getExternalReference = (array: Array<any>, assetId: string) => {
  const object = array.find((item) => item.id === assetId);
  return object.externalReference;
};

export const getQuestionType = (data: any) => {
  const questionAssetIds = data.interactions[0]?.question?.assetIds;

  if (questionAssetIds && questionAssetIds.length > 0) {
    return data.assets[0].type === "AUDIO" ? "voice" : "image";
  } else {
    return "text";
  }
};
export const getGameTitle = (gameType: GameType) => {
  switch (gameType) {
    case GameType.PowerWater:
      return GameType.Power;
    case GameType.SpeedOneAir:
      return GameType.SpeedOne;
    case GameType.SpeedTwoLand:
      return GameType.SpeedTwo;
    default:
      return gameType;
  }
};
