import { useCallback } from "react";
import SvgGraphic from "../../../utils/SvgGraphic";
import block1focus from "./block1-focus.svg";
import block2focus from "./block2-focus.svg";
import block3focus from "./block3-focus.svg";
import block4focus from "./block4-focus.svg";
import block5focus from "./block5-focus.svg";
import block6focus from "./block6-focus.svg";
import block7focus from "./block7-focus.svg";
import block8focus from "./block8-focus.svg";
import block9focus from "./block9-focus.svg";
import block10focus from "./block10-focus.svg";

type BlockFocusProps = {
  onCompleteLoad: Function;
};

export const useBlockFocus = (
  useCallback: Function,
  loadCounter: number,
  props: any
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadComplete = useCallback(() => onSvgGraphicLoaded(), []);
  const onSvgGraphicLoaded = () => {
    loadCounter += 1;
    if (loadCounter === 10) {
      props.onCompleteLoad();
    }
  };
  return { onSvgGraphicLoaded, loadComplete };
};

const BlockFocus = (props: BlockFocusProps) => {
  let loadCounter = 0;
  const { loadComplete } = useBlockFocus(useCallback, loadCounter, props);

  return (
    <>
      <SvgGraphic
        src={block1focus}
        onLoad={loadComplete}
        className="block-focus"
      />
      <SvgGraphic
        src={block2focus}
        onLoad={loadComplete}
        className="block-focus"
      />
      <SvgGraphic
        src={block3focus}
        onLoad={loadComplete}
        className="block-focus"
      />
      <SvgGraphic
        src={block4focus}
        onLoad={loadComplete}
        className="block-focus"
      />
      <SvgGraphic
        src={block5focus}
        onLoad={loadComplete}
        className="block-focus"
      />
      <SvgGraphic
        src={block6focus}
        onLoad={loadComplete}
        className="block-focus"
      />
      <SvgGraphic
        src={block7focus}
        onLoad={loadComplete}
        className="block-focus"
      />
      <SvgGraphic
        src={block8focus}
        onLoad={loadComplete}
        className="block-focus"
      />
      <SvgGraphic
        src={block9focus}
        onLoad={loadComplete}
        className="block-focus"
      />
      <SvgGraphic
        src={block10focus}
        onLoad={loadComplete}
        className="block-focus"
      />
    </>
  );
};
export default BlockFocus;
