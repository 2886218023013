import { ReactNode } from "react";
import MonkeyImg from "../assets/background_img/monkey.png";
import foregroundImage from "../assets/background_img/outer.png";
import MiniMap from "../component/mini-map/mini-map";

type pageProps = {
  backgroundURL: string;
  childElement?: ReactNode;
  level?: number;
  onMiniMapClick?: Function;
  showForeground?: boolean;
  showCharacter?: boolean;
};

function Page(props: pageProps) {
  const maxLevel = 10;
  const showForegroundImage = props.showForeground || false;
  const showCharacter = props.showCharacter || false;
  const showLevelMap =
    props.level && props.level <= maxLevel && props.level > 0 ? true : false;

  return (
    <>
      <div
        className="page-container"
        style={{
          background: `url(${props.backgroundURL}) no-repeat center bottom`,
        }}
      >
        {props.childElement && (
          <div className="page-child-container" data-testid="child-container">
            {" "}
            {props.childElement}{" "}
          </div>
        )}
        {showForegroundImage && (
          <div
            className="foreground"
            style={{
              background: `url(${foregroundImage}) no-repeat center bottom`,
            }}
            data-testid="foreground-image"
          ></div>
        )}
        {showLevelMap && (
          <MiniMap
            level={props.level}
            onClick={() => {
              props.onMiniMapClick && props.onMiniMapClick();
            }}
            data-testid="mini-map"
          />
        )}
        {showCharacter && (
          <div
            className="top-right-character"
            data-testid="top-right-character"
          >
            <img
              className="monkey-image"
              draggable="false"
              src={MonkeyImg}
              alt="monkey "
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Page;
