import React, { useCallback, useEffect, useRef, useState } from "react";
import volume from "../../assets/icons/audiovoice.svg";
import TextInput from "../text-input/text-input";
import AnswerBox from "../answer-box/answer-box";
import AudioVoice from "../audio-voice/audio-voice";
import { playAudioById } from "../../utils/AudioPlayerController";
import Frog from "../frog-animation-elements/frog";
import { isOkButtonDisabled } from "../game-container/game-utils";

type BoxContainerProps = {
  showFrog: boolean;
  jumpFrog: boolean;
  jumpFrogFromScreen: boolean;
  questionType: string;
  questionContent?: any;
  onInputChange: Function;
  onEnter: Function;
  onOptionClick: Function;
  onQuestionChange: Function;
  autoAnswerOption: string;
  isAnswerCorrect: boolean | undefined;
  isAnswerSubmitted: boolean;
  playAudio: boolean;
  answerType?: string;
  questionIndex?: number;
  life: number;
};

type optionType = {
  index: number;
  id: string;
};

export const useBoxContainer = () => {
  const [selectedOption, setSelectedOption] = useState<optionType>({
    index: -1,
    id: "null",
  });

  const [question, setQuestion] = useState("");
  const [assetURL, setAssetURL] = useState("");
  const [isAssetLoaded, setIsAssetLoaded] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [inputStyle, setInputStyle] = useState("");
  const childInputRef = useRef<HTMLInputElement>(null);

  const showBoxContainer = (box: HTMLElement) => {
    setTimeout(() => {
      box.style.opacity = "0";
      box.classList.add("animate-box-container");
    }, 500);
  };

  return {
    selectedOption,
    setSelectedOption,
    question,
    setQuestion,
    assetURL,
    setAssetURL,
    childInputRef,
    showBoxContainer,
    isAssetLoaded,
    setIsAssetLoaded,
    isAudioPlaying,
    setIsAudioPlaying,
    inputStyle,
    setInputStyle,
  };
};

export default function BoxContainer(props: BoxContainerProps) {
  const {
    question,
    setQuestion,
    assetURL,
    setAssetURL,
    childInputRef,
    selectedOption,
    setSelectedOption,
    showBoxContainer,
    isAssetLoaded,
    setIsAssetLoaded,
    isAudioPlaying,
    setIsAudioPlaying,
    inputStyle,
    setInputStyle,
  } = useBoxContainer();

  const optionClick = useCallback(
    (index: number, item: any) => (e: any) => {
      setSelectedOption({ index, id: item.id });
      props.onOptionClick(item, index);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onOptionClick]
  );

  const onKeyPress = useCallback(
    (e: any) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        if (!isOkButtonDisabled()) props.onEnter(e);
      } else {
        handleKeyPress(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onEnter]
  );

  const handleKeyPress = (e: any) => {
    if (
      e.key !== "Backspace" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      const charStr = e.key.toString();
      if (!charStr.match(/^\d/)) {
        e.preventDefault();
      } else {
        const button = document.getElementById(`key-${charStr}`);
        if (button) button.classList.add("active-button");
      }
    } else if (e.key === "Backspace") {
      const backspaceKey = document.getElementById("clear-button");

      backspaceKey?.classList.add("backspace-key-select");
    }
  };

  const onKeyUp = useCallback(
    (e: any) => {
      for (let i = 0; i < 11; i += 1) {
        const keyId = `key-${i}`;
        const key = document.getElementById(keyId);
        key?.classList.remove("active-button");
      }
      const backspaceKey = document.getElementById("clear-button");
      backspaceKey?.classList.remove("backspace-key-select");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const focusInput = () => {
    if (props.answerType === "TEXT_ENTRY") {
      const input = document.getElementById(
        "answer-input-box"
      ) as HTMLInputElement;
      input.focus();
    }
  };

  const playAudioQuestion = () => {
    const audioPlayer = document.getElementById(
      "question-audio-player"
    ) as HTMLAudioElement;
    audioPlayer.src = "";
    audioPlayer.src = assetURL;
    playAudioById("question-audio-player");
  };

  const inputChange = useCallback(
    (e: any) => {
      props.onInputChange(e);
      const input = document.getElementById("answer-input-box");
      input?.parentElement?.classList.remove("right-answer-box");
      input?.parentElement?.classList.remove("wrong-answer-box");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onInputChange]
  );

  useEffect(() => {
    if (
      props.autoAnswerOption &&
      props.isAnswerCorrect &&
      props.answerType === "SINGLE_CHOICE"
    ) {
      const index = props.questionContent.options.findIndex(
        (option: any) => option.id === props.autoAnswerOption
      );

      setSelectedOption({ index, id: props.autoAnswerOption });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.autoAnswerOption, props.isAnswerCorrect]);

  useEffect(() => {
    if (props.questionContent) {
      const box = document.getElementById("question-box-container");
      if (props.answerType === "TEXT_ENTRY") {
        const input = document.getElementById(
          "answer-input-box"
        ) as HTMLInputElement;
        input.value = "";
        input.removeAttribute("readOnly");
        if (props.questionIndex && props.questionIndex > 0) {
          input.focus();
        }
        input?.parentElement?.classList.remove("right-answer-box");
        input?.parentElement?.classList.remove("wrong-answer-box");
        setIsAssetLoaded(false);
      }
      props.questionType === "text"
        ? setQuestion(props.questionContent.question)
        : setAssetURL(props.questionContent.question);

      box?.classList.remove("animate-box-container");

      if (
        !props.showFrog &&
        box &&
        props.questionIndex &&
        props.questionIndex > 0
      ) {
        showBoxContainer(box);
      }
    }
    props.showFrog ? setInputStyle("frog-input") : setInputStyle("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questionContent]);

  useEffect(() => {
    const wrapper = document.querySelector(
      ".box-container-question-wrapper"
    ) as HTMLElement;

    wrapper?.classList.remove("frog-jump-animation");
    wrapper?.classList.remove("frog-jump-from-screen-animation");
    props.jumpFrog && props.showFrog
      ? wrapper?.classList.add("frog-jump-animation")
      : wrapper?.classList.remove("frog-jump-animation");
    props.jumpFrogFromScreen && props.showFrog
      ? wrapper?.classList.add("frog-jump-from-screen-animation")
      : wrapper?.classList.remove("frog-jump-from-screen-animation");
  }, [props.jumpFrog, props.showFrog, props.jumpFrogFromScreen]);

  useEffect(() => {
    if (props.questionType === "text") props.onQuestionChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  useEffect(() => {
    setSelectedOption({
      index: -1,
      id: "",
    });
    if (
      props.questionContent &&
      props.playAudio &&
      props.questionType === "voice"
    ) {
      playAudioQuestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questionContent, props.playAudio, props.questionType]);

  useEffect(() => {
    if (props.answerType === "TEXT_ENTRY") {
      const input = document.getElementById(
        "answer-input-box"
      ) as HTMLInputElement;
      input?.parentElement?.classList.remove("right-answer-box");
      input?.parentElement?.classList.remove("wrong-answer-box");
      input?.parentElement?.classList.remove("auto-answer-box");

      if (props.isAnswerCorrect && props.isAnswerSubmitted) {
        input?.parentElement?.classList.add("right-answer-box");
      } else if (!props.isAnswerCorrect && props.isAnswerSubmitted) {
        input?.parentElement?.classList.add("wrong-answer-box");
      }
    } else {
      const selected = document.getElementById(
        selectedOption.id
      ) as HTMLElement;
      if (props.isAnswerCorrect && props.isAnswerSubmitted && selected) {
        selected.removeAttribute("class");
        selected.classList.add("answer-box-container");
        selected.classList.add("option-right-answer");
      } else if (
        !props.isAnswerCorrect &&
        props.isAnswerSubmitted &&
        selected
      ) {
        selected.removeAttribute("class");
        selected.classList.add("answer-box-container");
        selected.classList.add("option-wrong-answer");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAnswerCorrect, props.isAnswerSubmitted, selectedOption]);

  return (
    <>
      {props?.questionContent && (
        <div>
          <div
            id={"question-box-container"}
            className={`question-container ${
              props.questionType !== "text"
                ? "media-container"
                : "text-container"
            }`}
          >
            {props.showFrog && <Frog life={props.life}></Frog>}

            {props.questionType === "text" ? (
              <div
                className="text-question-container"
                data-testid="text-question-container"
              >
                <div className="question-wrapper">{question}</div>
              </div>
            ) : (
              <div
                className="media-question-container"
                data-testid="media-question-container"
              >
                {props.questionType === "voice" ? (
                  <>
                    <button
                      data-testid="sound-button"
                      aria-label="sound button"
                      id="sound-button"
                      className={`btn-dark btn-audio ${
                        isAudioPlaying ? "audio-playing" : "audio-stop"
                      }`}
                      onClick={() => {
                        focusInput();
                        playAudioQuestion();
                      }}
                      title=""
                    >
                      <img
                        src={volume}
                        className="user-events-select"
                        draggable="false"
                        alt="audiovoice"
                      />
                    </button>
                    <AudioVoice
                      id="question-audio-player"
                      data-testid="audio-voice"
                      url={assetURL}
                      onPlay={() => {
                        setIsAudioPlaying(true);
                        props.onQuestionChange();
                      }}
                      onEnded={() => {
                        setIsAudioPlaying(false);
                      }}
                    ></AudioVoice>
                  </>
                ) : (
                  <>
                    <img
                      style={{
                        visibility: isAssetLoaded ? "visible" : "hidden",
                      }}
                      draggable="false"
                      id="image-question"
                      className="image-question"
                      src={assetURL}
                      onLoad={() => {
                        setIsAssetLoaded(true);
                        props.onQuestionChange();
                      }}
                      alt=""
                    />
                  </>
                )}
              </div>
            )}
            {props.answerType !== "SINGLE_CHOICE" ? (
              <div className={`input-field ${inputStyle}`}>
                <TextInput
                  childInputRef={childInputRef}
                  id={"answer-input-box"}
                  data-testid={"box-test-container-text-input"}
                  onChange={inputChange}
                  onKeyPress={onKeyPress}
                  onKeyUp={onKeyUp}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {props.questionContent.options &&
                  props.questionContent.options.map(
                    (item: any, index: number) => {
                      return (
                        props.questionContent.options && (
                          <AnswerBox
                            id={item.id}
                            answerStyle={`
                              ${
                                selectedOption.index === index
                                  ? "selected-option"
                                  : ""
                              }`}
                            onClick={optionClick(index, item)}
                            answerItem={item.text.replace(/(<([^>]+)>)/gi, "")}
                            index={index}
                            key={item.id}
                          />
                        )
                      );
                    }
                  )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
