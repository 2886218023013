import React, { useCallback, useEffect, useState } from "react";
import progressBar from "../../assets/progress-bar/progress-bar.svg";
import timerProgressBar from "../../assets/progress-bar/timer-progress-bar.svg";
import { Companions } from "./companion/companion";
import PreviousBestCompanion from "./companion/previous-best-companion";
import ProgressCompanion from "./companion/progress-companion";
import Life, { LifeProps } from "./life/life";
import TimerBackground from "../../assets/progress-bar/background/timer-background.svg";
import ProgressBackground from "../../assets/progress-bar/background/progress-background.svg";
import AirTimerBackground from "../../assets/progress-bar/background/air-timer-background.svg";
import AirProgressBackground from "../../assets/progress-bar/background/air-progress-background.svg";
import BirdFlat from "../../assets/progress-bar/background/bird-flat.svg";
import BirdFlatProgress from "../../assets/progress-bar/background/bird-flat-progress.svg";
import SvgGraphic from "../../utils/SvgGraphic";
import Bird from "../bird/bird";
import { GameType } from "../game-container/game-container";

export enum ProgressColors {
  Purple = "#662282",
  Yellow = "#EFC516",
  Blue = "#25328A",
}

export enum Backgrounds {
  Timer = "timer",
  Progress = "progress",
  Air_Progress = "air-progress",
  Air_Timer = "air-timer",
}

type ProgressBarProps = {
  companion: Companions;
  color: ProgressColors;
  progress: number;
  position: number;
  previousBestPosition: number;
  showMask: boolean;
  onHangingSpiderAnimationComplete?: (isCompleted: boolean) => void;
  life?: LifeProps;
  showReward?: boolean;
  backgroundType?: Backgrounds;
  isGameStarted: boolean;
  gameType?: GameType;
};

export const setProgress = (
  isTimerProgressBar: boolean,
  progress: number,
  color: ProgressColors,
  backgroundType: Backgrounds | ""
) => {
  const progressBarId = isTimerProgressBar
    ? "timer_progress_bar"
    : "progress_bar";
  const birdColorPrefix = setBirdColor(backgroundType);
  const flatOfBird = setAirGameForBackground(backgroundType);
  const barPrefix = isTimerProgressBar ? "timer_bar" : "default_bar";
  const flatPrefix = flatOfBird === "bird-flats" ? "flat" : "flat_progress";
  const progressBarSVG = document.getElementById(progressBarId);
  const children = progressBarSVG?.children;
  const bird_flat = flatOfBird && document.getElementById(flatOfBird);
  const bird_flat_children = bird_flat?.children;

  const nextDashedLine = document.getElementById(`${barPrefix}${progress + 1}`);

  if (children) {
    resetProgressBar(children);

    if (bird_flat_children) {
      resetBirdFlat(bird_flat_children);
    }

    if (progress > 0 && progress <= children.length) {
      for (let i = 1; i <= progress; i += 1) {
        const stepSVG = document.getElementById(`${barPrefix}${i}`);
        stepSVG?.setAttribute("fill", color);
        showBirdAtAirGame(
          backgroundType,
          flatPrefix,
          i,
          birdColorPrefix,
          nextDashedLine
        );
      }
    }
  }
};
export const resetProgressBar = (children: HTMLCollection) => {
  for (let element of children) {
    element.setAttribute("fill", "#ffffff");
  }
};

export const resetBirdFlat = (bird_flat_children: HTMLCollection) => {
  for (let element of bird_flat_children) {
    element.setAttribute("style", "display:none");
  }
};

export const showBirdAtAirGame = (
  backgroundType: Backgrounds | "",
  flatPrefix: string,
  i: number,
  birdColorPrefix: string | undefined,
  nextDashedLine: HTMLElement | null
) => {
  if (
    backgroundType === Backgrounds.Air_Timer ||
    backgroundType === Backgrounds.Air_Progress
  ) {
    const bird_flat_position = document.getElementById(`${flatPrefix}_${i}`);
    const birdAnimationContainer = document.getElementById(
      `${birdColorPrefix}`
    );
    setBirdPosition(birdAnimationContainer, nextDashedLine);

    bird_flat_position?.setAttribute("style", "display:block");
  }
};
export const setBirdPosition = (
  birdAnimationContainer: HTMLElement | null,
  nextDashedLine: HTMLElement | null
) => {
  if (birdAnimationContainer && nextDashedLine) {
    const bird = birdAnimationContainer.firstElementChild as HTMLElement;
    const leftPos =
      (nextDashedLine?.getBoundingClientRect().left /
        birdAnimationContainer.getBoundingClientRect().width) *
      100;
    bird.style.left = `${leftPos}%`;
    bird.style.transitionTimingFunction = "linear";
    bird.style.transition = "all 2s";
  }
};

export const setAirGameForBackground = (backgroundType: Backgrounds | "") => {
  switch (backgroundType) {
    case Backgrounds.Air_Progress:
      return "bird-flats-progress";

    case Backgrounds.Air_Timer:
      return "bird-flats";
  }
};

export const setProgressBarBackground = (backgroundType: Backgrounds) => {
  switch (backgroundType) {
    case Backgrounds.Timer:
      return TimerBackground;

    case Backgrounds.Air_Timer:
      return AirTimerBackground;

    case Backgrounds.Air_Progress:
      return AirProgressBackground;

    case Backgrounds.Progress:
      return ProgressBackground;
  }
};

export const setBirdColor = (backgroundType: Backgrounds | "") => {
  switch (backgroundType) {
    case Backgrounds.Air_Timer:
      return "blue-bird-container";
    case Backgrounds.Air_Progress:
      return "green-bird-container";
  }
};

function ProgressBar(props: ProgressBarProps) {
  const [isSVGLoaded, setIsSVGLoaded] = useState(false);

  const progressBarImage =
    props.companion === Companions.Chameleon ? timerProgressBar : progressBar;
  const isTimerProgressBar =
    props.companion === Companions.Chameleon ? true : false;
  const backgroundType = props.backgroundType ? props.backgroundType : "";
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const svgLoadComplete = useCallback(() => setIsSVGLoaded(true), []);
  useEffect(() => {
    setProgress(
      isTimerProgressBar,
      props.progress,
      props.color,
      backgroundType
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.progress, props.color, isTimerProgressBar]);

  useEffect(() => {
    const rewardImage =
      props.backgroundType === Backgrounds.Timer ||
      props.backgroundType === Backgrounds.Air_Timer
        ? document.getElementById("timer-reward")
        : document.getElementById("progress-reward");
    if (props.showReward) {
      rewardImage?.setAttribute("display", "block");
    } else {
      rewardImage?.setAttribute("display", "none");
    }
  }, [props.showReward, props.backgroundType]);

  useEffect(() => {
    if (isSVGLoaded) {
      setProgress(
        isTimerProgressBar,
        props.progress,
        props.color,
        backgroundType
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSVGLoaded]);

  return (
    <>
      {props.previousBestPosition > 0 && (
        <PreviousBestCompanion
          position={props.previousBestPosition}
          showMask={props.showMask}
          companion={props.companion}
        ></PreviousBestCompanion>
      )}

      {props.gameType !== GameType.SpeedOneAir && (
        <ProgressCompanion
          position={props.position}
          showMask={props.showMask}
          companion={props.companion}
        ></ProgressCompanion>
      )}

      {props.life && (
        <Life
          character={props.life.character}
          remaining={props.life.remaining}
          isGameStarted={props.isGameStarted}
          onSpiderAnimatinCompleted={props.onHangingSpiderAnimationComplete}
        ></Life>
      )}
      {props.backgroundType && (
        <SvgGraphic
          id={isTimerProgressBar ? "timer" : ""}
          className="progress-bar-background"
          src={setProgressBarBackground(props.backgroundType)}
        />
      )}

      <SvgGraphic
        className="progress-bar-dashed"
        src={progressBarImage}
        onLoad={svgLoadComplete}
      />

      {props.gameType === GameType.SpeedOneAir && (
        <SvgGraphic
          src={
            props.backgroundType === Backgrounds.Air_Progress
              ? BirdFlatProgress
              : BirdFlat
          }
          className="bird-flats"
          onLoad={svgLoadComplete}
        />
      )}

      {props.isGameStarted && props.gameType === GameType.SpeedOneAir && (
        <Bird birdColor={setBirdColor(backgroundType)} />
      )}
    </>
  );
}

export default ProgressBar;
