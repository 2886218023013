import { exercisesPlayerGameOver, exercisesPlayerSaveAnswer } from "../../api";
import { GameContainerProps } from "./game-container";
import { currentGameType, exerciseId } from "./game-controller";
import { getGameTitle } from "./game-utils";

export type GameOverProps = {
  exerciseStatus: number;
  props: GameContainerProps;
  callback?: Function;
};

export type SaveAnswerProps = {
  questionIndex: number;
  answer: string;
  assessmentId: string;
  assessmentAttemptNumber: number;
  answerAttempt: number;
  publicationId: string;
  props: GameContainerProps;
  callback?: Function;
};

export const onGameOver = async (gameOverProps: GameOverProps) => {
  await exercisesPlayerGameOver({
    menuId: gameOverProps.props.playerInfo?.menuId,
    subMenuId: gameOverProps.props.playerInfo?.subMenuId,
    levelTitle: getGameTitle(currentGameType),
    publicationId: gameOverProps.props.parameterInformation.publicationId,
    exerciseId: exerciseId,
    exerciseAttemptNumber: gameOverProps.props.exerciseAttemptNumber,
    status: gameOverProps.exerciseStatus,
    learningObjectiveId:
      gameOverProps.props.parameterInformation.learningObjectiveId,
    blockId: gameOverProps.props.parameterInformation.blockId,
    courseName: gameOverProps.props.parameterInformation.courseName,
  })
    .then(() => {
      if (gameOverProps.callback) gameOverProps.callback();
    })
    .catch((err) => {});
};

export const saveAnswer = async (saveAnswerProps: SaveAnswerProps) => {
  const _answer =
    saveAnswerProps.answer === "" ? "null" : saveAnswerProps.answer;
  await exercisesPlayerSaveAnswer({
    menuId: saveAnswerProps.props.playerInfo?.menuId,
    subMenuId: saveAnswerProps.props.playerInfo?.subMenuId,
    levelTitle: getGameTitle(currentGameType),
    publicationId: saveAnswerProps.publicationId,
    exerciseId,
    assessmentId: saveAnswerProps.assessmentId,
    exerciseAttemptNumber: 0,
    assessmentOrderNumber: saveAnswerProps.questionIndex + 1,
    assessmentAttemptNumber: saveAnswerProps.answerAttempt,
    givenAnswer: _answer,
    status: 1,
  })
    .then(() => {
      if (saveAnswerProps.callback) saveAnswerProps.callback();
    })
    .catch((err) => {
      if (saveAnswerProps.callback && err.response.status === 500)
        saveAnswerProps.callback();
    });
};
