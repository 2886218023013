import React from "react";
import snail from "../../../assets/progress-bar/previous-best-companion/previous-best-snail.svg";
import leaf from "../../../assets/progress-bar/previous-best-companion/previous-best-leaf.svg";
import elephant from "../../../assets/progress-bar/previous-best-companion/previous-best-elephant.svg";
import ladybug from "../../../assets/progress-bar/previous-best-companion/previous-best-ladybug.svg";
import grasshopper from "../../../assets/progress-bar/previous-best-companion/previous-best-grasshopper.svg";
import bug from "../../../assets/progress-bar/previous-best-companion/previous-best-bug.svg";
import firefly from "../../../assets/progress-bar/previous-best-companion/previous-best-firefly.svg";
import bird from "../../../assets/progress-bar/previous-best-companion/previous-best-bird.svg";

import Companion, { Companions, CompanionType } from "./companion";

const previousCompanionImages = [
  { name: Companions.Snail, src: snail },
  { name: Companions.Leaf, src: leaf },
  { name: Companions.Ladybug, src: ladybug },
  { name: Companions.Elephant, src: elephant },
  { name: Companions.Grasshopper, src: grasshopper },
  { name: Companions.Bug, src: bug },
  { name: Companions.Firefly, src: firefly },
  { name: Companions.Bird, src: bird },
];

interface PreviousBestCompanionProps {
  companion: Companions;
  position: number;
  showMask: boolean;
}

export const getCompanion = (name: Companions) => {
  return previousCompanionImages.find((obj) => obj.name === name);
};

export const getImageSource = (companion?: {
  name: Companions;
  src: string;
}) => {
  return companion ? companion?.src : "undefined";
};

function PreviousBestCompanion(props: PreviousBestCompanionProps) {
  const companion = getCompanion(props.companion);
  const companionImageSource = getImageSource(companion);

  return (
    <Companion
      position={props.position}
      showMask={props.showMask}
      source={companionImageSource}
      companionType={CompanionType.previous_best}
    ></Companion>
  );
}

export default PreviousBestCompanion;
