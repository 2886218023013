export const blockTenRepeatIcons = [
  [
    { id: "repeat_btn_b10_1_1" },
    { id: "repeat_btn_b10_1_2" },
    { id: "repeat_btn_b10_1_3" },
  ],
  [
    { id: "repeat_btn_b10_2_1" },
    { id: "repeat_btn_b10_2_2" },
    { id: "repeat_btn_b10_2_3" },
  ],
  [
    { id: "repeat_btn_b10_3_1" },
    { id: "repeat_btn_b10_3_2" },
    { id: "repeat_btn_b10_3_3" },
  ],
  [
    { id: "repeat_btn_b10_4_1" },
    { id: "repeat_btn_b10_4_2" },
    { id: "repeat_btn_b10_4_3" },
  ],
];
export const blockNineRepeatIcons = [
  [
    { id: "repeat_btn_b9_1_1" },
    { id: "repeat_btn_b9_1_2" },
    { id: "repeat_btn_b9_1_3" },
  ],
  [
    { id: "repeat_btn_b9_2_1" },
    { id: "repeat_btn_b9_2_2" },
    { id: "repeat_btn_b9_2_3" },
  ],
  [
    { id: "repeat_btn_b9_3_1" },
    { id: "repeat_btn_b9_3_2" },
    { id: "repeat_btn_b9_3_3" },
  ],
  [
    { id: "repeat_btn_b9_4_1" },
    { id: "repeat_btn_b9_4_2" },
    { id: "repeat_btn_b9_4_3" },
  ],
  [
    { id: "repeat_btn_b9_5_1" },
    { id: "repeat_btn_b9_5_2" },
    { id: "repeat_btn_b9_5_3" },
  ],
  [
    { id: "repeat_btn_b9_6_1" },
    { id: "repeat_btn_b9_6_2" },
    { id: "repeat_btn_b9_6_3" },
  ],
];
