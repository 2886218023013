import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import Background from "./block1-background.svg";
import Complete from "./block1-complete.svg";
import FocusGrayedOut from "./block1-focus-grayed-out.svg";
import Focus from "./block1-focus.svg";
import Foreground from "./block1-foreground.svg";
import HelpBlock from "./block1-help-block.svg";
import SvgGraphic from "../../utils/SvgGraphic";
import {
  setExplainationVideoPlayedStatus,
  setLoadingDataComplete,
  setSubMenuBlocks,
  addClickEventOnStone,
  setSubMenuManifestDataByMenuId,
  toggleBlockHelp,
} from "../../utils/SubmenuController";
import { useAppSelector } from "../../redux/hooks";
import { levelURLNames } from "../../component/constant";
import { elementReady } from "../../utils/CheckElement";

export type BlockSubMenuProps = {
  subMenuData: any;
  onLoad: Function;
  manifestBlockId: string;
};
type urlParams = {
  state: {
    path?: string;
    levelTitle?: string;
    manifestSubMenuId?: string;
    manifestMenuId?: string;
  };
};
let navigate: NavigateFunction | ((arg0: string, params?: urlParams) => void);

const repeatIcons = [
  [
    { id: "repeat_btn_b1_1_1" },
    { id: "repeat_btn_b1_1_2" },
    { id: "repeat_btn_b1_1_3" },
  ],
  [
    { id: "repeat_btn_b1_2_1" },
    { id: "repeat_btn_b1_2_2" },
    { id: "repeat_btn_b1_2_3" },
  ],
];

const grayedBackgrounds = [
  [
    { id: "grayedout_b1_1_1" },
    { id: "grayedout_b1_1_2" },
    { id: "grayedout_b1_1_3" },
  ],
  [
    { id: "grayedout_b1_2_1" },
    { id: "grayedout_b1_2_2" },
    { id: "grayedout_b1_2_3" },
  ],
];
const playButtons = [
  [
    { id: "play_btn_1_1_1" },
    { id: "play_btn_1_1_2" },
    { id: "play_btn_1_1_3" },
  ],
  [
    { id: "play_btn_1_2_1" },
    { id: "play_btn_1_2_2" },
    { id: "play_btn_1_2_3" },
  ],
];

export const useBlock1BlockMenu = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [subMenuManifestData, setSubMenuManifestData] = useState([]);
  const [loadCounter, setLoadCounter] = useState(0);

  return {
    subMenuManifestData,
    setSubMenuManifestData,
    loadCounter,
    setLoadCounter,
    isDataLoading,
    setIsDataLoading,
  };
};

export const setLevelButtons = (
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  repeatIcons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventForBlockOneLevel(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });

  playButtons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        element.style.cursor = "pointer";
        addClickEventForBlockOneLevel(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
};

export const addClickEventForBlockOneLevel = (
  clickableElement: HTMLElement | null,
  index: number,
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  if (clickableElement) {
    clickableElement.addEventListener("click", (evt) => {
      const manifestSubMenuId =
        clickableElement.getAttribute("manifestSubMenuId");

      const blockOneSubmenuId = clickableElement.getAttribute("subMenuId");
      const blockOneLevelId = clickableElement.getAttribute("levelId");
      const blockOneButtonIndex = clickableElement.getAttribute("data-index");

      const blockOneIsExplainationVideoPlayedList =
        setExplainationVideoPlayedStatus(
          blockOneSubmenuId,
          blockOneButtonIndex,
          explainationVideoPlayedStatus
        );

      navigate(
        `/b1/menu/${menuId}/submenu/${blockOneSubmenuId}/${levelURLNames[index]}/${blockOneLevelId}`,
        {
          state: {
            manifestSubMenuId: `${manifestSubMenuId}`,
            manifestMenuId: manifestBlockId,
            isExplainationVideo:
              blockOneIsExplainationVideoPlayedList.isExplainationVideoPlayed
                ? false
                : true,
            levelTitle: blockOneIsExplainationVideoPlayedList.levelTitle,
          },
        }
      );
    });

    clickableElement.style.cursor = "pointer";
  }
};

export const useSvgImageLoaded = (
  props: BlockSubMenuProps,
  menuId: any,
  loadCounter: number
) => {
  const [isVisible, setIsVisible] = useState(false);
  const { subMenuManifestData, setSubMenuManifestData, setLoadCounter } =
    useBlock1BlockMenu();

  const onSvgGraphicLoaded = () => {
    loadCounter = loadCounter + 1;
    setLoadCounter(loadCounter);

    if (loadCounter === 6) {
      setIsVisible(true);
      addClickEventOnStone("doel_knop_drempel_3_", () => {
        toggleBlockHelp("block_help");
      });
      setLevelButtons(menuId || "", props.subMenuData, props.manifestBlockId);

      setSubMenuBlocks(
        repeatIcons,
        grayedBackgrounds,
        playButtons,
        props.subMenuData,
        subMenuManifestData
      );

      if (props.onLoad) props.onLoad();
    }
  };
  return {
    isVisible,
    setIsVisible,
    onSvgGraphicLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  };
};

const BlockOneSubMenu = (props: BlockSubMenuProps) => {
  const { loadCounter, setLoadCounter, isDataLoading, setIsDataLoading } =
    useBlock1BlockMenu();
  const { menuId } = useParams();

  const {
    isVisible,
    onSvgGraphicLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  } = useSvgImageLoaded(props, menuId, loadCounter);

  navigate = useNavigate();

  const manifestData = useAppSelector(
    (state: any) => state.manifest.manifestData
  );

  useEffect(() => {
    setLoadCounter(0);

    setIsDataLoading(true);

    setSubMenuManifestDataByMenuId(
      manifestData?.children,
      (data: any) => {
        setSubMenuManifestData(data);
      },
      menuId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingDataComplete(
      props,
      () => {
        setIsDataLoading(false);
      },
      menuId,
      subMenuManifestData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId, subMenuManifestData, props.subMenuData]);

  return (
    <>
      {!isDataLoading && (
        <div style={{ visibility: isVisible ? "visible" : "hidden" }}>
          <SvgGraphic src={Background} onLoad={onSvgGraphicLoaded} />
          <SvgGraphic src={FocusGrayedOut} onLoad={onSvgGraphicLoaded} />

          <SvgGraphic src={Complete} onLoad={onSvgGraphicLoaded} />
          <SvgGraphic src={Focus} onLoad={onSvgGraphicLoaded} />
          <SvgGraphic
            src={HelpBlock}
            style={{ display: "none" }}
            id={"block_help"}
            onLoad={onSvgGraphicLoaded}
          />
          <SvgGraphic src={Foreground} onLoad={onSvgGraphicLoaded} />
        </div>
      )}
    </>
  );
};
export default BlockOneSubMenu;
