import Spritesheet from "react-responsive-spritesheet";
import blueBird from "../../assets/bird/blueBird.png";
import greenBird from "../../assets/bird/green-bird.png";
import { useEffect, useState } from "react";

type birdProps = {
  birdColor: string | undefined;
};
const Bird = (props: birdProps) => {
  const [spriteSheetInstance, setSpriteSheetInstance] = useState<Spritesheet>();
  const [scaleValue, setScaleValue] = useState<number | undefined>();

  useEffect(() => {
    if (spriteSheetInstance) {
      spriteSheetInstance.play();
      const scale = spriteSheetInstance?.getInfo("scale");
      setScaleValue(scale);
    }
  }, [spriteSheetInstance, scaleValue]);

  return (
    <div id={`${props.birdColor}`} className="bird-container">
      <Spritesheet
        className="bird-sprite-sheet"
        style={
          scaleValue ? { visibility: "visible" } : { visibility: "hidden" }
        }
        direction={"forward"}
        image={props.birdColor === "blue-bird-container" ? blueBird : greenBird}
        widthFrame={336}
        heightFrame={241}
        steps={25}
        fps={60}
        autoplay={true}
        loop={true}
        onInit={(spritesheet) => {
          setSpriteSheetInstance(spritesheet);
        }}
      />
    </div>
  );
};
export default Bird;
