import React, { useEffect } from "react";
import background from "../assets/MainMenu/year3_mainmenu_background_landscape.jpg";
import TopNavbar from "../component/navbar/navbar";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useAppSelector } from "../redux/hooks";
import MainMenu from "../assets/MainMenu";
import Page from "./page";
import SettingOverlay from "../component/setting-overlay/setting-overlay";
import { isMobileDevice } from "../utils/mobileDevice";

type LevelDashboardType = {
  handleFullScreen: Function;
};

function LevelDash(props: LevelDashboardType) {
  let handle = useFullScreenHandle();

  const popout = useAppSelector((state: any) => state.navbar.openDropDown);

  useEffect(() => {
    if (
      !isMobileDevice &&
      document.body.requestFullscreen &&
      localStorage.getItem("mainMenuOpenAutoFullScreen") === null
    ) {
      document.body.requestFullscreen();
      localStorage.setItem("mainMenuOpenAutoFullScreen", "true");
    }
  }, [props]);

  return (
    <FullScreen handle={handle}>
      <TopNavbar handleFullScreen={props.handleFullScreen} />
      {popout && <SettingOverlay />}
      <Page
        showForeground={true}
        childElement={<MainMenu />}
        backgroundURL={background}
        showCharacter={true}
      />
    </FullScreen>
  );
}

export default LevelDash;
