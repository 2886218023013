import React, { useEffect } from "react";
import fish from "../../../assets/progress-bar/life/fish_life.svg";
import ant from "../../../assets/progress-bar/life/ant_life.svg";
import frog from "../../../assets/progress-bar/life/frog_life.svg";
import SvgGraphic from "../../../utils/SvgGraphic";
import HangingSpider from "../../hanging-spider/hanging-spider";

export enum LifeCharacter {
  fish = "fish",
  ant = "ant",
  frog = "frog",
  spider = "spider",
}

export type LifeProps = {
  character: LifeCharacter;
  remaining: number;
  onSpiderAnimatinCompleted?: (isCompleted: boolean) => void;
  isGameStarted?: boolean;
};

export const updateLife = (props: LifeProps) => {
  const life: Array<string> = ["life1", "life2"];

  // Hide all life
  life.forEach((item) => {
    document.getElementById(item)?.setAttribute("display", "none");
  });

  // Show life
  for (let i = 0; i < props.remaining; i += 1) {
    document.getElementById(life[i])?.setAttribute("display", "block");
  }
};

export const getImageSource = (character: LifeCharacter) => {
  switch (character) {
    case LifeCharacter.fish:
      return fish;
    case LifeCharacter.ant:
      return ant;
    case LifeCharacter.frog:
      return frog;
    case LifeCharacter.spider:
      return "spider";
  }
};

function Life(props: LifeProps) {
  const imageSource = getImageSource(props.character);

  useEffect(() => {
    updateLife(props);
  }, [props]);

  return imageSource === "spider" ? (
    <HangingSpider
      remainingLife={props.remaining}
      isGameStarted={props.isGameStarted}
      onAnimationCompleted={props.onSpiderAnimatinCompleted}
    ></HangingSpider>
  ) : (
    <SvgGraphic
      src={imageSource}
      onLoad={() => {
        updateLife(props);
      }}
    ></SvgGraphic>
  );
}

export default Life;
