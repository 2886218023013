import { useEffect, useState } from "react";
import { getMenus } from "../../api";
import BlockClick from "./BlockClick";
import BlockCompleted from "./BlockCompleted";
import BlockFocus from "./BlockFocus";
import BlockGrayedOut from "./BlockGrayedOut";
import BlockWall from "./Wall";
import { useAppSelector } from "../../redux/hooks";
import Loader from "../../component/loader/loader";
import { NavigateFunction, useNavigate } from "react-router-dom";
import vineImage from "../../assets/MainMenu/vine.svg";
import SvgGraphic from "../../utils/SvgGraphic";

type urlParams = {
  state: { menuId: string };
};

let navigate: NavigateFunction | ((arg0: string, params: urlParams) => void);

export const useMainMenu = (manifestDataInfo: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const [menuData, setMenuData] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [manifestData, setManifestData] = useState<any>([]);
  let blockLoadCounter = 0;
  const onBlockLoad = async () => {
    blockLoadCounter += 1;

    if (blockLoadCounter === 5) {
      setIsLoading(true);
      if (manifestDataInfo) {
        setManifestData(manifestDataInfo.children);
      }

      await getMenus().then((menuDataResponse) => {
        setMenuData(menuDataResponse.data);
      });

      setIsLoading(false);
      setIsVisible(true);
    }
  };

  const showBlocks = () => {
    const menuList = menuData
      .map((menu: any) => {
        const { completeStatus, menuId, subMenuList } = menu;
        return { completeStatus, menuId, subMenuList };
      })
      .sort((a: any, b: any) => a.menuId - b.menuId);

    menuList.forEach((menu: any) => {
      const { menuId, completeStatus } = menu;

      if (completeStatus) {
        const grassMoss: HTMLElement | null = document.getElementById(
          `block${menuId}-completed`
        );
        if (grassMoss) grassMoss.style.display = "block";
      }
      showSubMenuByMenuData(menu);
    });

    const lastIncompleteMenu = menuList.find(
      (menu: any) => menu.completeStatus === false
    );
    if (lastIncompleteMenu) showBlockFocusByMenuId(lastIncompleteMenu.menuId);
  };

  const getManifestDataByMenuId = (id: number) => {
    if (manifestData.length !== 0) {
      return manifestData.find((data: any) => data.number === id);
    }
  };

  const showSubMenuByMenuData = (menu: any) => {
    const { menuId, subMenuList } = menu;

    subMenuList.forEach((submenu: any) => {
      const submenuId = submenu.subMenuId;
      const subMenuBlockGrayedOut = document.getElementById(
        `submenuId_${menuId}_${submenuId}`
      );

      const subMenuBlockClick: HTMLElement | null = document.getElementById(
        `block_${menuId}_${submenuId}_click`
      );

      if (submenu.completeStatus) {
        if (subMenuBlockGrayedOut) {
          subMenuBlockGrayedOut.setAttribute("display", "none");
        }

        if (subMenuBlockClick) {
          subMenuBlockClick.setAttribute("display", "block");
          subMenuBlockClick.style.pointerEvents = "auto";
          subMenuBlockClick.style.cursor = "pointer";
          const manifestBlockId = getManifestDataByMenuId(menuId).id;

          subMenuBlockClick.onclick = () => {
            navigate(`/submenu/${menuId}`, {
              state: {
                menuId,
                manifestBlockId: manifestBlockId,
              },
            });
          };
        }
      }
    });
    return "show blocks";
  };

  const showBlockFocusByMenuId = (id: number) => {
    manifestData.forEach((data: any) => {
      const menuId = data.number;

      const blockFocus = document.getElementById(`block${menuId}-focus`);

      if (blockFocus) {
        if (menuId === id) {
          blockFocus.style.display = "block";
          blockFocus.style.cursor = "pointer";
          blockFocus.style.pointerEvents = "auto";
          blockFocus.onclick = () => {
            navigate(`/submenu/${menuId}`, {
              state: {
                menuId,
                manifestBlockId: getManifestDataByMenuId(menuId).id,
              },
            });
          };
        } else {
          blockFocus.style.display = "none";
          blockFocus.style.removeProperty("cursor");
          blockFocus.style.removeProperty("pointer-events");
          blockFocus.onclick = null;
        }
      }
    });
  };

  return {
    showBlocks,
    onBlockLoad,
    setIsVisible,
    isVisible,
    menuData,
    setMenuData,
    setIsLoading,
    isLoading,
    manifestData,
    setManifestData,
    blockLoadCounter,
    getManifestDataByMenuId,
    showBlockFocusByMenuId,
  };
};

const MainMenu = () => {
  navigate = useNavigate();
  const manifestDataInfo = useAppSelector(
    (state: any) => state.manifest.manifestData
  );
  const { onBlockLoad, showBlocks, isVisible, menuData, isLoading } =
    useMainMenu(manifestDataInfo);

  useEffect(() => {
    if (menuData) {
      showBlocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData, manifestDataInfo]);

  return (
    <>
      <div
        style={{ visibility: isVisible ? "visible" : "hidden", scale: 0.9 }}
        className="main-menu-container"
      >
        <BlockWall onCompleteLoad={onBlockLoad} />
        <BlockGrayedOut onCompleteLoad={onBlockLoad} />
        <BlockFocus onCompleteLoad={onBlockLoad} />
        <BlockClick onCompleteLoad={onBlockLoad} />
        <BlockCompleted onCompleteLoad={onBlockLoad} />
        <SvgGraphic src={vineImage} className="vine-image" />
      </div>
      <Loader
        style={{
          visibility: isLoading ? "visible" : "hidden",
          PointerEvent: "none",
        }}
      />
    </>
  );
};
export default MainMenu;
