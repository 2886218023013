import { useCallback, useEffect, useState } from "react";
import "./style.modules.scss";

import { AxiosError } from "axios";
import { Route, Routes } from "react-router-dom";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import MainPage from "./pages/landing-page";
import LevelDash from "./pages/main-menu";
import SubMenu from "./pages/sub-menu/sub-menu";

import { authInfo, getUserInfo } from "./api";
import { GameType } from "./component/game-container/game-container";
import PowerWater from "./pages/game-pages/power-water/power-water";
import Power from "./pages/game-pages/power/power";
import SpeedOneAir from "./pages/game-pages/speed-one-air/speed-one-air";
import SpeedOne from "./pages/game-pages/speed-one/speed-one";
import SpeedTwoLand from "./pages/game-pages/speed-two-land/speed-two-land";
import SpeedTwo from "./pages/game-pages/speed-two/speed-two";
import { authInfoSlice } from "./redux/auth/auth.slice";
import { fullScreenSlice } from "./redux/fullScreenHandler/fullScreenHandler.slice";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { getEnvironmentUrl } from "./services/base";

export const handleFullScreen = (callback: Function) => {
  if (callback) callback();
  if (document.fullscreenElement === null) {
    document.body.requestFullscreen();
    localStorage.setItem("fullscreenviewer", "true");
  } else {
    document.exitFullscreen();
    localStorage.setItem("fullscreenviewer", "false");
  }
};

function App() {
  const dispatch = useAppDispatch();
  const handle = useFullScreenHandle();
  const isFullScreen = useAppSelector(
    (state: any) => state.fullscreen.isFullScreen
  );
  const [isLoading, setIsLoading] = useState(true);

  async function authResponse() {
    const baseUrl = getEnvironmentUrl();
    try {
      const auth = await authInfo();
      if (auth) {
        const responseData = await getUserInfo();
        dispatch(authInfoSlice.actions.authInfo(responseData?.data?.user));
      }

      setIsLoading(false);
    } catch (e: any) {
      const err = e as AxiosError;
      
      if (err?.response?.status === 401) {
        const returnUrl = window.location.href;
        window.location.href = `${baseUrl}/auth/login?idp_identifier=basispoort&returnUrl=${returnUrl}`;
      }
    }
  }

  useEffect(() => {
    authResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        authResponse();
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStorage.getItem("fullscreenviewer") === null) {
      localStorage.setItem("fullscreenviewer", "false");
    }
    localStorage.setItem("voiceSettingModal", "true");
  }, []);

  const fullScreen = useCallback(
    () =>
      handleFullScreen(() => {
        dispatch(fullScreenSlice.actions.updateFullScreen(!isFullScreen));
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handle.active]
  );
  return (
    <>
      {!isLoading && (
        <div className="App">
          <FullScreen handle={handle}>
            <Routes>
              <Route
                path="/"
                element={<MainPage handleFullScreen={fullScreen} />}
              />

              <Route
                path="/menu"
                element={<LevelDash handleFullScreen={fullScreen} />}
              />

              <Route
                path="/submenu/:menuId"
                element={<SubMenu handleFullScreen={fullScreen} />}
              />
              <Route
                path={`/:thresholdId/menu/:menuUID/submenu/:subMenuId/${GameType.Power.toLocaleLowerCase()}/:levelId`}
                element={<Power handleFullScreen={fullScreen} />}
              />
              <Route
                path={`/:thresholdId/menu/:menuUID/submenu/:subMenuId/${GameType.SpeedOne.toLocaleLowerCase()}/:levelId`}
                element={<SpeedOne handleFullScreen={fullScreen} />}
              />
              <Route
                path={`/:thresholdId/menu/:menuUID/submenu/:subMenuId/${GameType.SpeedTwo.toLocaleLowerCase()}/:levelId`}
                element={<SpeedTwo handleFullScreen={fullScreen} />}
              />
              <Route
                path={`/:thresholdId/menu/:menuUID/submenu/:subMenuId/${GameType.PowerWater.toLocaleLowerCase()}/:levelId`}
                element={<PowerWater handleFullScreen={fullScreen} />}
              />
              <Route
                path={`/:thresholdId/menu/:menuUID/submenu/:subMenuId/${GameType.SpeedOneAir.toLocaleLowerCase()}/:levelId`}
                element={<SpeedOneAir handleFullScreen={fullScreen} />}
              />
              <Route
                path={`/:thresholdId/menu/:menuUID/submenu/:subMenuId/${GameType.SpeedTwoLand.toLocaleLowerCase()}/:levelId`}
                element={<SpeedTwoLand handleFullScreen={fullScreen} />}
              />
            </Routes>
          </FullScreen>
        </div>
      )}
    </>
  );
}

export default App;
