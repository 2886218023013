import lillyPadImage from "../../assets/frog/lilly-pad.svg";
import frogShadow from "../../assets/frog/frog-shadow.svg";

type LillyPadProps = {
  isFrogJumping: boolean;
  isFrogJumpingOutOfScreen: boolean;
};

function LillyPad(props: LillyPadProps) {
  return (
    <>
      <img
        draggable="false"
        src={lillyPadImage}
        alt="lilly-pad"
        className="lilly-pad-position"
        style={{ pointerEvents: "none" }}
      />
      <img
        draggable="false"
        src={frogShadow}
        alt="frog-shadow"
        className={`frog-shadow-position ${
          props.isFrogJumping ? "frog-shadow-animation" : ""
        }${
          props.isFrogJumpingOutOfScreen
            ? "frog-jump-from-screen-shadow-animation"
            : ""
        }`}
        style={{ pointerEvents: "none" }}
      />
    </>
  );
}

export default LillyPad;
