import React, { useEffect, useState } from "react";
import Background from "./block6-background.svg";
import Complete from "./block6-complete.svg";
import FocusGrayedOut from "./block6-focus-grayed-out.svg";
import Focus from "./block6-focus.svg";
import Foreground from "./block6-foreground.svg";
import HelpBlock from "./block6-help-block.svg";

import SvgGraphic from "../../utils/SvgGraphic";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
  setExplainationVideoPlayedStatus,
  setLoadingDataComplete,
  setSubMenuBlocks,
  addClickEventOnStone,
  setSubMenuManifestDataByMenuId,
  toggleBlockHelp,
} from "../../utils/SubmenuController";
import { useAppSelector } from "../../redux/hooks";
import { BlockSubMenuProps } from "../Block1BlockMenu";
import { levelURLNames } from "../../component/constant";
import { elementReady } from "../../utils/CheckElement";

const blockSixRepeatIcons = [
  [
    { id: "repeat_btn_b6_1_1" },
    { id: "repeat_btn_b6_1_2" },
    { id: "repeat_btn_b6_1_3" },
  ],
];

const blockSixGrayedBackgrounds = [
  [
    { id: "grayedout_b6_1_1" },
    { id: "grayedout_b6_1_2" },
    { id: "grayedout_b6_1_3" },
  ],
];
const blockSixPlayButtons = [
  [
    { id: "play_btn_6_1_1" },
    { id: "play_btn_6_1_2" },
    { id: "play_btn_6_1_3" },
  ],
];

export const useBlock6BlockMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [subMenuManifestData, setSubMenuManifestData] = useState([]);
  const [blockSixLoadCounter, setBlockSixLoadCounter] = useState(0);
  const [blockSixIsLoading, setBlockSixIsLoading] = useState(true);

  return {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    blockSixLoadCounter,
    setBlockSixLoadCounter,
    blockSixIsLoading,
    setBlockSixIsLoading,
  };
};

type urlParams = {
  state: {
    path?: string;
    levelTitle?: string;
    manifestSubMenuId?: string;
    manifestMenuId?: string;
  };
};
let navigate: NavigateFunction | ((arg0: string, params?: urlParams) => void);

export const setBlockSixLevelRepeatIcons = (
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  blockSixRepeatIcons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockSixLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
  blockSixPlayButtons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockSixLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
  return "gray six block loaded";
};

export const addClickEventOnBlockSixLevels = (
  clickableElement: HTMLElement | null,
  index: number,
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  if (clickableElement) {
    clickableElement.addEventListener("click", () => {
      let manifestSubMenuId =
        clickableElement.getAttribute("manifestSubMenuId");
      const blockSixSubmenuId = clickableElement.getAttribute("subMenuId");
      const blockSixLevelId = clickableElement.getAttribute("levelId");

      const blockSixButtonIndex = clickableElement.getAttribute("data-index");

      const isExplainationVideoPlayedList = setExplainationVideoPlayedStatus(
        blockSixSubmenuId,
        blockSixButtonIndex,
        explainationVideoPlayedStatus
      );

      navigate(
        `/b6/menu/${menuId}/submenu/${blockSixSubmenuId}/${levelURLNames[index]}/${blockSixLevelId}`,
        {
          state: {
            manifestSubMenuId: `${manifestSubMenuId}`,
            manifestMenuId: manifestBlockId,
            isExplainationVideo:
              isExplainationVideoPlayedList.isExplainationVideoPlayed
                ? false
                : true,
            levelTitle: isExplainationVideoPlayedList.levelTitle,
          },
        }
      );
    });

    clickableElement.style.cursor = "pointer";
  }
};

export const useSvgImageBlockSixLoaded = (
  props: BlockSubMenuProps,
  menuId: any,
  loadCounter: number
) => {
  const {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    setBlockSixLoadCounter,
  } = useBlock6BlockMenu();

  const onSvgGraphicBlockSixLoaded = () => {
    loadCounter = loadCounter + 1;
    setBlockSixLoadCounter(loadCounter);
    if (loadCounter === 6) {
      setIsVisible(true);
      setBlockSixLevelRepeatIcons(
        menuId || "",
        props.subMenuData,
        props.manifestBlockId
      );
      addClickEventOnStone("doel_knop_drempel_4_", () => {
        toggleBlockHelp("block_help_6");
      });

      setSubMenuBlocks(
        blockSixRepeatIcons,
        blockSixGrayedBackgrounds,
        blockSixPlayButtons,
        props.subMenuData,
        subMenuManifestData
      );

      if (props.onLoad) props.onLoad();
    }
  };
  return {
    isVisible,
    setIsVisible,
    onSvgGraphicBlockSixLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  };
};

const BlockSixSubMenu = (props: BlockSubMenuProps) => {
  navigate = useNavigate();
  const { menuId } = useParams();
  const {
    blockSixLoadCounter,
    setBlockSixLoadCounter,
    blockSixIsLoading,
    setBlockSixIsLoading,
  } = useBlock6BlockMenu();

  const {
    isVisible,
    onSvgGraphicBlockSixLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  } = useSvgImageBlockSixLoaded(props, menuId, blockSixLoadCounter);
  const manifestData = useAppSelector(
    (state: any) => state.manifest.manifestData
  );

  useEffect(() => {
    let blockSixManifestChildren = manifestData?.children;
    setBlockSixLoadCounter(0);
    setBlockSixIsLoading(true);
    setSubMenuManifestDataByMenuId(
      blockSixManifestChildren,
      (data: any) => {
        setSubMenuManifestData(data);
      },
      menuId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingDataComplete(
      props,
      () => {
        setBlockSixIsLoading(false);
      },
      menuId,
      subMenuManifestData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId, subMenuManifestData, props.subMenuData]);

  return (
    <>
      {!blockSixIsLoading && (
        <div style={{ visibility: isVisible ? "visible" : "hidden" }}>
          <SvgGraphic src={Background} onLoad={onSvgGraphicBlockSixLoaded} />
          <SvgGraphic
            src={FocusGrayedOut}
            onLoad={onSvgGraphicBlockSixLoaded}
          />
          <SvgGraphic src={Focus} onLoad={onSvgGraphicBlockSixLoaded} />
          <SvgGraphic src={Complete} onLoad={onSvgGraphicBlockSixLoaded} />
          <SvgGraphic
            src={HelpBlock}
            style={{ display: "none" }}
            id={"block_help_6"}
            onLoad={onSvgGraphicBlockSixLoaded}
          />
          <SvgGraphic src={Foreground} onLoad={onSvgGraphicBlockSixLoaded} />
        </div>
      )}
    </>
  );
};
export default BlockSixSubMenu;
