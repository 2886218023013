import React, { useEffect, useState } from "react";
import Background from "./block2-background.svg";
import FocusGrayedOut from "./block2-focus-grayed-out.svg";
import Foreground from "./block2-foreground.svg";
import HelpBlock from "./block2-help-block.svg";
import Complete from "./block2-complete.svg";
import Focus from "./block2-focus.svg";

import SvgGraphic from "../../utils/SvgGraphic";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

import { useAppSelector } from "../../redux/hooks";
import {
  setExplainationVideoPlayedStatus,
  setLoadingDataComplete,
  setSubMenuBlocks,
  addClickEventOnStone,
  setSubMenuManifestDataByMenuId,
  toggleBlockHelp,
} from "../../utils/SubmenuController";
import { BlockSubMenuProps } from "../Block1BlockMenu";
import { levelURLNames } from "../../component/constant";
import { elementReady } from "../../utils/CheckElement";
type urlParams = {
  state: {
    path?: string;
    levelTitle?: string;
    manifestSubMenuId?: string;
    manifestMenuId?: string;
  };
};
let navigate: NavigateFunction | ((arg0: string, params?: urlParams) => void);

const blockTwoRepeatIcons = [
  [
    { id: "repeat_btn_b2_1_1" },
    { id: "repeat_btn_b2_1_2" },
    { id: "repeat_btn_b2_1_3" },
  ],
  [
    { id: "repeat_btn_b2_2_1" },
    { id: "repeat_btn_b2_2_2" },
    { id: "repeat_btn_b2_2_3" },
  ],
  [
    { id: "repeat_btn_b2_3_1" },
    { id: "repeat_btn_b2_3_2" },
    { id: "repeat_btn_b2_3_3" },
  ],
];

const blockTwoGrayedBackgrounds = [
  [
    { id: "grayedout_b2_1_1" },
    { id: "grayedout_b2_1_2" },
    { id: "grayedout_b2_1_3" },
  ],
  [
    { id: "grayedout_b2_2_1" },
    { id: "grayedout_b2_2_2" },
    { id: "grayedout_b2_2_3" },
  ],
  [
    { id: "grayedout_b2_3_1" },
    { id: "grayedout_b2_3_2" },
    { id: "grayedout_b2_3_3" },
  ],
];

const blockTwoPlayButtons = [
  [
    { id: "play_btn_2_1_1" },
    { id: "play_btn_2_1_2" },
    { id: "play_btn_2_1_3" },
  ],
  [
    { id: "play_btn_2_2_1" },
    { id: "play_btn_2_2_2" },
    { id: "play_btn_2_2_3" },
  ],
  [
    { id: "play_btn_2_3_1" },
    { id: "play_btn_2_3_2" },
    { id: "play_btn_2_3_3" },
  ],
];

export const useBlock2BlockMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [subMenuManifestData, setSubMenuManifestData] = useState([]);
  const [loadCounter, setLoadCounter] = useState(0);
  const [isDataLoading, setIsDataLoading] = useState(true);

  return {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    loadCounter,
    setLoadCounter,
    isDataLoading,
    setIsDataLoading,
  };
};

export const setBlockTwoLevelRepeatIcons = (
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  blockTwoRepeatIcons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockTwoLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
  blockTwoPlayButtons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockTwoLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
};

export const addClickEventOnBlockTwoLevels = (
  clickableElement: HTMLElement | null,
  index: number,
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  if (clickableElement) {
    clickableElement.addEventListener("click", () => {
      let manifestSubMenuId =
        clickableElement.getAttribute("manifestSubMenuId");
      const blockTwoSubmenuId = clickableElement.getAttribute("subMenuId");
      const blockTwoLevelId = clickableElement.getAttribute("levelId");
      const blockTwoButtonIndex = clickableElement.getAttribute("data-index");
      const isExplainationVideoPlayedList = setExplainationVideoPlayedStatus(
        blockTwoSubmenuId,
        blockTwoButtonIndex,
        explainationVideoPlayedStatus
      );

      navigate(
        `/b2/menu/${menuId}/submenu/${blockTwoSubmenuId}/${levelURLNames[index]}/${blockTwoLevelId}`,
        {
          state: {
            manifestSubMenuId: `${manifestSubMenuId}`,
            manifestMenuId: manifestBlockId,
            isExplainationVideo:
              isExplainationVideoPlayedList.isExplainationVideoPlayed
                ? false
                : true,
            levelTitle: isExplainationVideoPlayedList.levelTitle,
          },
        }
      );
    });

    clickableElement.style.cursor = "pointer";
  }
};

export const useSvgImageBlockTwoLoaded = (
  props: BlockSubMenuProps,
  menuId: any,
  loadCounter: number
) => {
  const {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    setLoadCounter,
  } = useBlock2BlockMenu();

  const onSvgGraphicBlockTwoLoaded = () => {
    loadCounter = loadCounter + 1;
    setLoadCounter(loadCounter);
    if (loadCounter === 6) {
      setIsVisible(true);
      setBlockTwoLevelRepeatIcons(
        menuId || "",
        props.subMenuData,
        props.manifestBlockId
      );
      addClickEventOnStone("doel_knop_drempel", () => {
        toggleBlockHelp("block_help_2");
      });
      setSubMenuBlocks(
        blockTwoRepeatIcons,
        blockTwoGrayedBackgrounds,
        blockTwoPlayButtons,
        props.subMenuData,
        subMenuManifestData
      );

      if (props.onLoad) props.onLoad();
    }
  };
  return {
    isVisible,
    setIsVisible,
    onSvgGraphicBlockTwoLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  };
};

const BlockTwoSubMenu = (props: BlockSubMenuProps) => {
  navigate = useNavigate();
  const { menuId } = useParams();

  const { loadCounter, setLoadCounter, isDataLoading, setIsDataLoading } =
    useBlock2BlockMenu();

  const manifestData = useAppSelector(
    (state: any) => state.manifest.manifestData
  );

  const {
    isVisible,
    onSvgGraphicBlockTwoLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  } = useSvgImageBlockTwoLoaded(props, menuId, loadCounter);

  useEffect(() => {
    let blockTwoManifestChildren = manifestData?.children;
    setLoadCounter(0);
    setIsDataLoading(true);

    setSubMenuManifestDataByMenuId(
      blockTwoManifestChildren,
      (data: any) => {
        setSubMenuManifestData(data);
      },
      menuId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingDataComplete(
      props,
      () => {
        setIsDataLoading(false);
      },
      menuId,
      subMenuManifestData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId, subMenuManifestData, props.subMenuData]);

  return (
    <>
      {!isDataLoading && (
        <div style={{ visibility: isVisible ? "visible" : "hidden" }}>
          <SvgGraphic src={Background} onLoad={onSvgGraphicBlockTwoLoaded} />
          <SvgGraphic
            src={FocusGrayedOut}
            onLoad={onSvgGraphicBlockTwoLoaded}
          />
          <SvgGraphic src={Focus} onLoad={onSvgGraphicBlockTwoLoaded} />
          <SvgGraphic src={Complete} onLoad={onSvgGraphicBlockTwoLoaded} />
          <SvgGraphic
            src={HelpBlock}
            style={{ display: "none" }}
            id={"block_help_2"}
            onLoad={onSvgGraphicBlockTwoLoaded}
          />
          <SvgGraphic src={Foreground} onLoad={onSvgGraphicBlockTwoLoaded} />
        </div>
      )}
    </>
  );
};
export default BlockTwoSubMenu;
