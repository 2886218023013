import React, { useEffect, useState } from "react";
import powerBackground from "../../assets/background_img/power.jpg";
import speedOneBackground from "../../assets/background_img/speed1.jpg";
import powerYearFourBackground from "../../assets/background_img/Achtergrond_level1.svg";
import speedTwoBackground from "../../assets/background_img/speed2.jpg";
import powerMonkeyWin from "../../assets/background_img/power-win.png";
import powerMonkeyLoose from "../../assets/background_img/power-loose.png";
import speedOneMonkeyWin from "../../assets/background_img/speed-one-win.png";
import speedOneMonkeyLoose from "../../assets/background_img/speed-one-loose.png";
import speedTwoMonkeyWin from "../../assets/background_img/speed-two-win.png";
import speedTwoMonkeyLoose from "../../assets/background_img/speed-two-loose.png";

import { GameType } from "../game-container/game-container";

type GameOverProps = {
  isGameWin: boolean;
  gameType: string;
  style?: object;
};

export default function GameOver(props: GameOverProps) {
  const [background, setBackground] = useState("");
  const [monkeyImage, setMonkeyImage] = useState("");

  useEffect(() => {
    switch (props.gameType) {
      case GameType.Power:
        setBackground(powerBackground);
        break;
      case GameType.SpeedOne:
        setBackground(speedOneBackground);
        break;
      case GameType.SpeedTwo:
        setBackground(speedTwoBackground);
        break;
      case GameType.PowerWater:
        setBackground(powerYearFourBackground);
        break;
      case GameType.SpeedOneAir:
        setBackground(powerYearFourBackground);
        break;
      case GameType.SpeedTwoLand:
        setBackground(speedTwoBackground);
        break;
    }
  }, [props.gameType]);

  useEffect(() => {
    if (props.isGameWin) {
      switch (props.gameType) {
        case GameType.Power:
          setMonkeyImage(powerMonkeyWin);
          break;
        case GameType.SpeedOne:
          setMonkeyImage(speedOneMonkeyWin);
          break;
        case GameType.SpeedTwo:
          setMonkeyImage(speedTwoMonkeyWin);
          break;
        case GameType.PowerWater:
          setMonkeyImage(powerMonkeyWin);
          break;
        case GameType.SpeedOneAir:
          setMonkeyImage(speedOneMonkeyWin);
          break;
        case GameType.SpeedTwoLand:
          setMonkeyImage(speedTwoMonkeyWin);
      }
    } else {
      switch (props.gameType) {
        case GameType.Power:
          setMonkeyImage(powerMonkeyLoose);
          break;
        case GameType.SpeedOne:
          setMonkeyImage(speedOneMonkeyLoose);
          break;
        case GameType.SpeedTwo:
          setMonkeyImage(speedTwoMonkeyLoose);
          break;
        case GameType.PowerWater:
          setMonkeyImage(powerMonkeyLoose);
          break;
        case GameType.SpeedOneAir:
          setMonkeyImage(speedOneMonkeyLoose);
          break;
        case GameType.SpeedTwoLand:
          setMonkeyImage(speedTwoMonkeyLoose);
      }
    }
  }, [props.gameType, props.isGameWin]);

  return (
    <div className="game-over" style={props.style}>
      <img
        data-testid="background-image"
        className="game-over-img"
        src={background}
        alt="background"
      />
      <div className="position-absolute top-50 start-50 translate-middle">
        {props.isGameWin ? (
          <h1 className="text-white text-center feedback-text">Goed gedaan!</h1>
        ) : (
          <h1 className="text-white text-center feedback-text">
            Jammer, probeer het nog eens!
          </h1>
        )}
        <div className="d-flex justify-content-center">
          <img
            data-testid="monkey-image"
            src={monkeyImage}
            className=""
            alt="monkey"
          />
        </div>
      </div>
    </div>
  );
}
