import blockclick from "./block-click.svg";

import SvgGraphic from "../../../utils/SvgGraphic";

type menuData = {
  onCompleteLoad: Function;
};

export const useBlockClick = (props: menuData) => {
  let loadCounter = 0;
  const onSvgGraphicLoaded = () => {
    loadCounter += 1;
    if (loadCounter === 1) {
      props.onCompleteLoad();
    }
  };

  return { onSvgGraphicLoaded };
};

const BlockClick = (props: menuData) => {
  const { onSvgGraphicLoaded } = useBlockClick(props);

  return (
    <>
      <SvgGraphic
        src={blockclick}
        onLoad={onSvgGraphicLoaded}
        className="block-click"
      />
    </>
  );
};
export default BlockClick;
