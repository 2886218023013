import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TopNavbar from "../../component/navbar/navbar";
import { useAppSelector } from "../../redux/hooks";

import background from "../../assets/background_img/year3_block_background_landscape.png";
import Page from "../page";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import BlockOneSubMenu from "../../assets/Block1BlockMenu";
import BlockTwoSubMenu from "../../assets/Block2BlockMenu";
import BlockThreeSubMenu from "../../assets/Block3BlockMenu";
import BlockFourSubMenu from "../../assets/Block4BlockMenu";
import BlockFiveSubMenu from "../../assets/Block5BlockMenu";
import BlockSixSubMenu from "../../assets/Block6BlockMenu";
import BlockSevenSubMenu from "../../assets/Block7BlockMenu";
import BlockEightSubMenu from "../../assets/Block8BlockMenu";
import BlockNineSubMenu from "../../assets/Block9BlockMenu";
import BlockTenSubMenu from "../../assets/Block10BlockMenu";
import { subMenuInfo } from "../../api";
import Loader from "../../component/loader/loader";
import SettingOverlay from "../../component/setting-overlay/setting-overlay";

type SubMenuProps = {
  handleFullScreen: Function;
};

export type Level = {
  menuId?: number;
  manifestBlockId?: string;
};

export const useSubMenu = () => {
  const [level, setLevel] = useState<Level>({});
  const [isLoading, setIsLoading] = useState(true);
  const [blockSubMenu, setBlockSubMenu] = useState<JSX.Element>();
  const [subMenuData, setSubMenuData] = useState<any>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadComplete = useCallback(() => setIsLoading(false), []);
  const switchBlockSubMenu = (
    menuId: number,
    manifestBlockId: string | undefined
  ) => {
    if (manifestBlockId) {
      switch (menuId) {
        case 1:
          setBlockSubMenu(
            <BlockOneSubMenu
              data-testid="block-sub-menu"
              subMenuData={subMenuData}
              onLoad={loadComplete}
              manifestBlockId={manifestBlockId}
            />
          );
          break;
        case 2:
          setBlockSubMenu(
            <BlockTwoSubMenu
              subMenuData={subMenuData}
              onLoad={loadComplete}
              manifestBlockId={manifestBlockId}
            />
          );
          break;
        case 3:
          setBlockSubMenu(
            <BlockThreeSubMenu
              subMenuData={subMenuData}
              onLoad={loadComplete}
              manifestBlockId={manifestBlockId}
            />
          );
          break;
        case 4:
          setBlockSubMenu(
            <BlockFourSubMenu
              subMenuData={subMenuData}
              onLoad={loadComplete}
              manifestBlockId={manifestBlockId}
            />
          );
          break;
        case 5:
          setBlockSubMenu(
            <BlockFiveSubMenu
              subMenuData={subMenuData}
              onLoad={loadComplete}
              manifestBlockId={manifestBlockId}
            />
          );
          break;
        case 6:
          setBlockSubMenu(
            <BlockSixSubMenu
              subMenuData={subMenuData}
              onLoad={loadComplete}
              manifestBlockId={manifestBlockId}
            />
          );
          break;
        case 7:
          setBlockSubMenu(
            <BlockSevenSubMenu
              subMenuData={subMenuData}
              onLoad={loadComplete}
              manifestBlockId={manifestBlockId}
            />
          );
          break;
        case 8:
          setBlockSubMenu(
            <BlockEightSubMenu
              subMenuData={subMenuData}
              onLoad={loadComplete}
              manifestBlockId={manifestBlockId}
            />
          );
          break;
        case 9:
          setBlockSubMenu(
            <BlockNineSubMenu
              subMenuData={subMenuData}
              onLoad={loadComplete}
              manifestBlockId={manifestBlockId}
            />
          );
          break;
        case 10:
          setBlockSubMenu(
            <BlockTenSubMenu
              subMenuData={subMenuData}
              onLoad={loadComplete}
              manifestBlockId={manifestBlockId}
            />
          );
          break;
        default:
          return undefined;
      }
    }
  };

  return {
    level,
    setLevel,
    blockSubMenu,
    setBlockSubMenu,
    subMenuData,
    setSubMenuData,
    switchBlockSubMenu,
    isLoading,
    setIsLoading,
  };
};

function SubMenu(props: SubMenuProps) {
  const handle = useFullScreenHandle();
  const navigate = useNavigate();
  const popOut = useAppSelector((state: any) => state.navbar.openDropDown);
  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clickMiniMap = useCallback(() => navigate("/menu"), []);
  const {
    level,
    setLevel,
    blockSubMenu,
    subMenuData,
    setSubMenuData,
    switchBlockSubMenu,
    isLoading,
    setIsLoading,
  } = useSubMenu();

  useEffect(() => {
    setIsLoading(true);
    if (location.state) {
      setLevel(location.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (level && level.menuId) {
      getSubMenuProgressStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level, level.menuId]);

  useEffect(() => {
    if (level && subMenuData) {
      switchBlockSubMenu(level.menuId || 0, level.manifestBlockId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level, subMenuData]);

  async function getSubMenuProgressStatus() {
    try {
      const subMenuData = await subMenuInfo({
        menuId: `${level.menuId}`,
      });
      setSubMenuData(subMenuData.data);
    } catch (err) {}
  }

  return (
    <FullScreen handle={handle}>
      <TopNavbar leftContent="back" handleFullScreen={props.handleFullScreen} />
      {popOut && <SettingOverlay />}
      {level.menuId && (
        <Page
          level={level.menuId}
          childElement={blockSubMenu}
          backgroundURL={background}
          showCharacter={true}
          onMiniMapClick={clickMiniMap}
        />
      )}
      <Loader
        style={{
          visibility: isLoading ? "visible" : "hidden",
          PointerEvent: "none",
        }}
      />
    </FullScreen>
  );
}

export default SubMenu;
