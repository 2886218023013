import block1complete from "./block1-completed.svg";
import block2complete from "./block2-completed.svg";
import block3complete from "./block3-completed.svg";
import block4complete from "./block4-completed.svg";
import block5complete from "./block5-completed.svg";
import block6complete from "./block6-completed.svg";
import block7complete from "./block7-completed.svg";
import block8complete from "./block8-completed.svg";
import block9complete from "./block9-completed.svg";
import block10complete from "./block10-completed.svg";
import shadowMoss from "../shadow-moss.svg";

import SvgGraphic from "../../../utils/SvgGraphic";

type blockCompleteProps = {
  // menuData: any;
  onCompleteLoad: Function;
};
export const useBlockCompleted = (props: blockCompleteProps) => {
  let loadCounter = 0;
  const onSvgGraphicLoaded = () => {
    loadCounter += 1;
    if (loadCounter === 11) {
      props.onCompleteLoad();
    }
  };

  return { onSvgGraphicLoaded };
};

const BlockCompleted = (props: blockCompleteProps) => {
  const { onSvgGraphicLoaded } = useBlockCompleted(props);

  return (
    <>
      <SvgGraphic
        src={block10complete}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
      <SvgGraphic
        src={block9complete}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
      <SvgGraphic
        src={block8complete}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
      <SvgGraphic
        src={block7complete}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
      <SvgGraphic
        src={block6complete}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
      <SvgGraphic
        src={block5complete}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
      <SvgGraphic
        src={block4complete}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
      <SvgGraphic
        src={block3complete}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
      <SvgGraphic
        src={block2complete}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
      <SvgGraphic
        src={block1complete}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
      <SvgGraphic
        src={shadowMoss}
        onLoad={onSvgGraphicLoaded}
        className="block-complete"
      />
    </>
  );
};
export default BlockCompleted;
