import React, { useEffect, useState } from "react";
import { GameType } from "../../../component/game-container/game-container";
import Level from "../level";
import { useLocation, useParams } from "react-router-dom";

type PowerProps = {
  handleFullScreen: Function;
};

type manifestInfo = {
  manifestSubMenuId?: string;
  manifestMenuId?: string;
  isExplanationVideo?: boolean;
  levelTitle?: string;
};

export default function Power(props: PowerProps) {
  let location = useLocation();
  const [manifestInformation, setManifestInformation] = useState<manifestInfo>(
    {}
  );

  const { menuUID, subMenuId, levelId } = useParams();
  const playerInfo = {
    menuId: Number(menuUID),
    subMenuId: Number(subMenuId),
  };

  useEffect(() => {
    if (location.state) setManifestInformation(location.state);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifestInformation]);

  return (
    <>
      <Level
        handleFullScreen={props.handleFullScreen}
        gameType={GameType.Power}
        showFrog={false}
        exerciseId={levelId}
        playerInfo={playerInfo}
        parameterInformation={manifestInformation}
      ></Level>
    </>
  );
}
