import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store/store";
import { ManifestTypes } from "./manifest.types";

const initialState: ManifestTypes = {
  manifestData: null,
};
export const manifestSlice = createSlice({
  name: "manifest",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateManifest: (state, action) => {
      state.manifestData = action.payload;
    },
  },
});

export const { updateManifest } = manifestSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.manifest.manifestData;

export default manifestSlice.reducer;
