import { createSlice } from "@reduxjs/toolkit";
import { FullScreenTypes } from "./fullScreenHandler.types";
import type { RootState } from "../store/store";

const initialState: FullScreenTypes = {
  isFullScreen: false,
};
export const fullScreenSlice = createSlice({
  name: "fullscreen",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateFullScreen: (state, action) => {
      state.isFullScreen = action.payload;
    },
  },
});

export const { updateFullScreen } = fullScreenSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.fullscreen.isFullScreen;

export default fullScreenSlice.reducer;
