import React from "react";
import beginarrow from "../../assets/icons/beginarrow.svg";
export interface ArrowButtonType {
  onClick: Function;
}
export default function ArrowButton(props: ArrowButtonType) {
  return (
    <div className="back-overlay">
      <div
        onClick={() => {
          props.onClick();
        }}
        className="start-game-icon"
      >
        <div data-testid="gamebegin-btn" className="arrow-icon">
          <img
            draggable="false"
            className="user-events-select"
            src={beginarrow}
            alt="arrow start"
          />
        </div>
      </div>
    </div>
  );
}
