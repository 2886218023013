type audioVoiceType = {
  id: string;
  url: string;
  onLoad?: () => void;
  onPlay?: () => void;
  onEnded?: () => void;
};

function AudioVoice(props: audioVoiceType) {
  return (
    <audio
      id={props.id}
      controls
      src={props.url}
      style={{ display: "none" }}
      onLoadedData={() => {
        if (props.onLoad) props.onLoad();
      }}
      onPlay={() => {
        if (props.onPlay) props.onPlay();
      }}
      onEnded={() => {
        if (props.onEnded) props.onEnded();
      }}
    ></audio>
  );
}

export default AudioVoice;
