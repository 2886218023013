import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { navbarSlice } from "../navbar/navbar.slice";
import { authInfoSlice } from "../auth/auth.slice";
import { fullScreenSlice } from "../fullScreenHandler/fullScreenHandler.slice";
import { manifestSlice } from "../manifest/manifest.slice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const reducer = combineReducers({
  navbar: navbarSlice.reducer,
  authInfo: authInfoSlice.reducer,
  fullscreen: fullScreenSlice.reducer,
  manifest: manifestSlice.reducer,
});
const persistReducers = persistReducer(persistConfig, reducer);
export const store = configureStore({
  reducer: persistReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
