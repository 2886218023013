import { useCallback } from "react";

type boxType = {
  id: string;
  children?: React.ReactNode;
  onClick: Function;
  customClass: string;
  testid?: string;
};

export default function ButtonBox(props: boxType) {
  const onClick = useCallback(
    (e: any) => {
      props.onClick(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onClick]
  );

  const onMouseDown = useCallback(
    (e: any) => {
      e.preventDefault();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <div
      id={props.id}
      data-testid={props.testid}
      className={props.customClass}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {props.children}
    </div>
  );
}
