import { useCallback } from "react";
import SvgGraphic from "../../../utils/SvgGraphic";
import drempel1 from "./_x30_1_x5F_drempel.svg";
import drempel2 from "./_x30_2_x5F_drempel.svg";
import drempel3 from "./_x30_3_x5F_drempel.svg";
import drempel4 from "./_x30_4_x5F_drempel.svg";
import drempel5 from "./_x30_5_x5F_drempel.svg";
import drempel6 from "./_x30_6_x5F_drempel.svg";
import drempel7 from "./_x30_7_x5F_drempel.svg";
import shadowWall from "../shadow-wall.svg";

type BlockWallProps = {
  onCompleteLoad: Function;
};

export const useBlockWall = (
  useCallback: Function,
  loadCounter: number,
  props: any
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadComplete = useCallback(() => onSvgGraphicLoaded(), []);
  function onSvgGraphicLoaded() {
    loadCounter += 1;
    if (loadCounter === 8) {
      props.onCompleteLoad();
    }
  }
  return { onSvgGraphicLoaded, loadComplete };
};
const BlockWall = (props: BlockWallProps) => {
  let loadCounter = 0;
  const { loadComplete } = useBlockWall(useCallback, loadCounter, props);

  return (
    <>
      <SvgGraphic
        src={shadowWall}
        onLoad={loadComplete}
        className="block-wall"
      />
      <SvgGraphic src={drempel1} onLoad={loadComplete} className="block-wall" />
      <SvgGraphic src={drempel2} onLoad={loadComplete} className="block-wall" />
      <SvgGraphic src={drempel3} onLoad={loadComplete} className="block-wall" />
      <SvgGraphic src={drempel4} onLoad={loadComplete} className="block-wall" />
      <SvgGraphic src={drempel5} onLoad={loadComplete} className="block-wall" />
      <SvgGraphic src={drempel6} onLoad={loadComplete} className="block-wall" />
      <SvgGraphic src={drempel7} onLoad={loadComplete} className="block-wall" />
    </>
  );
};
export default BlockWall;
