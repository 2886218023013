import SvgGraphic from "../../../utils/SvgGraphic";
import block1grayedout from "./block1-grayedout.svg";
import block2grayedout from "./block2-grayedout.svg";
import block3grayedout from "./block3-grayedout.svg";
import block4grayedout from "./block4-grayedout.svg";
import block5grayedout from "./block5-grayedout.svg";
import block6grayedout from "./block6-grayedout.svg";
import block7grayedout from "./block7-grayedout.svg";
import block8grayedout from "./block8-grayedout.svg";
import block9grayedout from "./block9-grayedout.svg";
import block10grayedout from "./block10-grayedout.svg";

export interface BlockGrayedOutProps {
  onCompleteLoad: Function;
}

export const useBlockGrayedOut = (props: BlockGrayedOutProps) => {
  let loadCounter = 0;
  const onSvgGraphicLoaded = () => {
    loadCounter += 1;
    if (loadCounter === 8) {
      props.onCompleteLoad();
    }
  };

  return { onSvgGraphicLoaded };
};
const BlockGrayedOut = (props: BlockGrayedOutProps) => {
  const { onSvgGraphicLoaded } = useBlockGrayedOut(props);

  return (
    <>
      <SvgGraphic
        src={block10grayedout}
        onLoad={onSvgGraphicLoaded}
        className="block-grayedout"
      />
      <SvgGraphic
        src={block9grayedout}
        onLoad={onSvgGraphicLoaded}
        className="block-grayedout"
      />
      <SvgGraphic
        src={block8grayedout}
        onLoad={onSvgGraphicLoaded}
        className="block-grayedout"
      />
      <SvgGraphic
        src={block7grayedout}
        onLoad={onSvgGraphicLoaded}
        className="block-grayedout"
      />
      <SvgGraphic
        src={block6grayedout}
        onLoad={onSvgGraphicLoaded}
        className="block-grayedout"
      />
      <SvgGraphic
        src={block5grayedout}
        onLoad={onSvgGraphicLoaded}
        className="block-grayedout"
      />
      <SvgGraphic
        src={block4grayedout}
        onLoad={onSvgGraphicLoaded}
        className="block-grayedout"
      />
      <SvgGraphic
        src={block3grayedout}
        onLoad={onSvgGraphicLoaded}
        className="block-grayedout"
      />
      <SvgGraphic
        src={block2grayedout}
        onLoad={onSvgGraphicLoaded}
        className="block-grayedout"
      />
      <SvgGraphic
        src={block1grayedout}
        onLoad={onSvgGraphicLoaded}
        className="block-grayedout"
      />
    </>
  );
};
export default BlockGrayedOut;
