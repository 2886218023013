import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import beginarrow from "../assets/icons/beginarrow.svg";
import background from "../assets/background_img/background.jpg";
import PopUpModal from "../component/modal/modal";
import TopNavbar from "../component/navbar/navbar";
import { fullScreenSlice } from "../redux/fullScreenHandler/fullScreenHandler.slice";
import footerLogo from "../assets/svg/icons/zwisen.svg";
import { flushMenuInfo } from "../api";
import SettingOverlay from "../component/setting-overlay/setting-overlay";
import { disclaimerContent } from "../component/constant";

type MainPageType = {
  handleFullScreen: any;
};
export const useOpenDialogBox = () => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  return { openDialog, setOpenDialog };
};

function MainPage(props: MainPageType) {
  const { setOpenDialog, openDialog } = useOpenDialogBox();
  let handle = useFullScreenHandle();
  const dispatch = useAppDispatch();
  const authDetail = useAppSelector((state: any) => state.authInfo);
  const popout = useAppSelector((state: any) => state.navbar.openDropDown);
  let navigate = useNavigate();

  const startGameHandler = useCallback(async () => {
    navigate("/menu");
    await flushMenuInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fullScreenSlice.actions.updateFullScreen(true));
  }, [dispatch]);

  return (
    <FullScreen handle={handle}>
      <img
        draggable="false"
        alt="background-img"
        className="welcome-page-img"
        src={background}
      />
      <TopNavbar
        leftContent={"Jaargroep"}
        handleFullScreen={props.handleFullScreen}
      />
      {popout && <SettingOverlay />}
      <div className="container container-column">
        <h1
          className="user-events-select welcome-text"
          style={{ color: "#fff" }}
        >
          {`Welkom, ${authDetail.userDetail.fullName.split(" ")[0]}`}{" "}
        </h1>
        <div onClick={startGameHandler} className="welcomehome-icon">
          <div data-testid="welcome-button" className="arrow-icon">
            <img
              draggable="false"
              src={beginarrow}
              alt="Start"
              className="user-events-select"
            />
          </div>
        </div>
      </div>
      <div id="footer">
        <PopUpModal
          data-testid="dialog-opener"
          size="lg"
          dialogClassName=""
          show={openDialog}
          close={() => setOpenDialog(false)}
          centered={true}
          title={""}
        >
          <div
            data-testid="popup-modal"
            className="scroll-container"
            dangerouslySetInnerHTML={{ __html: disclaimerContent }}
          ></div>
        </PopUpModal>

        <div
          data-testid="dialog-open"
          title="open dialog"
          aria-label="open dialog"
          aria-labelledby="open dialog"
          className="footer-text"
          onClick={() => {
            setOpenDialog(true);
          }}
        >
          Disclaimer
        </div>
        <img
          draggable="false"
          src={footerLogo}
          alt="footer-logo"
          className="footer-logo"
        />
      </div>
    </FullScreen>
  );
}
export default MainPage;
