import { useEffect, useRef, useState } from "react";
import cave from "../../assets/background_img/achtergrond_level3_rock.svg";
import monsterTongue from "../../assets/spider-animation/monster-tongue.svg";
import Spritesheet from "react-responsive-spritesheet";
import SvgGraphic from "../../utils/SvgGraphic";
import redSpider from "../../assets/spider-animation/red-spider.png";
import greenSpider from "../../assets/spider-animation/green-spider.png";
import { SpeedTwoCharacter } from "../fish-animation/fish-animation";

export type SpiderAnimationProps = {
  timerDurationInMilliSeconds: number;
  startAnimation: boolean;
  pauseAnimation: boolean;
  isAnswerSubmitted: boolean;
  isCorrectAnswer: boolean;
  isLoading: boolean;
  spiderColor: SpeedTwoCharacter;
  onTimerEnd?: Function;
  onAnimationEnd?: Function;
};

export const useSpiderAnimation = (props: SpiderAnimationProps) => {
  const [isSpiderVisible, setIsSpiderVisible] = useState(false);
  const [spritesheetInstance, setSpritesheetInstance] = useState<Spritesheet>();
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);
  const [isTimerEnd, setIsTimerEnd] = useState(false);
  const [spiderMoveToCaveAnimation, setSpiderMoveToCaveAnimation] =
    useState<Animation>();
  const [touchSpiderByTongueAnimation, setTouchSpiderByTongueAnimation] =
    useState<Animation>();
  const [grabbingSpiderAnimation, setGrabbingSpiderAnimation] =
    useState<Animation>();
  const mounted = useRef(false);

  const spider = document.getElementsByClassName(
    "sprite-sheet-animation"
  )[0] as HTMLElement;

  const monsterTongue = document.getElementById("monster-tongue");
  if (monsterTongue) monsterTongue.style.transform = "scale(0)";

  const moveSpiderToCave = () => {
    if (spider) {
      const moveSpiderToCaveAnimation = spider.animate(
        [{ left: "100%", visibility: "visible" }, { left: "5%" }],
        {
          duration: props.timerDurationInMilliSeconds / 20,
          fill: "forwards",
        }
      );
      moveSpiderToCaveAnimation.addEventListener("finish", () => {
        spider.style.visibility = "hidden";
        if (mounted.current) {
          setIsTimerEnd(true);
          setIsAnimationFinished(true);
          if (props.onTimerEnd) props.onTimerEnd();
        }
      });
      setSpiderMoveToCaveAnimation(moveSpiderToCaveAnimation);
      setIsTimerEnd(false);
    }
  };

  const touchSpiderByTongue = () => {
    const tongueLeftPos = 0.5;
    if (monsterTongue) {
      const touchSpiderWithTongueAnimation = monsterTongue.animate(
        [
          { transform: "scaleX(0)" },
          {
            transform: `scaleX(${
              getSpiderCurrentLeftPos() / 10 - tongueLeftPos
            })`,
          },
        ],
        {
          duration: 500,
          fill: "forwards",
        }
      );
      // Drag spider after touching spider by tongue
      touchSpiderWithTongueAnimation.addEventListener("finish", () => {
        if (props.onTimerEnd) props.onTimerEnd();
        dragSpiderToCaveByTongue();
      });
      setTouchSpiderByTongueAnimation(touchSpiderWithTongueAnimation);
    }
  };

  const dragSpiderToCaveByTongue = () => {
    spider.animate(
      [
        { left: `${getSpiderCurrentLeftPos()}%` },
        { left: "5%", visibility: "hidden" },
      ],
      {
        duration: 500,
        fill: "forwards",
      }
    );

    const tongueGrabbingSpiderAnimation = monsterTongue?.animate(
      [{ transform: "scaleX(0)" }],
      {
        duration: 500,
        fill: "forwards",
      }
    );

    tongueGrabbingSpiderAnimation?.addEventListener("finish", () => {
      setIsAnimationFinished(true);
    });
    setGrabbingSpiderAnimation(tongueGrabbingSpiderAnimation);
  };

  return {
    spider,
    moveSpiderToCave,
    touchSpiderByTongue,
    isAnimationFinished,
    setIsAnimationFinished,
    isTimerEnd,
    setIsTimerEnd,
    spiderMoveToCaveAnimation,
    spritesheetInstance,
    setSpritesheetInstance,
    getSpiderCurrentLeftPos,
    touchSpiderByTongueAnimation,
    setTouchSpiderByTongueAnimation,
    grabbingSpiderAnimation,
    setGrabbingSpiderAnimation,
    dragSpiderToCaveByTongue,
    isSpiderVisible,
    setIsSpiderVisible,
    mounted,
  };
};

const getSpiderCurrentLeftPos = () => {
  const spider = document.getElementsByClassName(
    "sprite-sheet-animation"
  )[0] as HTMLElement;

  const spiderAnimationContainer = document.getElementsByClassName(
    "spider-animation-container"
  )[0];

  return (
    (spider.getBoundingClientRect().left /
      spiderAnimationContainer.getBoundingClientRect().width) *
    100
  );
};

export const moveBackSpiderToOriginalPosition = (
  spider: HTMLElement,
  spiderSpriteSheet: Spritesheet,
  onAnimationEnd: Function
) => {
  spider.style.transform = "scale(1, 1)";
  if (spiderSpriteSheet) spiderSpriteSheet.play();
  const moveToOriginalPos = spider.animate([{ left: "100%" }], {
    duration: 1500,
    fill: "forwards",
  });
  moveToOriginalPos.addEventListener("finish", () => {
    spider.style.transform = "scale(-1, 1)";
    if (onAnimationEnd) onAnimationEnd();
  });
};

function SpiderAnimation(props: SpiderAnimationProps) {
  const {
    spider,
    moveSpiderToCave,
    touchSpiderByTongue,
    isAnimationFinished,
    setIsAnimationFinished,
    isTimerEnd,
    spiderMoveToCaveAnimation,
    spritesheetInstance,
    setSpritesheetInstance,
    isSpiderVisible,
    setIsSpiderVisible,
    mounted,
  } = useSpiderAnimation(props);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.onAnimationEnd && isAnimationFinished) {
      props.onAnimationEnd();
      setIsAnimationFinished(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimationFinished, props.onAnimationEnd]);

  useEffect(() => {
    if (props.startAnimation) {
      // Start animation
      moveSpiderToCave();
      if (spritesheetInstance) spritesheetInstance.play();
    }

    // sets visibility of spider when assets scale is not undefined
    const spriteSheetScale = spritesheetInstance?.getInfo("scale");
    spriteSheetScale ? setIsSpiderVisible(true) : setIsSpiderVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startAnimation, spritesheetInstance]);

  useEffect(() => {
    if (spiderMoveToCaveAnimation && props.pauseAnimation) {
      spritesheetInstance?.pause();
      spiderMoveToCaveAnimation.pause();
    }
    if (props.isAnswerSubmitted && !props.isCorrectAnswer && !isTimerEnd) {
      touchSpiderByTongue();
    } else if (
      props.isAnswerSubmitted &&
      props.isCorrectAnswer &&
      !isTimerEnd
    ) {
      if (spritesheetInstance) {
        moveBackSpiderToOriginalPosition(spider, spritesheetInstance, () => {
          if (props.onAnimationEnd) props.onAnimationEnd();
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCorrectAnswer, props.isAnswerSubmitted, props.pauseAnimation]);

  return (
    <>
      <div className="monster-container">
        <SvgGraphic id={"cave"} src={cave} />
        <div className="spider-animation-container">
          <SvgGraphic id="monster-tongue" src={monsterTongue} />
          {!props.isLoading && (
            <div className="spider-sprite-container">
              {props.spiderColor === SpeedTwoCharacter.RED ? (
                <Spritesheet
                  className="sprite-sheet-animation"
                  style={
                    isSpiderVisible
                      ? { visibility: "visible" }
                      : { visibility: "hidden" }
                  }
                  direction="forward"
                  image={redSpider}
                  widthFrame={190}
                  heightFrame={82}
                  steps={25}
                  fps={60}
                  autoplay={false}
                  loop={true}
                  isResponsive={true}
                  onInit={(spritesheet) => {
                    setSpritesheetInstance(spritesheet);
                  }}
                ></Spritesheet>
              ) : (
                <Spritesheet
                  className="sprite-sheet-animation"
                  style={
                    isSpiderVisible
                      ? { visibility: "visible" }
                      : { visibility: "hidden" }
                  }
                  direction="forward"
                  image={greenSpider}
                  widthFrame={190}
                  heightFrame={82}
                  steps={25}
                  fps={60}
                  autoplay={false}
                  loop={true}
                  isResponsive={true}
                  onInit={(spritesheet) => {
                    setSpritesheetInstance(spritesheet);
                  }}
                ></Spritesheet>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SpiderAnimation;

