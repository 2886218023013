export function elementReady(id: string) {
  return new Promise(async (resolve, reject) => {
    let el = await document.getElementById(id);
    if (el) {
      resolve(el);
      return;
    }
    new MutationObserver(async (mutationRecords, observer) => {
      let element = await document.getElementById(id);
      if (element) {
        resolve(element);
        observer.disconnect();
      }
    }).observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
}
