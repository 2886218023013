import Spritesheet from "react-responsive-spritesheet";
import greenSpider from "../../assets/spider-animation/green-spider-hanging.png";
import { useEffect, useState } from "react";
type HangingSpiderProps = {
  remainingLife: number;
  isGameStarted: boolean | undefined;
  onAnimationCompleted?: (isComplated: boolean) => void;
};

export const useHangingSpider = () => {
  const [greenSpiderInstance, setGreenSpiderInstance] = useState<Spritesheet>();
  const [greenSpiderScaleValue, setGreenSpiderScaleValue] =
    useState<number | undefined>();

  return {
    greenSpiderInstance,
    setGreenSpiderInstance,
    greenSpiderScaleValue,
    setGreenSpiderScaleValue,
  };
};
const HangingSpider = (props: HangingSpiderProps) => {
  const {
    greenSpiderInstance,
    setGreenSpiderInstance,
    greenSpiderScaleValue,
    setGreenSpiderScaleValue,
  } = useHangingSpider();

  useEffect(() => {
    if (greenSpiderInstance) {
      const scale = greenSpiderInstance?.getInfo("scale");
      setGreenSpiderScaleValue(scale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [greenSpiderInstance, greenSpiderScaleValue]);

  useEffect(() => {
    if (props.remainingLife === 0) {
      greenSpiderInstance?.play();
    }
  }, [greenSpiderInstance, props.remainingLife]);

  return (
    <>
      {props.isGameStarted && (
        <div className="hanging-spiders-container">
          <Spritesheet
            className="green-hanging-spider-sprite-sheet"
            style={
              greenSpiderScaleValue
                ? { visibility: "visible" }
                : { visibility: "hidden" }
            }
            direction={"forward"}
            image={greenSpider}
            widthFrame={105}
            heightFrame={723}
            startAt={1}
            steps={33}
            fps={60}
            autoplay={false}
            loop={false}
            onEnterFrame={[
              {
                frame: 32,
                callback: () => {
                  if (props.onAnimationCompleted)
                    props.onAnimationCompleted(true);
                },
              },
            ]}
            onInit={(spritesheet) => {
              setGreenSpiderInstance(spritesheet);
            }}
          />
        </div>
      )}
    </>
  );
};
export default HangingSpider;
