import React, { useEffect, useState } from "react";
import Background from "./block5-background.svg";
import Complete from "./block5-complete.svg";
import FocusGrayedOut from "./block5-focus-grayed-out.svg";
import Focus from "./block5-focus.svg";
import Foreground from "./block5-foreground.svg";
import HelpBlock from "./block5-help-block.svg";

import SvgGraphic from "../../utils/SvgGraphic";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
  setExplainationVideoPlayedStatus,
  setLoadingDataComplete,
  setSubMenuBlocks,
  addClickEventOnStone,
  setSubMenuManifestDataByMenuId,
  toggleBlockHelp,
} from "../../utils/SubmenuController";
import { useAppSelector } from "../../redux/hooks";
import { BlockSubMenuProps } from "../Block1BlockMenu";
import { levelURLNames } from "../../component/constant";
import { elementReady } from "../../utils/CheckElement";

const blockFiveRepeatIcons = [
  [
    { id: "repeat_btn_b5_1_1" },
    { id: "repeat_btn_b5_1_2" },
    { id: "repeat_btn_b5_1_3" },
  ],
  [
    { id: "repeat_btn_b5_2_1" },
    { id: "repeat_btn_b5_2_2" },
    { id: "repeat_btn_b5_2_3" },
  ],
  [
    { id: "repeat_btn_b5_3_1" },
    { id: "repeat_btn_b5_3_2" },
    { id: "repeat_btn_b5_3_3" },
  ],
];

const blockFiveGrayedBackgrounds = [
  [
    { id: "grayedout_b5_1_1" },
    { id: "grayedout_b5_1_2" },
    { id: "grayedout_b5_1_3" },
  ],
  [
    { id: "grayedout_b5_2_1" },
    { id: "grayedout_b5_2_2" },
    { id: "grayedout_b5_2_3" },
  ],
  [
    { id: "grayedout_b5_3_1" },
    { id: "grayedout_b5_3_2" },
    { id: "grayedout_b5_3_3" },
  ],
];
const blockFivePlayButtons = [
  [
    { id: "play_btn_5_1_1" },
    { id: "play_btn_5_1_2" },
    { id: "play_btn_5_1_3" },
  ],
  [
    { id: "play_btn_5_2_1" },
    { id: "play_btn_5_2_2" },
    { id: "play_btn_5_2_3" },
  ],
  [
    { id: "play_btn_5_3_1" },
    { id: "play_btn_5_3_2" },
    { id: "play_btn_5_3_3" },
  ],
];

export const useBlock5BlockMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [subMenuManifestData, setSubMenuManifestData] = useState([]);
  const [blockFiveLoadCounter, setBlockFiveLoadCounter] = useState(0);
  const [blockFiveIsLoading, setBlockFiveIsLoading] = useState(true);
  return {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    blockFiveLoadCounter,
    setBlockFiveLoadCounter,
    blockFiveIsLoading,
    setBlockFiveIsLoading,
  };
};

type urlParams = {
  state: {
    path?: string;
    levelTitle?: string;
    manifestSubMenuId?: string;
    manifestMenuId?: string;
  };
};
let navigate: NavigateFunction | ((arg0: string, params?: urlParams) => void);

export const setBlockFiveLevelRepeatIcons = (
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  blockFiveRepeatIcons.forEach((subMenus) => {
    subMenus.forEach(async (item, index) => {
      await elementReady(item.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockFiveLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
  blockFivePlayButtons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockFiveLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
  return "gray five block loaded";
};

export const addClickEventOnBlockFiveLevels = (
  clickableElement: HTMLElement | null,
  index: number,
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  if (clickableElement) {
    clickableElement.addEventListener("click", () => {
      let manifestSubMenuId =
        clickableElement.getAttribute("manifestSubMenuId");
      const blockFiveSubmenuId = clickableElement.getAttribute("subMenuId");
      const blockFiveLevelId = clickableElement.getAttribute("levelId");

      const blockFiveButtonIndex = clickableElement.getAttribute("data-index");

      const isExplainationVideoPlayedList = setExplainationVideoPlayedStatus(
        blockFiveSubmenuId,
        blockFiveButtonIndex,
        explainationVideoPlayedStatus
      );

      navigate(
        `/b5/menu/${menuId}/submenu/${blockFiveSubmenuId}/${levelURLNames[index]}/${blockFiveLevelId}`,
        {
          state: {
            manifestSubMenuId: `${manifestSubMenuId}`,
            manifestMenuId: manifestBlockId,
            isExplainationVideo:
              isExplainationVideoPlayedList.isExplainationVideoPlayed
                ? false
                : true,
            levelTitle: isExplainationVideoPlayedList.levelTitle,
          },
        }
      );
    });

    clickableElement.style.cursor = "pointer";
  }
};
export const useSvgImageBlockFiveLoaded = (
  props: BlockSubMenuProps,
  menuId: any,
  loadCounter: number
) => {
  const {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    setBlockFiveLoadCounter,
  } = useBlock5BlockMenu();

  const onSvgGraphicBlockFiveLoaded = () => {
    loadCounter = loadCounter + 1;
    setBlockFiveLoadCounter(loadCounter);
    if (loadCounter === 6) {
      setIsVisible(true);
      setBlockFiveLevelRepeatIcons(
        menuId || "",
        props.subMenuData,
        props.manifestBlockId
      );
      addClickEventOnStone("doel_knop_drempel_1_", () => {
        toggleBlockHelp("block_help_5");
      });
      setSubMenuBlocks(
        blockFiveRepeatIcons,
        blockFiveGrayedBackgrounds,
        blockFivePlayButtons,
        props.subMenuData,
        subMenuManifestData
      );

      if (props.onLoad) props.onLoad();
    }
  };
  return {
    isVisible,
    setIsVisible,
    onSvgGraphicBlockFiveLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  };
};

const BlockFiveSubMenu = (props: BlockSubMenuProps) => {
  navigate = useNavigate();
  const { menuId } = useParams();

  const {
    blockFiveLoadCounter,
    setBlockFiveLoadCounter,
    blockFiveIsLoading,
    setBlockFiveIsLoading,
  } = useBlock5BlockMenu();

  const {
    isVisible,
    onSvgGraphicBlockFiveLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  } = useSvgImageBlockFiveLoaded(props, menuId, blockFiveLoadCounter);
  const manifestData = useAppSelector(
    (state: any) => state.manifest.manifestData
  );

  useEffect(() => {
    let blockFiveManifestChildren = manifestData?.children;
    setBlockFiveLoadCounter(0);
    setBlockFiveIsLoading(true);
    setSubMenuManifestDataByMenuId(
      blockFiveManifestChildren,
      (data: any) => {
        setSubMenuManifestData(data);
      },
      menuId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingDataComplete(
      props,
      () => {
        setBlockFiveIsLoading(false);
      },
      menuId,
      subMenuManifestData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId, subMenuManifestData, props.subMenuData]);

  return (
    <>
      {!blockFiveIsLoading && (
        <div style={{ visibility: isVisible ? "visible" : "hidden" }}>
          <SvgGraphic src={Background} onLoad={onSvgGraphicBlockFiveLoaded} />
          <SvgGraphic
            src={FocusGrayedOut}
            onLoad={onSvgGraphicBlockFiveLoaded}
          />
          <SvgGraphic src={Focus} onLoad={onSvgGraphicBlockFiveLoaded} />
          <SvgGraphic src={Complete} onLoad={onSvgGraphicBlockFiveLoaded} />
          <SvgGraphic
            src={HelpBlock}
            style={{ display: "none" }}
            id={"block_help_5"}
            onLoad={onSvgGraphicBlockFiveLoaded}
          />
          <SvgGraphic src={Foreground} onLoad={onSvgGraphicBlockFiveLoaded} />
        </div>
      )}
    </>
  );
};
export default BlockFiveSubMenu;
