import { useEffect, useState } from "react";
import level1Image from "../../assets/mini-map/block1.svg";
import level2Image from "../../assets/mini-map/block2.svg";
import level3Image from "../../assets/mini-map/block3.svg";
import level4Image from "../../assets/mini-map/block4.svg";
import level5Image from "../../assets/mini-map/block5.svg";
import level6Image from "../../assets/mini-map/block6.svg";
import level7Image from "../../assets/mini-map/block7.svg";
import level8Image from "../../assets/mini-map/block8.svg";
import level9Image from "../../assets/mini-map/block9.svg";
import level10Image from "../../assets/mini-map/block10.svg";

type MiniMapProps = {
  onClick?: Function;
  level?: number;
};

function MiniMap(props: MiniMapProps) {
  const [miniMapImage, setMiniMapImage] = useState("");
  const level = props.level || 0;

  useEffect(() => {
    const miniMapImages = [
      level1Image,
      level2Image,
      level3Image,
      level4Image,
      level5Image,
      level6Image,
      level7Image,
      level8Image,
      level9Image,
      level10Image,
    ];

    setMiniMapImage(() =>
      level > 0 && level <= miniMapImages.length ? miniMapImages[level - 1] : ""
    );
  }, [level]);

  return (
    <>
      {miniMapImage !== "" && (
        <div className="mini-map" data-testid="mini-map">
          <img
            draggable="false"
            src={miniMapImage}
            alt="mini_map"
            style={{ pointerEvents: "none" }}
          />
          <div
            className="clickable-layer"
            data-testid="clickable-layer"
            onClick={() => {
              props.onClick && props.onClick();
            }}
          ></div>
        </div>
      )}
    </>
  );
}

export default MiniMap;
