import axios from "axios";
import axiosRetry from 'axios-retry';

import { getEnvironmentUrl } from "../services/base";

import { endpoints } from "./endpoints";

axios.defaults.headers.post["Content-Type"] = "application/json";

type manifestType = {
  course: string;
  product: string;
  year: string;
};

type submenuType = {
  menuId: string;
};
type ExercisePlayerProps = {
  menuId: number;
  subMenuId: number;
  levelTitle: string;
  publicationId: string;
  exerciseId: string;
  exerciseAttemptNumber: number;
  status: number;
  learningObjectiveId: string | undefined;
  blockId: string | undefined;
  courseName: string;
};

type ExercisePlayerSaveProps = {
  menuId: number;
  subMenuId: number;
  levelTitle: string;
  publicationId: string;
  exerciseId: string;
  assessmentId: string;
  assessmentOrderNumber: number;
  exerciseAttemptNumber: number;
  assessmentAttemptNumber: number;
  status: number;
  givenAnswer: string;
};

type ValidateAnswerProps = {
  publicationId: string;
  exerciseId: string;
  assessmentId: string;
  interactionId: string;
  answer: string;
  isReturnFeedback: boolean;
};

axiosRetry(axios, {
  retries: 3,
  retryCondition(error) {
    switch(error.response?.status) {
      case 500:
      case 501:
      case 502:
      case 503:
      case 504: return true
      default: return false
    }
  },
  retryDelay: (retryCount: number) => {
    return retryCount * 2000
  },
});

export const authInfo = async () => {
  const baseUrl = await getEnvironmentUrl();
  const options = {
    url: `${baseUrl}/${endpoints.authInfo}`,
    withCredentials: true,
    data: null,
    method: "get",
  };
  return axios(options);
};
export const getUserInfo = async () => {
  const baseUrl = await getEnvironmentUrl();
  const options = {
    url: `${baseUrl}/${endpoints.userInfo}`,
    withCredentials: true,
    data: null,
    method: "get",
  };
  return axios(options);
};

export const manifestInfo = async (props: manifestType) => {
  const baseUrl = await getEnvironmentUrl();
  const options = {
    url: `${baseUrl}/${endpoints.manifest}/${props.course}/${props.product}/${props.year}`,
    withCredentials: true,
    data: null,
    method: "get",
  };
  return axios(options);
};

export const getMenus = async () => {
  const baseUrl = await getEnvironmentUrl();
  const options = {
    url: `${baseUrl}/${endpoints.menus}/getmenus`,
    withCredentials: true,
    data: null,
    method: "get",
  };
  return axios(options);
};
export const getMediaInfo = async (mediaId: string) => {
  const baseUrl = await getEnvironmentUrl();
  const options = {
    url: `${baseUrl}/api/media/getmediainfo?externalReference=${mediaId}`,
    withCredentials: true,
    data: null,
    method: "get",
  };
  return axios(options);
};

export const subMenuInfo = async (props: submenuType) => {
  const baseUrl = await getEnvironmentUrl();
  const options = {
    url: `${baseUrl}/${endpoints.subMenuInfo}?menuId=${props.menuId}`,
    withCredentials: true,
    data: null,
    method: "get",
  };
  return axios(options);
};

export const exercisesPlayerStart = async (props: ExercisePlayerProps) => {
  const baseUrl = await getEnvironmentUrl();
  const options = {
    url: `${baseUrl}/${endpoints.exerciseInfo}/start`,
    withCredentials: true,
    data: props,
    method: "post",
  };
  return axios(options);
};

export const exercisesPlayerSaveAnswer = async (
  props: ExercisePlayerSaveProps
) => {
  const baseUrl = await getEnvironmentUrl();
  const options = {
    url: `${baseUrl}/${endpoints.exerciseInfo}/saveanswer`,
    withCredentials: true,
    data: props,
    method: "post",
  };
  return axios(options);
};

export const exercisesPlayerGameOver = async (props: ExercisePlayerProps) => {
  const baseUrl = await getEnvironmentUrl();
  const options = {
    url: `${baseUrl}/${endpoints.exerciseInfo}/gameover`,
    withCredentials: true,
    data: props,
    method: "post",
  };
  return axios(options);
};

export const flushMenuInfo = async () => {
  const baseUrl = await getEnvironmentUrl();
  const options = {
    url: `${baseUrl}/${endpoints.menus}/flushmenuinfo`,
    withCredentials: true,
    data: null,
    method: "post",
  };
  return axios(options);
};

export const validateAnswer = async (props: ValidateAnswerProps) => {
  const baseUrl = getEnvironmentUrl();

  const options = {
    url: `${baseUrl}/${endpoints.exerciseInfo}/validateanswer`,
    withCredentials: true,
    data: props,
    method: "post",
  };
  return axios(options);
};
