import React, { useEffect, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import powerLeafImage from "../../assets/background_img/leaf.png";
import BoxContainer from "../box-container/box-container";
import BottomContainer from "../bottom-container/bottom-container";
import voiceTestAudio from "../../assets/sound/voicetesting.mp3";
import audioTestImage from "../../assets/icons/audiovoice.svg";
import gameLooseAudio from "../../assets/sound/gameloose.mp3";
import gameWinAudio from "../../assets/sound/gamewin.mp3";

import ProgressBar, {
  Backgrounds,
  ProgressColors,
} from "../progress-bar/progress-bar";
import {
  CompanionType,
  Companions,
  setCompanionPosition,
} from "../progress-bar/companion/companion";

import HelperCard from "../helper-card/helper-card";
import AntBackground from "../ant-background/ant-background";
import PopUpModal from "../modal/modal";
import AudioVoice from "../audio-voice/audio-voice";
import {
  playAudioById,
  stopAudioById,
} from "../../utils/AudioPlayerController";
import GameOver from "../game-over/game-over";
import Page from "../../pages/page";
import background from "../../assets/background_img/year3_block_background_landscape.png";
import leafOverlay from "../../assets/background_img/leaf_overlay_level3.png";

import {
  handleAddItem,
  handleBackSpace,
  setCompanionCharacter,
} from "./game-utils";

import {
  currentGameType,
  maxQuestions,
  useExplanationVideo,
  useGameController,
} from "./game-controller";
import ExplanationVideo from "../../pages/explanation-video/explanation-video";
import LillyPad from "../frog-animation-elements/lilly-pad";

export enum GameType {
  Power = "Power",
  PowerWater = "Power-Water",
  SpeedOne = "Speed1",
  SpeedOneAir = "Speed1-Air",
  SpeedTwo = "Speed2",
  SpeedTwoLand = "Speed2-Land",
}
export interface PlayerInfo {
  menuId: number;
  subMenuId: number;
}

export interface GameContainerProps {
  gameType: GameType;
  showFrog: boolean;
  answerType: string;
  playerInfo: PlayerInfo;
  gameDuration: number;
  parameterInformation: ParameterInfoType;
  exerciseAttemptNumber: number;
  isLoading?: boolean;
  isGameBegin?: boolean;
  updateGameBegin: (value: boolean) => void;
  exerciseList?: any;
  isExplanationVideo?: boolean;
}

export interface ParameterInfoType {
  learningObjectiveId: string | undefined;
  blockId: string | undefined;
  courseName: string;
  publicationId: string;
}

export interface QuestionDetailType {
  question: string;
  answer: string;
}

export interface Question {
  questionId: string;
  questionType: string;
  question: string;
  interactionId: string;
  answerType: string;
  options: any;
}

export interface QuestionList {
  exerciseAttempt: number;
  personalBest: number;
  questions: Array<Question>;
}

const GameContainer = (props: GameContainerProps) => {
  const {
    selectedAnswer,
    inputText,
    isCorrectAnswer,
    checkAnswer,
    isAnswerSubmitted,
    backgroundImage,
    companion,
    questionIndex,
    currentQuestion,
    companionProgress,
    personalBest,
    setPersonalBest,
    life,
    showMask,
    showHelperCard,
    showReward,
    setShowReward,
    showSoundPopup,
    voiceAudio,
    setVoiceAudio,
    clickedVoice,
    setClickedVoice,
    playAudio,
    initGameContainer,
    gotoNextQuestion,
    initInputField,
    fishAnimationContainer,
    spiderAnimationContainer,
    headerProgressBar,
    selectedOptionId,
    optionClick,
    speedOneTimerEnd,
    hideSoundPopup,
    setIsGameBegin,
    handleAddItemOnChange,
    helperCardURL,
    showArrowIcon,
    isGameOver,
    isGameWin,
    setPlayAudio,
    showLoader,
    playGameOverAudio,
    setCompanion,
    showExplanationVideo,
    handleShowExplanationVideo,
    boxContainerStyle,
    setStartSpeedTwoAnimation,
    setIsFrogJump,
    isFrogJump,
    isFrogJumpFromScreen,
    setIsShuffleArray,
    isGameBegin,
  } = useGameController(props);
  const { sourceLink, switchVideo } = useExplanationVideo();
  const { thresholdId } = useParams();
  const navigate = useNavigate();
  const [question, setQuestion] = useState<Question>();
  const [showTimerProgressBar, setShowTimerProgressBar] = useState(false);

  const arrowClick = useCallback(async () => {
    const input = document.getElementById(
      "answer-input-box"
    ) as HTMLInputElement;
    if (input) {
      input.removeAttribute("readOnly");
      input.disabled = false;
      input.focus();
    }
    if (currentQuestion?.questionType !== "voice") {
      playGameOverAudio(gameWinAudio, true);
    }
    setIsGameBegin(true);
    props.updateGameBegin(true);
    setPlayAudio(true);
    if (
      props.gameType === GameType.SpeedTwo ||
      props.gameType === GameType.SpeedTwoLand
    ) {
      setStartSpeedTwoAnimation(true);
      setIsShuffleArray(true);
    } else {
      setStartSpeedTwoAnimation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion?.questionType]);

  useEffect(() => {
    return () => {
      document
        .querySelectorAll("audio")
        .forEach((audioElement: HTMLAudioElement) => {
          audioElement.muted = true;
          audioElement.pause();
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsFrogJump(false);
    setQuestion(currentQuestion);
  }, [currentQuestion, setIsFrogJump]);

  useEffect(() => {
    switchVideo(props.gameType);
    props.gameType === GameType.SpeedOne ||
    props.gameType === GameType.SpeedOneAir
      ? setShowTimerProgressBar(true)
      : setShowTimerProgressBar(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.gameType]);

  useEffect(() => {
    initGameContainer(props, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.exerciseList]);

  useEffect(() => {
    initInputField(props);
    if (!props.isLoading)
      setCompanionPosition(showMask, 1, CompanionType.progress);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoading, props.isGameBegin]);

  useEffect(() => {
    if (currentGameType === GameType.SpeedOneAir) {
      setCompanion(Companions.Bird);
    } else {
      setCompanionCharacter(thresholdId, (companion) => {
        setCompanion(companion);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isGameBegin]);

  useEffect(() => {
    if (
      (questionIndex >= maxQuestions &&
        currentGameType === GameType.SpeedOne) ||
      (questionIndex >= maxQuestions &&
        currentGameType === GameType.SpeedOneAir)
    ) {
      setShowReward(true);
    }

    gotoNextQuestion(() => {
      navigate(-1);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionIndex]);

  // Removes personal best when companion surpasses
  useEffect(() => {
    if (companionProgress >= personalBest) {
      setPersonalBest(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companionProgress]);

  return (
    <>
      {showExplanationVideo ? (
        <Page
          showCharacter={true}
          childElement={
            <ExplanationVideo
              source={sourceLink}
              handleShowExplanationVideo={handleShowExplanationVideo}
            />
          }
          backgroundURL={background}
        />
      ) : (
        <>
          <div
            className="game-main-container"
            style={{
              background: `url(${backgroundImage}) no-repeat center bottom`,
            }}
          >
            <div style={{ display: `${!props.isLoading ? "block" : "none"}` }}>
              {props.gameType === GameType.SpeedTwo && (
                // Speed two Level "Fish animation"
                <div style={{ position: "relative", top: "-30px" }}>
                  {fishAnimationContainer(props)}
                </div>
              )}

              {props.gameType === GameType.SpeedTwoLand && (
                // Speed two land Level "Spider animation"
                <>
                  <div>{spiderAnimationContainer(props)}</div>
                  <div
                    className="leaf-overlay"
                    style={{
                      background: `url(${leafOverlay}) no-repeat center bottom`,
                    }}
                  ></div>
                </>
              )}

              {props.gameType === GameType.Power && (
                <>
                  <div className="power-game-background-container">
                    <img
                      src={powerLeafImage}
                      className="power-leaf-background"
                      alt="power-leaf-background"
                    ></img>
                    <AntBackground life={life}></AntBackground>
                  </div>
                </>
              )}
              {props.showFrog && (
                <>
                  <LillyPad
                    isFrogJumping={isFrogJump}
                    isFrogJumpingOutOfScreen={isFrogJumpFromScreen}
                  ></LillyPad>
                </>
              )}
              <div className="top-progress-bar">
                <>
                  {headerProgressBar(
                    props,
                    showTimerProgressBar,
                    speedOneTimerEnd
                  )}
                </>
              </div>
              <div
                className={`box-container-question-wrapper ${boxContainerStyle}`}
              >
                <BoxContainer
                  showFrog={props.showFrog}
                  jumpFrog={isFrogJump}
                  jumpFrogFromScreen={isFrogJumpFromScreen}
                  answerType={question?.answerType}
                  questionIndex={questionIndex}
                  questionType={
                    question?.questionType ? question.questionType : "text"
                  }
                  questionContent={question}
                  onInputChange={(e: number) => {
                    handleAddItemOnChange(e);
                  }}
                  onEnter={checkAnswer}
                  autoAnswerOption={selectedOptionId}
                  onOptionClick={optionClick}
                  isAnswerCorrect={isCorrectAnswer}
                  isAnswerSubmitted={isAnswerSubmitted}
                  playAudio={playAudio}
                  onQuestionChange={() => {
                    if (
                      props.isGameBegin &&
                      currentGameType === GameType.SpeedTwo
                    )
                      setStartSpeedTwoAnimation(true);
                  }}
                  life={life}
                />
              </div>
              {showTimerProgressBar && (
                <div className="bottom-progress-bar-container">
                  <ProgressBar
                    gameType={props.gameType}
                    companion={companion}
                    color={ProgressColors.Yellow}
                    progress={companionProgress}
                    position={
                      questionIndex < maxQuestions
                        ? questionIndex + 1
                        : maxQuestions
                    }
                    previousBestPosition={personalBest}
                    showMask={showMask}
                    backgroundType={
                      props.gameType === GameType.SpeedOneAir
                        ? Backgrounds.Air_Progress
                        : Backgrounds.Progress
                    }
                    showReward={showReward}
                    isGameStarted={isGameBegin}
                  ></ProgressBar>
                </div>
              )}
              {helperCardURL && (
                <HelperCard
                  showHelperCard={showHelperCard}
                  src={helperCardURL}
                ></HelperCard>
              )}
            </div>
            {showLoader(props, arrowClick)}
            <PopUpModal
              size="lg"
              dialogClassName="voice-setting"
              show={showSoundPopup}
              close={() => {
                stopAudioById("pop-up-sound-player");
                hideSoundPopup();
              }}
              centered={true}
              title={""}
            >
              <div className="sound-setting-wrapper">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                  }}
                  className="setting-button"
                >
                  <button
                    data-testid="sound-button"
                    aria-label="sound button"
                    className="btn-dark btn-logout"
                    onClick={() => {
                      const soundTestPopup = document.getElementById(
                        "pop-up-sound-player"
                      ) as HTMLAudioElement;
                      soundTestPopup.src = "";
                      soundTestPopup.src = voiceTestAudio;
                      playAudioById("pop-up-sound-player");
                      setClickedVoice(true);
                      setVoiceAudio(!voiceAudio);
                    }}
                    title=""
                  >
                    <img src={audioTestImage} alt="audiovoice" />
                  </button>
                </div>
              </div>
              <div className="ok-setting-wrapper">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  className="setting-button"
                >
                  <button
                    data-testid="sound-check-ok-button"
                    aria-label="ok button"
                    className={`${
                      !clickedVoice
                        ? "disable-button btn-logout"
                        : "btn-dark btn-logout"
                    } `}
                    disabled={clickedVoice ? false : true}
                    onClick={() => {
                      stopAudioById("pop-up-sound-player");
                      hideSoundPopup();
                    }}
                    title=""
                  >
                    OK
                  </button>
                </div>
              </div>
            </PopUpModal>
          </div>
          <AudioVoice
            data-testid="audio-voice"
            id={"pop-up-sound-player"}
            url={voiceTestAudio}
          />
          {!props.isLoading && (
            <div
              className="bottom-container-keyboard"
              data-testid="bottom-container-keyboard"
            >
              <BottomContainer
                answerText={inputText}
                addItem={(value: number) =>
                  handleAddItem(value, () => {
                    handleAddItemOnChange(value);
                  })
                }
                clearHandle={handleBackSpace}
                onAnswerSubmit={checkAnswer}
                answerType={question?.answerType}
                selectedAnswer={selectedAnswer}
                showArrowIcon={showArrowIcon}
              />
              <AudioVoice
                data-testid="game-over-audio-player"
                id={"game-over-audio-player"}
                url={gameLooseAudio}
              />
            </div>
          )}
          <GameOver
            style={{ visibility: isGameOver ? "visible" : "hidden" }}
            isGameWin={isGameWin}
            gameType={currentGameType}
          ></GameOver>
        </>
      )}
    </>
  );
};

export default GameContainer;

