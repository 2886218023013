import React, { useCallback, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { isMobileDevice } from "../../utils/mobileDevice";

type textProps = {
  id: string;
  customClass?: string;
  onChange: Function;
  onKeyPress: Function;
  onKeyUp: Function;
  childInputRef: any;
};
export const handleWheel = (event: any) => {
  event.preventDefault();
};
export default function TextInput(props: textProps) {
  useEffect(() => {
    props.childInputRef.current.addEventListener("wheel", handleWheel, {
      passive: false,
    });
    props.childInputRef.current.style.pointerEvents = isMobileDevice
      ? "none"
      : "auto";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback(
    (e: any) => {
      props.onChange(e.target);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange]
  );

  const onKeyPress = useCallback(
    (e: any) => {
      props.onKeyPress(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onKeyPress]
  );

  const onKeyUp = useCallback(
    (e: any) => {
      props.onKeyUp(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onKeyUp]
  );

  const focusInput = useCallback((e: any) => {
    e.target.focus();
  }, []);

  const onFocus = useCallback(
    (e: any) => {
      if (!e.target.readOnly) {
        e.target.readOnly = true;
        setTimeout(() => {
          e.target.readOnly = false;
        }, 10);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  return (
    <Form.Control
      id={props.id}
      ref={props.childInputRef}
      data-testid="text-input"
      inputMode="none"
      className={props.customClass}
      onChange={onChange}
      onKeyDown={onKeyPress}
      onKeyUp={onKeyUp}
      onBlur={focusInput}
      onFocus={onFocus}
      autoCorrect="off"
      autoCapitalize="off"
      autoComplete="off"
    />
  );
}
