import React, { useCallback } from "react";
import ButtonBox from "../button/button";
import okIcon from "../../assets/icons/ok-icon.svg";
import arrowIcon from "../../assets/icons/arrow-icon.svg";
export interface BottomContainerProps {
  addItem: Function;
  clearHandle: Function;
  onAnswerSubmit: Function;
  answerType?: string;
  answerText?: number | null;
  selectedAnswer?: boolean;
  showArrowIcon: boolean;
}

export default function BottomContainer(props: BottomContainerProps) {
  const array = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  const addItem = useCallback(
    (item: any) => (e: any) => {
      const textbox = document.getElementById(
        "answer-input-box"
      ) as HTMLInputElement;

      if (textbox) {
        textbox.focus();
        if (textbox.getAttribute("readOnly") === null) props.addItem(item);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.addItem]
  );
  const clearHandle = useCallback(
    (e: any) => {
      const textbox = document.getElementById("answer-input-box");
      if (textbox) {
        textbox.focus();
      }
      props.clearHandle(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.clearHandle]
  );

  const onAnswerSubmit = useCallback(
    (e: any) => {
      props.onAnswerSubmit(e);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onAnswerSubmit]
  );

  return (
    <div
      data-testid="keypad-container"
      className={`keypad-container ${
        props.answerType === "TEXT_ENTRY" ? "" : "ok-button-container"
      }`}
    >
      {props.answerType === "TEXT_ENTRY" && (
        <div className="keyboard-button">
          {array.map((item, index) => {
            return (
              <div key={item}>
                <ButtonBox
                  id={`key-${item}`}
                  testid={`key-${item}`}
                  onClick={addItem(item)}
                  customClass={"key-button"}
                >
                  {item}
                </ButtonBox>
              </div>
            );
          })}
          <div>
            <ButtonBox
              id="clear-button"
              testid="clear-button"
              customClass={"key-button backspace-key"}
              onClick={clearHandle}
            />
          </div>
        </div>
      )}
      <div style={{ display: "flex", marginRight: "10px" }}>
        <ButtonBox
          id={"ok-button"}
          testid="ok-button"
          customClass={`${
            !props.answerText && !props.selectedAnswer && "disable-button"
          } ok-button`}
          onClick={onAnswerSubmit}
        >
          <img src={props.showArrowIcon ? arrowIcon : okIcon} alt="ok-button" />
        </ButtonBox>
      </div>
    </div>
  );
}
