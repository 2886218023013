import { BlockSubMenuProps } from "../assets/Block1BlockMenu";
import { elementReady } from "./CheckElement";

type ArrayType = { id: string };

const LevelTypes = ["Power", "Speed1", "Speed2"];

export function setSubMenuBlocks(
  repeatIcons: ArrayType[][],
  grayedBackgrounds: ArrayType[][],
  playButtons: ArrayType[][],
  subMenuData: Array<{
    subMenuId: number;
    levels: Array<{ levelTitle: string; completeStatus: boolean }>;
    completeStatus: boolean;
  }>,
  subMenuManifestData: any
) {
  if (subMenuData) {
    // Get active submenu data if available.
    const activeSubMenuData = subMenuData.find(
      (submenu) =>
        !submenu.completeStatus &&
        submenu.levels.find((level) => level.completeStatus === true)
    );

    setSubMenuList(
      subMenuData,
      playButtons,
      repeatIcons,
      grayedBackgrounds,
      activeSubMenuData,
      subMenuManifestData
    );
  }
}

export const setSubMenuList = async (
  subMenuData: any,
  playButtons: any,
  repeatIcons: any,
  grayedBackgrounds: any,
  activeSubMenuData: any,
  subMenuManifestData: any
) => {
  const maxSubMenu = playButtons.length;
  subMenuData.forEach((submenu: any, i: number) => {
    // make sure the submenu data doesnot exceed more than expected submenu length
    if (i >= maxSubMenu) return;

    const { subMenuId } = submenu;
    const subMenuIndex = subMenuId - 1;

    // Checks if all the levels of this current submenu are inactive (not played yet).
    const isSubMenuLevelsInactive =
      submenu.levels.find((level: any) => level.completeStatus === false) &&
      !submenu.completeStatus;

    // Gets the first incomplete level from this current submenu.
    const firstIncompleteLevel = submenu.levels.find(
      (level: any) => level.completeStatus === false
    );

    submenu.levels.forEach(async (level: any, index: number) => {
      const { levelTitle } = level;
      const levelIndex = LevelTypes.indexOf(levelTitle);

      const playIconSubMenuData = playButtons[subMenuIndex];
      const playIcon = playIconSubMenuData[levelIndex];

      let playIconElement_: HTMLElement;
      await elementReady(playIcon.id).then((resolve) => {
        playIconElement_ = resolve as HTMLElement;

        // Set the properties "subMenuId" and "levelId" to be used later for navigation.
        setAttribute(
          playIconElement_,
          subMenuManifestData,
          subMenuIndex,
          levelIndex,
          index
        );

        showRepeatIconAndGrayed({
          level,
          playIconElement: playIconElement_,
          isSubMenuLevelsInactive,
          activeSubMenuData,
          firstIncompleteLevel,
          levelTitle,
          submenu,
        });
      });

      const repeatIconSubMenuData = repeatIcons[subMenuIndex];
      const repeatIcon = repeatIconSubMenuData
        ? repeatIconSubMenuData[levelIndex]
        : undefined;

      let repeatIconElement_: HTMLElement;
      await elementReady(repeatIcon.id).then((resolve) => {
        repeatIconElement_ = resolve as HTMLElement;

        setAttribute(
          repeatIconElement_,
          subMenuManifestData,
          subMenuIndex,
          levelIndex,
          index
        );

        showRepeatIconAndGrayed({
          level,
          repeatIconElement: repeatIconElement_,
          isSubMenuLevelsInactive,
          activeSubMenuData,
          firstIncompleteLevel,
          levelTitle,
          submenu,
        });
      });

      const grayedBackgroundSubMenuData = grayedBackgrounds[subMenuIndex];
      const grayedBackground = grayedBackgroundSubMenuData
        ? grayedBackgroundSubMenuData[levelIndex]
        : undefined;

      await elementReady(grayedBackground.id).then((resolve) => {
        const grayedBackgroundElement = resolve as HTMLElement;

        showRepeatIconAndGrayed({
          level,
          grayedBackgroundElement,
          isSubMenuLevelsInactive,
          activeSubMenuData,
          firstIncompleteLevel,
          levelTitle,
          submenu,
        });
      });
    });
  });
};

export const checkCurrentLevel = (
  isSubMenuLevelsInactive: any,
  activeSubMenuData: any,
  firstIncompleteLevel: any,
  levelTitle: any,
  submenu: any,
  playIconElement?: HTMLElement
) => {
  const isFirstIncompleteLevelMatched =
    firstIncompleteLevel && firstIncompleteLevel.levelTitle === levelTitle;
  if (playIconElement) {
    // By default play icon is invisible.
    playIconElement.style.display = "none";

    // Play icon is visible only in two conditions:
    // 1. If the active submenu data is found. Active submenu has ongoing levels left to be played.
    // 2. When there's no any active submenu, the incomplete submenus with the first incomplete level is eligible for the play button.
    const showPlayIcon =
      (activeSubMenuData &&
        activeSubMenuData.subMenuId === submenu.subMenuId &&
        isFirstIncompleteLevelMatched) ||
      (activeSubMenuData &&
        isSubMenuLevelsInactive &&
        isFirstIncompleteLevelMatched) ||
      (!activeSubMenuData &&
        isSubMenuLevelsInactive &&
        isFirstIncompleteLevelMatched);
    if (showPlayIcon) playIconElement.style.display = "inline";
  }
};
export const setAttribute = (
  icon: HTMLElement,
  subMenuManifestData: any,
  subMenuIndex: any,
  levelIndex: any,
  elementIndex: number
) => {
  // Sets attributes to submenu elements when subMenuIndex is less than subMenuManifestData
  if (icon && subMenuIndex < subMenuManifestData.length) {
    icon.style.pointerEvents = "auto";
    icon.setAttribute("subMenuId", String(subMenuIndex + 1));
    icon.setAttribute(
      "manifestSubMenuId",
      subMenuManifestData[subMenuIndex].id
    );
    icon.setAttribute("data-index", String(elementIndex));
    icon.setAttribute(
      "levelId",
      subMenuManifestData[subMenuIndex].children[levelIndex].id
    );
  } else {
    icon.style.pointerEvents = "none";
  }
};
export const showRepeatIconAndGrayed = (props: {
  level: any;
  repeatIconElement?: any;
  grayedBackgroundElement?: any;
  playIconElement?: any;
  isSubMenuLevelsInactive: any;
  activeSubMenuData: any;
  firstIncompleteLevel: any;
  levelTitle: any;
  submenu: any;
}) => {
  if (props.level.completeStatus) {
    if (props.repeatIconElement) {
      props.repeatIconElement.style.display = "inline";
      props.repeatIconElement.style.pointerEvents = "auto";
    }

    if (props.grayedBackgroundElement) {
      props.grayedBackgroundElement.style.display = "none";
    }

    if (props.playIconElement) {
      props.playIconElement.style.display = "none";
    }
  } else {
    if (props.repeatIconElement) {
      props.repeatIconElement.style.display = "none";
      props.repeatIconElement.style.removeProperty("pointer-events");
    }

    if (props.grayedBackgroundElement) {
      props.grayedBackgroundElement.style.display = "inline";
    }

    // Play Icons //
    // If the level complete status is false, there's a possibility that this particular level is eligible for playing.

    // Checks if the current level matches the first incomplete level of the submenu.
    checkCurrentLevel(
      props.isSubMenuLevelsInactive,
      props.activeSubMenuData,
      props.firstIncompleteLevel,
      props.levelTitle,
      props.submenu,
      props.playIconElement
    );
  }
};

export const setLoadingDataComplete = (
  props: BlockSubMenuProps,
  callback: Function,
  menuId?: string,
  subMenuManifestData?: any
) => {
  if (
    menuId &&
    subMenuManifestData &&
    subMenuManifestData.length !== 0 &&
    props.subMenuData
  ) {
    if (callback) callback();
  }
};
export const setSubMenuManifestDataByMenuId = (
  manifestData: any,
  callback: Function,
  menuId?: string
) => {
  if (manifestData) {
    const menuManifestData = manifestData.find((data: any) => {
      return data.number === Number(menuId);
    });
    if (menuManifestData && callback) callback(menuManifestData.children);
  }
};

export const setExplainationVideoPlayedStatus = (
  submenuId: any,
  buttonIndex: any,
  explanationVideoPlayedStatus: any
) => {
  let isExplanationVideoPlayed;
  let levelTitle;
  if (
    explanationVideoPlayedStatus[Number(submenuId) - 1] &&
    explanationVideoPlayedStatus[Number(submenuId) - 1].levels
  ) {
    isExplanationVideoPlayed =
      explanationVideoPlayedStatus[Number(submenuId) - 1].levels[
        Number(buttonIndex)
      ].explanationVideoDisplayedStatus;
    levelTitle =
      explanationVideoPlayedStatus[Number(submenuId) - 1].levels[
        Number(buttonIndex)
      ].levelTitle;
  }
  return { isExplainationVideoPlayed: isExplanationVideoPlayed, levelTitle };
};

export const addClickEventOnStone = (id: string, callback: Function) => {
  elementReady(id).then((resolve) => {
    const stone = resolve as HTMLElement;
    if (stone) {
      stone.style.pointerEvents = "auto";
      stone.addEventListener("click", () => {
        callback();
      });
    }
  });
};

export const toggleBlockHelp = async (id: string) => {
  elementReady(id).then((resolve) => {
    const blockOneHelp = resolve as HTMLElement;
    blockOneHelp.style.display =
      blockOneHelp.style.display === "none" ? "block" : "none";
  });
};
