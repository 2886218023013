import { useAppDispatch } from "../../redux/hooks";
import { navbarSlice } from "../../redux/navbar/navbar.slice";

const SettingOverlay = () => {
  const dispatch = useAppDispatch();

  return (
    <div
      data-testid={"setting-overlay"}
      onClick={() => dispatch(navbarSlice.actions.openPopOut())}
      className="setting-overlay"
    ></div>
  );
};
export default SettingOverlay;
