import React, { useState, useEffect } from "react";
export interface SvgGraphicProps {
  id?: string;
  src: string;
  onLoad?: Function;
  className?: string;
  onAnimationEnd?: (event: React.AnimationEvent) => void;
  style?: object;
}

const SvgGraphic = (props: SvgGraphicProps) => {
  const [svgState, setSvgState] = useState("");

  useEffect(() => {
    setSVG();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setSVG() {
    fetch(props.src)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setSvgState(text);
        if (props.onLoad) {
          props.onLoad();
        }
      });
  }

  return (
    <div
      data-testid={`test-${props.id}`}
      className={`${props.className ? props.className : ""} svg-graphic`}
      id={props.id}
      dangerouslySetInnerHTML={{ __html: svgState }}
      onAnimationEnd={(event) => {
        if (props.onAnimationEnd) props.onAnimationEnd(event);
      }}
      style={props.style}
    />
  );
};

export default SvgGraphic;
