import { createSlice } from "@reduxjs/toolkit";
import { UserDetail } from "./auth.type";
import type { RootState } from "../store/store";

const initialState: UserDetail = {
  userDetail: {
    memberId: null,
    fullName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    year: "",
    schoolId: null,
    schoolName: "",
    groups: [
      {
        groupId: null,
        name: "",
      },
    ],
  },
};
export const authInfoSlice = createSlice({
  name: "authInfo",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    authInfo: (state, action) => {
      state.userDetail = action.payload;
    },
    resetAuth: (state) => {
      state.userDetail = {
        memberId: null,
        fullName: "",
        firstName: "",
        middleName: "",
        lastName: "",
        year: "",
        schoolId: null,
        schoolName: "",
        groups: [
          {
            groupId: null,
            name: "",
          },
        ],
      };
    },
  },
});

export const { authInfo, resetAuth } = authInfoSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.authInfo.userDetail;

export default authInfoSlice.reducer;
