type helperCardProps = {
  showHelperCard: boolean;
  src: string;
};

const HelperCard = (props: helperCardProps) => {
  return (
    <div
      data-testid="helper-card"
      style={{
        display: props.showHelperCard ? "flex" : "none",
        justifyContent: "center",
        position: "absolute",
        bottom: "-50px",
        left: "50%",
      }}
      className={`${
        props.showHelperCard ? "show-helper-card" : ""
      } helper-card-position`}
    >
      <img
        draggable="false"
        alt="helpercard"
        className="helper-card-img"
        src={props.src}
        width="auto"
      />
    </div>
  );
};

export default HelperCard;
