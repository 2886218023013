import arrow from "../../assets/icons/beginarrow.svg";

type ExplanationVideoType = {
  source: string;
  handleShowExplanationVideo: Function;
};

const ExplanationVideo = (props: ExplanationVideoType) => {
  return (
    <div style={{ paddingTop: "5rem", paddingBottom: "5rem" }}>
      <div className="video-button-container container">
        <div className="video-container">
          <iframe
            title="level explanation"
            src={props.source}
            width="100%"
            height="400px"
          ></iframe>
        </div>
        <div className="d-flex justify-content-end" style={{ width: "600px" }}>
          <div
            data-testid="explanation-video-container"
            className="button-container"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.handleShowExplanationVideo();
            }}
          >
            <div data-testid="skip-explanation" className="skip-arrow">
              <img
                draggable="false"
                ata-testid="skip-animation"
                src={arrow}
                alt="Start"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExplanationVideo;
