import { useCallback, useEffect, useState } from "react";
import fishNetBackgroundImage from "../../assets/fish-animation/fish-net.svg";
import fishNetForegroundImage from "../../assets/fish-animation/fish-foreground.svg";
import greenFish from "../../assets/fish-animation/green-fish.svg";
import redFish from "../../assets/fish-animation/red-fish.svg";
import SvgGraphic from "../../utils/SvgGraphic";

export enum SpeedTwoCharacter {
  RED = "red",
  GREEN = "green",
}

type FishAnimationProps = {
  timerDurationInMilliSeconds: number;
  startAnimation: boolean;
  isAnswerSubmitted: boolean;
  isCorrectAnswer: boolean;
  pauseAnimation?: boolean;
  onTimerStart?: Function;
  onTimerEnd?: Function;
  onAnimationEnd?: Function;
  fishColor: SpeedTwoCharacter;
};

export const useFishAnimation = (props: FishAnimationProps) => {
  const [fishImage, setFishImage] = useState(true);
  const fishNetForeground = document.getElementById("fish-net-foreground");
  const fishNetBackground = document.getElementById("fish-net-background");
  const fishGreen = document.getElementById("fish-container-green");
  const fishRed = document.getElementById("fish-container-red");
  const onFishAnimationComplete = (
    animationName: string,
    target: HTMLElement
  ) => {
    // Start fish swimming animation
    if (animationName === "fish-appear-animation-keyframes") {
      target.classList.add("fish-swim-animation");
      if (props.onTimerStart) props.onTimerStart();
    }
  };

  const onFishNetAnimationComplete = (
    animationName: string,
    target: HTMLElement
  ) => {
    if (animationName === "fish-net-appear-animation-keyframes") {
      // Move fish net towards fish.
      target.classList.remove("fish-net-appear-animation-keyframes");
      target.classList.add("move-net-to-fish");
    } else if (
      animationName === "move-net-to-fish-keyframes" ||
      animationName === "incorrect-animation-keyframes"
    ) {
      if (props.onTimerEnd && target.id === "fish-net-foreground")
        props.onTimerEnd();
      // Catch fish animation.
      fishGreen?.classList.remove("fish-swim-animation");
      fishGreen?.classList.add("take-fish-animation");
      fishRed?.classList.remove("fish-swim-animation");
      fishRed?.classList.add("take-fish-animation");
      target.classList.remove("catch-fish-animation");
      target.classList.add("take-fish-animation");
    } else if (
      animationName === "take-fish-animation-keyframes" ||
      animationName === "correct-fish-net-animation-keyframes" ||
      animationName === "take-fish-animation"
    ) {
      if (props.onAnimationEnd && target.id === "fish-net-foreground")
        props.onAnimationEnd();
      resetStyle();
    }
  };

  const resetStyle = () => {
    // Reset class
    fishNetForeground?.classList.remove(...fishNetForeground.classList);
    fishNetBackground?.classList.remove(...fishNetBackground.classList);
    fishRed?.classList.remove(...fishRed.classList);
    fishGreen?.classList.remove(...fishGreen.classList);

    if (fishNetForeground && fishNetBackground && fishGreen && fishRed) {
      fishNetForeground.style.left = "100%";
      fishNetBackground.style.left = "100%";
      fishGreen.style.left = "-16%";
      fishRed.style.left = "-16%";
    }
    //Add default class
    fishNetForeground?.classList.add("fish-animation-element");
    fishNetBackground?.classList.add("fish-animation-element");
    fishGreen?.classList.add("fish-animation-element");
    fishRed?.classList.add("fish-animation-element");
  };

  const startAnimation = () => {
    resetStyle();
    // Add animation class
    fishNetForeground?.classList.add("fish-net-appear-animation");
    fishNetBackground?.classList.add("fish-net-appear-animation");
    fishGreen?.classList.add("fish-appear-animation");
    fishRed?.classList.add("fish-appear-animation");
  };

  const pauseAnimation = () => {
    if (fishNetForeground && fishNetBackground && fishRed && fishGreen) {
      // Pause current animation.
      fishNetForeground.style.animationPlayState = "paused";
      fishNetBackground.style.animationPlayState = "paused";
      fishRed.style.animationPlayState = "paused";
      fishGreen.style.animationPlayState = "paused";
    }
  };

  const playAnimationOnAnswer = (isAnswerCorrect: boolean) => {
    if (fishNetForeground && fishNetBackground && fishRed && fishGreen) {
      let leftPosition;
      // Remove current animation.
      const parentWidth = fishNetForeground?.parentElement?.clientWidth;
      if (parentWidth)
        leftPosition = (fishNetForeground.offsetLeft / parentWidth) * 100;
      fishNetForeground?.classList.remove("fish-net-appear-animation");
      fishNetBackground?.classList.remove("fish-net-appear-animation");
      fishRed?.classList.remove("fish-appear-animation");
      fishGreen?.classList.remove("fish-appear-animation");
      fishNetForeground?.classList.remove("move-net-to-fish");
      fishNetBackground?.classList.remove("move-net-to-fish");
      fishRed.classList.remove("fish-swim-animation");
      fishGreen.classList.remove("fish-swim-animation");
      document.documentElement.style.setProperty(
        "--move-from",
        `${leftPosition}%`
      );
      if (isAnswerCorrect) {
        fishNetForeground.classList.add("correct-fish-net-animation");
        fishNetBackground.classList.add("correct-fish-net-animation");
        fishRed.classList.add("correct-fish-animation");
        fishGreen.classList.add("correct-fish-animation");
      } else {
        // Catch fish animation
        fishRed.style.left = "16%";
        fishGreen.style.left = "16%";
        fishNetForeground.style.left = `${leftPosition}%`;
        fishNetBackground.style.left = `${leftPosition}%`;
        fishNetForeground?.classList.add("incorrect-animation");
        fishNetBackground?.classList.add("incorrect-animation");
      }
      fishNetForeground.style.animationPlayState = "running";
      fishNetBackground.style.animationPlayState = "running";
      fishRed.style.animationPlayState = "running";
      fishGreen.style.animationPlayState = "running";
    }
  };

  return {
    onFishAnimationComplete,
    onFishNetAnimationComplete,
    resetStyle,
    startAnimation,
    playAnimationOnAnswer,
    fishImage,
    setFishImage,
    pauseAnimation,
  };
};
function FishAnimation(props: FishAnimationProps) {
  const {
    onFishAnimationComplete,
    onFishNetAnimationComplete,
    startAnimation,
    playAnimationOnAnswer,
    fishImage,
    setFishImage,
    pauseAnimation,
  } = useFishAnimation(props);

  const fishNetAnimationComplete = useCallback(
    (event: React.AnimationEvent) => {
      if (!props.pauseAnimation) {
        onFishNetAnimationComplete(
          event.animationName,
          event.currentTarget as HTMLElement
        );
      }
    },

    [onFishNetAnimationComplete, props.pauseAnimation]
  );

  const fishAnimationComplete = useCallback(
    (event: React.AnimationEvent) => {
      onFishAnimationComplete(
        event.animationName,
        event.currentTarget as HTMLElement
      );
    },
    [onFishAnimationComplete]
  );

  useEffect(() => {
    pauseAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pauseAnimation]);

  useEffect(() => {
    if (props.isAnswerSubmitted) {
      playAnimationOnAnswer(props.isCorrectAnswer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCorrectAnswer, props.isAnswerSubmitted]);

  useEffect(() => {
    if (props.startAnimation) {
      document.documentElement.style.setProperty("--move-from", "64%");
      document.documentElement.style.setProperty(
        "--fish-catch-animation-duration",
        `${props.timerDurationInMilliSeconds / 20 / 1000}s`
      );
      startAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startAnimation]);

  useEffect(() => {
    if (!props.isCorrectAnswer && props.startAnimation) {
      if (props.fishColor === SpeedTwoCharacter.GREEN) {
        setFishImage(false);
      } else {
        setFishImage(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fishColor, props.isCorrectAnswer, props.startAnimation]);

  return (
    <>
      <SvgGraphic
        id={"fish-net-foreground"}
        className={"fish-animation-element"}
        src={fishNetForegroundImage}
        onAnimationEnd={fishNetAnimationComplete}
      />
      <SvgGraphic
        id={"fish-container-red"}
        className={"fish-animation-element"}
        src={redFish}
        style={{ visibility: !fishImage ? "hidden" : "visible" }}
        onAnimationEnd={fishAnimationComplete}
      />
      <SvgGraphic
        id={"fish-container-green"}
        className={"fish-animation-element"}
        src={greenFish}
        style={{ visibility: !fishImage ? "visible" : "hidden" }}
        onAnimationEnd={fishAnimationComplete}
      />
      <SvgGraphic
        id={"fish-net-background"}
        className={"fish-animation-element"}
        src={fishNetBackgroundImage}
        onAnimationEnd={fishNetAnimationComplete}
      />
    </>
  );
}

export default FishAnimation;

