import React, { useEffect, useState } from "react";
import { GameType } from "../../../component/game-container/game-container";
import { useLocation, useParams } from "react-router-dom";
import Level from "../level";

type SpeedOneProps = {
  handleFullScreen: Function;
};
type manifestInfo = {
  manifestSubMenuId?: string;
  manifestMenuId?: string;
};

export default function SpeedOne(props: SpeedOneProps) {
  const { menuUID, subMenuId, levelId } = useParams();
  let location = useLocation();
  const [manifestInformation, setManifestInformation] = useState<manifestInfo>(
    {}
  );
  const playerInfo = {
    menuId: Number(menuUID),
    subMenuId: Number(subMenuId),
  };
  useEffect(() => {
    if (location.state) setManifestInformation(location.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manifestInformation]);
  return (
    <>
      <Level
        handleFullScreen={props.handleFullScreen}
        gameType={GameType.SpeedOne}
        showFrog={false}
        exerciseId={levelId}
        playerInfo={playerInfo}
        parameterInformation={manifestInformation}
      ></Level>
    </>
  );
}
