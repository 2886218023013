export interface AnswerBoxProps {
  id: string;
  answerItem: string | number;
  index: number;
  answerStyle?: string;
  onClick: Function;
}

export default function AnswerBox(props: AnswerBoxProps) {
  return (
    <div
      data-testid={`answer-button${props.index}`}
      id={props.id}
      className={`${
        props.answerStyle ? props.answerStyle : ""
      } answer-box-container`}
      key={props.index}
      onClick={() => {
        props.onClick();
      }}
    >
      {props.answerItem}
    </div>
  );
}
