import React from "react";
import { Modal } from "react-bootstrap";

type sizeType = "lg" | "sm" | "xl";

export interface ModalProps {
  show: boolean;
  close: Function;
  centered: boolean;
  title: string;
  children: React.ReactNode;
  dialogClassName: string;
  size?: sizeType;
  muteAudio?: Function;
}

const PopUpModal = (props: ModalProps) => {
  const handleOnClose = () => {
    props.close();
  };
  return (
    <Modal
      size={props.size}
      show={props.show}
      onHide={() => {
        handleOnClose();
      }}
      centered={props.centered}
      dialogClassName={props.dialogClassName ? props.dialogClassName : ""}
    >
      <Modal.Header>
        <div
          data-testid="cross-button"
          className="cross-button"
          onClick={() => {
            handleOnClose();
            props.muteAudio && props.muteAudio();
          }}
        >
          X
        </div>
        <Modal.Title data-testid="dialog-title">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-style">{props.children}</Modal.Body>
    </Modal>
  );
};
export default PopUpModal;
