import React, { useEffect, useState } from "react";
import Background from "./block7-background.svg";
import Complete from "./block7-complete.svg";
import FocusGrayedOut from "./block7-focus-grayed-out.svg";
import Focus from "./block7-focus.svg";
import Foreground from "./block7-foreground.svg";
import HelpBlock from "./block7-help-block.svg";

import SvgGraphic from "../../utils/SvgGraphic";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import {
  addClickEventOnStone,
  setExplainationVideoPlayedStatus,
  setLoadingDataComplete,
  setSubMenuBlocks,
  setSubMenuManifestDataByMenuId,
  toggleBlockHelp,
} from "../../utils/SubmenuController";
import { BlockSubMenuProps } from "../Block1BlockMenu";
import { levelURLNames } from "../../component/constant";
import { elementReady } from "../../utils/CheckElement";

type urlParams = {
  state: {
    path?: string;
    levelTitle?: string;
    manifestSubMenuId?: string;
    manifestMenuId?: string;
  };
};
let navigate: NavigateFunction | ((arg0: string, params?: urlParams) => void);

const blockSevenRepeatIcons = [
  [
    { id: "repeat_btn_7_1_1" },
    { id: "repeat_btn_7_1_2" },
    { id: "repeat_btn_7_1_3" },
  ],
  [
    { id: "repeat_btn_7_2_1" },
    { id: "repeat_btn_7_2_2" },
    { id: "repeat_btn_7_2_3" },
  ],
  [
    { id: "repeat_btn_7_3_1" },
    { id: "repeat_btn_7_3_2" },
    { id: "repeat_btn_7_3_3" },
  ],
  [
    { id: "repeat_btn_7_4_1" },
    { id: "repeat_btn_7_4_2" },
    { id: "repeat_btn_7_4_3" },
  ],
];

const blockSevengrayedBackgrounds = [
  [
    { id: "grayedout_b7_1_1" },
    { id: "grayedout_b7_1_2" },
    { id: "grayedout_b7_1_3" },
  ],
  [
    { id: "grayedout_b7_2_1" },
    { id: "grayedout_b7_2_2" },
    { id: "grayedout_b7_2_3" },
  ],
  [
    { id: "grayedout_b7_3_1" },
    { id: "grayedout_b7_3_2" },
    { id: "grayedout_b7_3_3" },
  ],
  [
    { id: "grayedout_b7_4_1" },
    { id: "grayedout_b7_4_2" },
    { id: "grayedout_b7_4_3" },
  ],
];
const blockSevenPlayButtons = [
  [
    { id: "play_btn_7_1_1" },
    { id: "play_btn_7_1_2" },
    { id: "play_btn_7_1_3" },
  ],
  [
    { id: "play_btn_7_2_1" },
    { id: "play_btn_7_2_2" },
    { id: "play_btn_7_2_3" },
  ],
  [
    { id: "play_btn_7_3_1" },
    { id: "play_btn_7_3_2" },
    { id: "play_btn_7_3_3" },
  ],
  [
    { id: "play_btn_7_4_1" },
    { id: "play_btn_7_4_2" },
    { id: "play_btn_7_4_3" },
  ],
];

export const useBlock7BlockMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [subMenuManifestData, setSubMenuManifestData] = useState([]);
  const [blockSevenLoadCounter, setBlockSevenLoadCounter] = useState(0);
  const [blockSevenIsLoading, setBlockSevenIsLoading] = useState(true);
  return {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    blockSevenLoadCounter,
    setBlockSevenLoadCounter,
    blockSevenIsLoading,
    setBlockSevenIsLoading,
  };
};

export const setBlockSevenLevelRepeatIcons = (
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  blockSevenRepeatIcons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockSevenLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
  blockSevenPlayButtons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockSevenLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
  return "gray seven block loaded";
};

export const addClickEventOnBlockSevenLevels = (
  clickableElement: HTMLElement | null,
  index: number,
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  if (clickableElement) {
    clickableElement.addEventListener("click", () => {
      let manifestSubMenuId =
        clickableElement.getAttribute("manifestSubMenuId");
      const blockSevenSubmenuId = clickableElement.getAttribute("subMenuId");
      const blockSevenLevelId = clickableElement.getAttribute("levelId");

      const blockSevenButtonIndex = clickableElement.getAttribute("data-index");

      const isExplainationVideoPlayedList = setExplainationVideoPlayedStatus(
        blockSevenSubmenuId,
        blockSevenButtonIndex,
        explainationVideoPlayedStatus
      );

      navigate(
        `/b7/menu/${menuId}/submenu/${blockSevenSubmenuId}/${levelURLNames[index]}/${blockSevenLevelId}`,
        {
          state: {
            manifestSubMenuId: `${manifestSubMenuId}`,
            manifestMenuId: manifestBlockId,
            isExplainationVideo:
              isExplainationVideoPlayedList.isExplainationVideoPlayed
                ? false
                : true,
            levelTitle: isExplainationVideoPlayedList.levelTitle,
          },
        }
      );
    });

    clickableElement.style.cursor = "pointer";
  }
};
export const useSvgImageBlockSevenLoaded = (
  props: BlockSubMenuProps,
  menuId: any,
  loadCounter: number
) => {
  const {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    setBlockSevenLoadCounter,
  } = useBlock7BlockMenu();

  const onSvgGraphicBlockSevenLoaded = () => {
    loadCounter = loadCounter + 1;
    setBlockSevenLoadCounter(loadCounter);
    if (loadCounter === 6) {
      setIsVisible(true);
      setBlockSevenLevelRepeatIcons(
        menuId || "",
        props.subMenuData,
        props.manifestBlockId
      );
      addClickEventOnStone("doel_knop_drempel_2_", () => {
        toggleBlockHelp("block_help_7");
      });

      setSubMenuBlocks(
        blockSevenRepeatIcons,
        blockSevengrayedBackgrounds,
        blockSevenPlayButtons,
        props.subMenuData,
        subMenuManifestData
      );

      if (props.onLoad) props.onLoad();
    }
  };
  return {
    isVisible,
    setIsVisible,
    onSvgGraphicBlockSevenLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  };
};
const BlockSevenSubMenu = (props: BlockSubMenuProps) => {
  navigate = useNavigate();
  const { menuId } = useParams();

  const {
    blockSevenLoadCounter,
    setBlockSevenLoadCounter,
    blockSevenIsLoading,
    setBlockSevenIsLoading,
  } = useBlock7BlockMenu();

  const {
    isVisible,
    onSvgGraphicBlockSevenLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  } = useSvgImageBlockSevenLoaded(props, menuId, blockSevenLoadCounter);
  const manifestData = useAppSelector(
    (state: any) => state.manifest.manifestData
  );

  useEffect(() => {
    setBlockSevenLoadCounter(0);
    setBlockSevenIsLoading(true);
    setSubMenuManifestDataByMenuId(
      manifestData?.children,
      (data: any) => {
        setSubMenuManifestData(data);
      },
      menuId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setLoadingDataComplete(
      props,
      () => {
        setBlockSevenIsLoading(false);
      },
      menuId,
      subMenuManifestData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId, subMenuManifestData, props.subMenuData]);

  return (
    <>
      {!blockSevenIsLoading && (
        <div style={{ visibility: isVisible ? "visible" : "hidden" }}>
          <SvgGraphic src={Background} onLoad={onSvgGraphicBlockSevenLoaded} />
          <SvgGraphic
            src={FocusGrayedOut}
            onLoad={onSvgGraphicBlockSevenLoaded}
          />
          <SvgGraphic src={Focus} onLoad={onSvgGraphicBlockSevenLoaded} />
          <SvgGraphic src={Complete} onLoad={onSvgGraphicBlockSevenLoaded} />
          <SvgGraphic
            src={HelpBlock}
            style={{ display: "none" }}
            id={"block_help_7"}
            onLoad={onSvgGraphicBlockSevenLoaded}
          />
          <SvgGraphic src={Foreground} onLoad={onSvgGraphicBlockSevenLoaded} />
        </div>
      )}
    </>
  );
};
export default BlockSevenSubMenu;
