import greenFrog from "../../assets/frog/green-frog.svg";
import pinkFrog from "../../assets/frog/pink-frog.svg";

type FrogProps = {
  life: number;
};

export const changeFrog = (life: number) => {
  switch (life) {
    case 2:
      return greenFrog;
    case 1:
      return pinkFrog;
    case 0:
    default:
      return greenFrog;
  }
};

function Frog(props: FrogProps) {
  return (
    <>
      {changeFrog(props.life) && (
        <img
          draggable="false"
          src={changeFrog(props.life)}
          alt="frog"
          className="frog"
        />
      )}
    </>
  );
}

export default Frog;
