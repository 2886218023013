import React, { useEffect, useState } from "react";
import Background from "./block9-background.svg";
import Complete from "./block9-complete.svg";
import FocusGrayedOut from "./block9-focus-grayed-out.svg";
import Focus from "./block9-focus.svg";
import Foreground from "./block9-foreground.svg";
import HelpBlock from "./block9-help-block.svg";

import SvgGraphic from "../../utils/SvgGraphic";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import {
  setExplainationVideoPlayedStatus,
  setLoadingDataComplete,
  setSubMenuBlocks,
  addClickEventOnStone,
  setSubMenuManifestDataByMenuId,
  toggleBlockHelp,
} from "../../utils/SubmenuController";
import { useAppSelector } from "../../redux/hooks";
import { BlockSubMenuProps } from "../Block1BlockMenu";
import { elementReady } from "../../utils/CheckElement";
import { blockNineRepeatIcons } from "../../utils/svgIdList";
import { levelYearFourURLNames } from "../../component/constant";

type urlParams = {
  state: {
    path?: string;
    levelTitle?: string;
    manifestSubMenuId?: string;
    manifestMenuId?: string;
  };
};
let navigate: NavigateFunction | ((arg0: string, params?: urlParams) => void);

const blockNineGrayedBackgrounds: { id: string }[][] = [];

for (let i = 1; i <= 6; i++) {
  const subArray = [];

  for (let j = 1; j <= 3; j++) {
    const id = `grayedout_b9_${i}_${j}`;

    subArray.push({ id });
  }

  blockNineGrayedBackgrounds.push(subArray);
}

const blockNinePlayButtons: { id: string }[][] = [];

for (let i = 1; i <= 6; i++) {
  const subPlayButtonArray = [];

  for (let j = 1; j <= 3; j++) {
    const id = `play_btn_9_${i}_${j}`;

    subPlayButtonArray.push({ id });
  }

  blockNinePlayButtons.push(subPlayButtonArray);
}

export const useBlock9BlockMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [subMenuManifestData, setSubMenuManifestData] = useState([]);
  const [blockNineLoadCounter, setBlockNineLoadCounter] = useState(0);
  const [blockNineIsLoading, setBlockNineIsLoading] = useState(true);
  return {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    blockNineLoadCounter,
    setBlockNineLoadCounter,
    blockNineIsLoading,
    setBlockNineIsLoading,
  };
};

export const setBlockNineLevelRepeatIcons = (
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string,
  subMenuManifestData: any
) => {
  blockNineRepeatIcons.forEach((levels, levelIndex) => {
    if (levelIndex < subMenuManifestData.length) {
      levels.forEach(async (level, index) => {
        await elementReady(level.id).then((resolve) => {
          const element = resolve as HTMLElement;
          addClickEventOnBlockNineLevels(
            element,
            index,
            menuId,
            explainationVideoPlayedStatus,
            manifestBlockId
          );
        });
      });
    }
  });
  blockNinePlayButtons.forEach((levels) => {
    levels.forEach(async (level, index) => {
      await elementReady(level.id).then((resolve) => {
        const element = resolve as HTMLElement;
        addClickEventOnBlockNineLevels(
          element,
          index,
          menuId,
          explainationVideoPlayedStatus,
          manifestBlockId
        );
      });
    });
  });
};

export const addClickEventOnBlockNineLevels = (
  clickableElement: HTMLElement | null,
  index: number,
  menuId: string,
  explainationVideoPlayedStatus: any,
  manifestBlockId: string
) => {
  if (clickableElement) {
    clickableElement.addEventListener("click", () => {
      let manifestSubMenuId =
        clickableElement.getAttribute("manifestSubMenuId");
      const blockNineSubmenuId = clickableElement.getAttribute("subMenuId");
      const blockNineLevelId = clickableElement.getAttribute("levelId");

      const blockNineButtonIndex = clickableElement.getAttribute("data-index");

      const isExplainationVideoPlayedList = setExplainationVideoPlayedStatus(
        blockNineSubmenuId,
        blockNineButtonIndex,
        explainationVideoPlayedStatus
      );

      navigate(
        `/b9/menu/${menuId}/submenu/${blockNineSubmenuId}/${levelYearFourURLNames[index]}/${blockNineLevelId}`,

        {
          state: {
            manifestSubMenuId: `${manifestSubMenuId}`,
            manifestMenuId: manifestBlockId,
            isExplainationVideo:
              isExplainationVideoPlayedList.isExplainationVideoPlayed
                ? false
                : true,
            levelTitle: isExplainationVideoPlayedList.levelTitle,
          },
        }
      );
    });

    clickableElement.style.cursor = "pointer";
  }
};
export const useSvgImageBlockNineLoaded = (
  props: BlockSubMenuProps,
  menuId: any,
  loadCounter: number
) => {
  const {
    isVisible,
    setIsVisible,
    subMenuManifestData,
    setSubMenuManifestData,
    setBlockNineLoadCounter,
  } = useBlock9BlockMenu();

  const onSvgGraphicBlockNineLoaded = () => {
    loadCounter = loadCounter + 1;
    setBlockNineLoadCounter(loadCounter);
    if (loadCounter === 6) {
      setIsVisible(true);
      setBlockNineLevelRepeatIcons(
        menuId || "",
        props.subMenuData,
        props.manifestBlockId,
        subMenuManifestData
      );
      addClickEventOnStone("doel_knop_drempel_kopie", () => {
        toggleBlockHelp("block_help_9");
      });
      setSubMenuBlocks(
        blockNineRepeatIcons,
        blockNineGrayedBackgrounds,
        blockNinePlayButtons,
        props.subMenuData,
        subMenuManifestData
      );

      if (props.onLoad) props.onLoad();
    }
  };
  return {
    isVisible,
    setIsVisible,
    onSvgGraphicBlockNineLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  };
};
const BlockNineSubMenu = (props: BlockSubMenuProps) => {
  navigate = useNavigate();
  const { menuId } = useParams();

  const {
    blockNineLoadCounter,
    setBlockNineLoadCounter,
    blockNineIsLoading,
    setBlockNineIsLoading,
  } = useBlock9BlockMenu();

  const {
    isVisible,
    onSvgGraphicBlockNineLoaded,
    subMenuManifestData,
    setSubMenuManifestData,
  } = useSvgImageBlockNineLoaded(props, menuId, blockNineLoadCounter);
  const manifestData = useAppSelector(
    (state: any) => state.manifest.manifestData
  );

  useEffect(() => {
    setBlockNineLoadCounter(0);
    setBlockNineIsLoading(true);
    setSubMenuManifestDataByMenuId(
      manifestData?.children,
      (data: any) => {
        setSubMenuManifestData(data);
      },
      menuId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingDataComplete(
      props,
      () => {
        setBlockNineIsLoading(false);
      },
      menuId,
      subMenuManifestData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId, subMenuManifestData, props.subMenuData]);

  return (
    <>
      {!blockNineIsLoading && (
        <div style={{ visibility: isVisible ? "visible" : "hidden" }}>
          <SvgGraphic src={Background} onLoad={onSvgGraphicBlockNineLoaded} />

          <SvgGraphic
            src={FocusGrayedOut}
            onLoad={onSvgGraphicBlockNineLoaded}
          />
          <SvgGraphic src={Focus} onLoad={onSvgGraphicBlockNineLoaded} />
          <SvgGraphic src={Complete} onLoad={onSvgGraphicBlockNineLoaded} />
          <SvgGraphic
            src={HelpBlock}
            style={{ display: "none" }}
            id={"block_help_9"}
            onLoad={onSvgGraphicBlockNineLoaded}
          />
          <SvgGraphic src={Foreground} onLoad={onSvgGraphicBlockNineLoaded} />
        </div>
      )}
    </>
  );
};
export default BlockNineSubMenu;
