import { createSlice } from "@reduxjs/toolkit";
import { navbarTypes } from "./navbar.types";
import type { RootState } from "../store/store";

const initialState: navbarTypes = {
  openDropDown: false,
};
export const navbarSlice = createSlice({
  name: "navbar",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    openPopOut: (state) => {
      state.openDropDown = !state.openDropDown;
    },
    resetPopOut: (state, action:{payload:boolean}) => {
      state.openDropDown = action.payload;
    },
  },
});

export const { openPopOut,resetPopOut } = navbarSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.navbar.openDropDown;

export default navbarSlice.reducer;
