import React from "react";
import snail from "../../../assets/progress-bar/progress-companion/progress-snail.svg";
import leaf from "../../../assets/progress-bar/progress-companion/progress-leaf.svg";
import elephant from "../../../assets/progress-bar/progress-companion/progress-elephant.svg";
import ladybug from "../../../assets/progress-bar/progress-companion/progress-ladybug.svg";
import grasshopper from "../../../assets/progress-bar/progress-companion/progress-grasshopper.svg";
import chameleon from "../../../assets/progress-bar/progress-companion/chameleon.svg";
import bug from "../../../assets/progress-bar/progress-companion/progress-bug.svg";
import firefly from "../../../assets/progress-bar/progress-companion/progress-firefly.svg";
import Companion, { Companions, CompanionType } from "./companion";

const companionImages = [
  { name: Companions.Snail, src: snail },
  { name: Companions.Leaf, src: leaf },
  { name: Companions.Ladybug, src: ladybug },
  { name: Companions.Elephant, src: elephant },
  { name: Companions.Grasshopper, src: grasshopper },
  { name: Companions.Chameleon, src: chameleon },
  { name: Companions.Bug, src: bug },
  { name: Companions.Firefly, src: firefly },
];

type ProgressCompanionProps = {
  companion: Companions;
  position: number;
  showMask: boolean;
};

export const getCompanion = (name: Companions) => {
  return companionImages.find((obj) => obj.name === name);
};

export const getImageSource = (companion?: {
  name: Companions;
  src: string;
}) => {
  return companion ? companion?.src : "undefined";
};

function ProgressCompanion(props: ProgressCompanionProps) {
  const companion = getCompanion(props.companion);
  const companionImageSource = getImageSource(companion);

  return (
    <Companion
      position={props.position}
      showMask={props.showMask}
      source={companionImageSource}
      companionType={
        props.companion === Companions.Chameleon
          ? CompanionType.timer
          : CompanionType.progress
      }
    ></Companion>
  );
}

export default ProgressCompanion;
